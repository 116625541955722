import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import smticket from '../../../assets/images/svg/icons/ticketsmallicon.svg';
import imagePlaceholder from '../../../assets/images/ticketplaceholder.png';  

const MyTicketCard = (props: any) => { 
 
   return (
     <Link to={`/user-nft/${props?.nftTicket?.metadata_id}`} >
       <div className="ticketcard"  key={props?.nftTicket?.title}>
         <div className="ticketicon">
           <div className="avalable-ticket">
             <div className="ticket-sm-icon">
               <img src={smticket} alt="" />
             </div> 
           </div>          
           {!props?.nftTicket?.media ?  <img src={imagePlaceholder} alt="Ticket" /> : 
           <img src={props?.nftTicket?.media} alt="Ticket" />
           }
         </div>
         <div className="details">          
           {props?.nftTicket?.title != null ? (
             <h6>{props?.nftTicket?.title}</h6>
           ) : (
             <div className="mb-3">
               <Skeleton width={"200px"} height={"25px"} />
             </div>
           )} 
          </div>
       </div>
     </Link>
   );
};

export default MyTicketCard;
