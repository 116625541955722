
import { useQuery } from '@apollo/client';
import axios from 'axios';
import { useEffect, useState } from 'react'; 
import { StoreEventList } from '../queries/marketplace.queries';
import { Store } from '../types/types';

export const useStores = () => {
  const [storesList, setStoresList] = useState([]);
  // const [stores, setStores] = useState(null);
  
  const formatedselectedStores = process.env.REACT_APP_NEAR_STORE_SYMBOL;
  const url = process.env.REACT_APP_PUBLIC_STORE_DATA_API;
  const data = async (id:any) =>{ 
     await axios.get(`${url}/${'nfticket.mintspace2.testnet'}`).then((res:any)=>{
      return res.data; 
    });
  }

  const { loading } = useQuery(StoreEventList, {
    variables: {
      id: formatedselectedStores,
    },
    onCompleted: (data) => {
      const storesData = data?.store; 
      setStoresList(storesData);

    },
  });
  
 
  const stores = storesList.map((item:any) => ({
    storedata: item?.nft_contracts,
    storedetails: data(item?.nft_contracts?.id),
}));  

 

  return { stores, loading };
};

export default useStores;
