import toast from "react-hot-toast";
import nftTicketBaseApi, { store } from "../baseApi/nftTicketBaseApi"
import {
    CREATE_EVENT,
    CREATE_EVENT_ERROR,
    CREATE_EVENT_START,
    CREATE_EVENT_SUCCESS,
    CREATE_EVENT_FAIL
} from "./actionTypes";
import { actionCreators } from "./eventDataActions";

export const createEvent = () => async (dispatch: any) => {
    const event = store.getState().eventData;
    try {
        dispatch(fetchCreateEventStart());
        let body = {}
        body = {
            eventId: event.eventId,
            title: event.eventName,
            description: event.eventDesc,
            type: event.eventType,
            externalLink: event.eventExternalLink,
            profileImage: event.eventProfile,
            coverImage: event.eventCover,
            startDate: event.eventStartDate,
            endDate: event.eventEndDate,
            location: event.eventLocation,
            country: event.eventcountry,
            state: event.eventstate,
            city: event.eventCity,
            totalTicketSupply: event.ticketsupplay,
            facebookLink: event.eventFacebook,
            telegramLink: event.eventTelegram,
            discordLink: event.eventDiscord,
            twitterLink: event.eventTwitter, 
        }

        const response = await nftTicketBaseApi.post(`nft-event/create`, body)

        if (response.status === 201) {
            toast.success(response.data.message); 
            actionCreators.evntId(response.data.nftEvent._id);
            actionCreators.eventTicketStep(2);
        } else if (response.status === 200) {
            toast.error(response.data.message)
            actionCreators.eventTicketStep(2);
        } else {
            toast.error(response.data.message)
        }

        dispatch({
            type: CREATE_EVENT,
            payload: response.data
        })
        dispatch(fetchCreateEventSuccess())

    } catch (err: any) {
        toast.error(err.response.data.message);
        dispatch(fetchCreateEventStart())

        dispatch(fetchCreateEventError(err))

        dispatch(fetchCreateEventFail())

    }

}

export const fetchCreateEventError = (error: any) => async (dispatch: any) => {

    dispatch({
        type: CREATE_EVENT_ERROR,
        fetchNftTicketsNetworkError: error
    })
}

const fetchCreateEventStart = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_EVENT_START,
        nftEventLoader: true
    })
}

const fetchCreateEventSuccess = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_EVENT_SUCCESS,
        nftEventLoader: false
    })
}

const fetchCreateEventFail = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_EVENT_FAIL,
        nftEventLoader: false
    })
}