import toast from "react-hot-toast"; 
import nftTicketBaseApi, { store } from "../baseApi/nftTicketBaseApi"; 
import { CREATE_TICKET_PUBLISH, CREATE_TICKET_PUBLISH_ERROR, CREATE_TICKET_PUBLISH_FAIL, CREATE_TICKET_PUBLISH_START, CREATE_TICKET_PUBLISH_SUCCESS } from "./actionTypes";
import { actionCreators } from "./eventDataActions";
import { TicketManagerCelo, TicketManagerMetamask } from "../../contractInstance/contractInstance";
import moment from "moment";
 
export const creatEventPublish = (walletAddress: any) => async(dispatch: any) => {
    const event = store.getState().eventData;  
    try {
        dispatch(fetchcreateTicketPublishStart());
        let body: any = {
             
        }
        const eventId = event.eventId
        const eventStartDate = event.eventStartDate
        const eventEndDate = event.eventEndDate;
        const startDateEpoch = moment(eventStartDate).unix()
        const endDateEpoch = moment(eventEndDate).unix()
        const totalTicketSupply = event.ticketsupplay;
        const eventTicketTypes = store.getState().eventData.eventTicketType

        let prices: any = [];

        eventTicketTypes.map((ticketType: any) => {
            return prices = [...prices, ticketType["price"]]
        })

        body["eventId"] = eventId;
        body["eventData"] = event;
        const eventInfoPinataResponse = await nftTicketBaseApi.post(`nft-event/publish-event`, body)  
         
        const eventInfoHash = eventInfoPinataResponse.data.eventInfoHash;

        const eventInfoUrl = `https://gateway.pinata.cloud/ipfs/${eventInfoHash}`

        const currentInjectedProvider = localStorage.getItem('provider');

        let blockChainResponse: any;

        if (currentInjectedProvider === "injectedmetamask") {
            blockChainResponse = await TicketManagerMetamask.methods.createEvent(eventId, totalTicketSupply, startDateEpoch, endDateEpoch, prices, eventInfoUrl).send({ from: walletAddress, gasLimit: 300000 })
        } else {
            blockChainResponse = await TicketManagerCelo.methods.createEvent(eventId, totalTicketSupply, startDateEpoch, endDateEpoch, prices, eventInfoUrl).send({ from: walletAddress, gasLimit: 300000 })
        }

        body = {}

        body = {
            eventId: eventId,
            transactionHash: blockChainResponse.transactionHash
        }
            
        const response = await nftTicketBaseApi.post(`nft-event/publish-event`, body)  
        if (response.status === 201) { 
            actionCreators.eventTicketStep(5); 
            toast.success(response.data.message);   
                
        } else if (response.status === 200) {
            toast.success(response.data.message) 
            actionCreators.eventTicketStep(5); 
        }  else { 
            toast.error(response.data.message) 
        }
    
        dispatch({
            type: CREATE_TICKET_PUBLISH,
            payload: response.data
        })

        dispatch(fetchcreateTicketPublishSuccess())

    } catch (err) {
        // toast.error(err) 
        toast.error("Ticket Type Creat unsucessfully. Pls try again");

        dispatch(fetchcreateTicketPublishStart())

        dispatch(fetchcreateTicketPublishError(err))

        dispatch(fetchcreateTicketPublishFail())

    }

}

export const fetchcreateTicketPublishError = (error: any) => async (dispatch: any) => {

    dispatch({
        type: CREATE_TICKET_PUBLISH_ERROR,
        fetchNftTicketsNetworkError: error
    })
}

const fetchcreateTicketPublishStart = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_PUBLISH_START,
        nftEventLoader: true
    })
}

const fetchcreateTicketPublishSuccess = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_PUBLISH_SUCCESS,
        nftEventLoader: false
    })
}

const fetchcreateTicketPublishFail = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_PUBLISH_FAIL,
        nftEventLoader: false
    })
}