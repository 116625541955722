import React from "react";
import { searchLabel } from '../../context/search';
import search from '../../assets/images/svg/icons/search.svg';

function Search() {
  return (
    <div className="serach">
      <input type="text" placeholder={searchLabel.placeholder} />
      <img src={search} alt="search" />
    </div>
  );
}

export default Search;