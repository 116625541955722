import toast from "react-hot-toast";
import { actionCreators } from "../Redux/actions/eventDataActions";
import nftTicketBaseApi from "../Redux/baseApi/nftTicketBaseApi";

export const getUserSetting = async (subscribe:string,receipt:string) => {
    let body = {}
    body = {
        subscription: subscribe, 
        receipt: receipt
    }
    const res =  await nftTicketBaseApi.post(`/user/update-settings`, body);
    if(res.status === 200){
        actionCreators.editUserData(res.data.userData);
    }
}

export const getUserData = async () =>{
    const res =  await nftTicketBaseApi.get(`/user/my-profile`);
    if(res.status === 200){
        actionCreators.editUserData(res.data.userData);
    }
}

export const setProfile = async (file: any) => {
    let body = {}
    body = {
        image: file,
    }
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }
    const res =await nftTicketBaseApi.post(`/user/update-profile-img`, body, config);
    if(res.status === 200){
        toast.success(res.data.message);
        actionCreators.editUserData(res);
        getUserData();
    }
}

export const setUserProfile = async (emailid: string, name: string) => {
    let body = {}
    body = {
        name: name,
        email: emailid,
    }
    try {
        const res = await nftTicketBaseApi.post(`/user/update-info `, body);
        if (res.status === 200) {
            toast.success(res.data.message)
            actionCreators.editUserData(res.data.userData);
        }
    } catch (err: any) {
        toast.error(err.response.data.message);
    }

}

export const removeProfile = async () => {
    const res =  await nftTicketBaseApi.post(`/user/remove-profile`);
    if(res.status === 200){
        toast.success(res.data.message)
        actionCreators.editUserData(res.data.userData);         
     }else if(res.status === 201){
        toast.error(res.data.message);
     } 
     
}

