import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import { eventcard } from "../../../const/eventcard";
 

function Eventcard() {
    let navigate = useNavigate();
    
    return (
        <div className="section event-card">
            <div className="container">
                <div className="card-section">
                    {eventcard.map((item,index) => {
                        return (
                            <div key={index} className="card-list" >                             
                                   <img className="event-img" src={item.img} alt="event one" data-aos="fade-in"/>                             
                                <div className="conent" data-aos="fade-up">
                                    <h2>{item.name}</h2>
                                    <p>{item.content}</p>
                                    <Button name={'Get Started'} click={()=>{
                                        
                                         navigate("/events");                                         
                                    }}  icon={true} classname={"primarybtn"}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Eventcard;