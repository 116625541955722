import { EVENT_CITY, EVENT_COUNTRY, EVENT_COVER, EVENT_DESC, EVENT_DISCORD, EVENT_END_DATE, EVENT_EXTERNAL_LINK, EVENT_FACEBOOK, EVENT_ID, EVENT_LOCATION, EVENT_NAME, EVENT_OWNER, EVENT_PROFILE, EVENT_PUBLISH, EVENT_START_DATE, EVENT_STATE, EVENT_TELEGRAM, EVENT_TICKET_DATA, EVENT_TICKET_STEP, EVENT_TICKET_TYPE, EVENT_TWITTER, EVENT_TYPE, EVENT_VALIDATION, TICKET_SUPPLAY } from "../actions/actionTypes";

/////// TYPESCRIPT INTERFACE ////////
interface state {
    eventId: number | null,
    eventName: string | null,
    eventDesc: string | null,
    eventType: string | null,
    ticketsupplay: number | null,
    eventcountry: string | null,
    eventstate: string | null,
    eventCity: string | null,
    eventLocation: string | null,
    eventExternalLink: string | null,
    eventProfile: string | null,
    eventCover: string | null,
    eventTwitter: string | null,
    eventFacebook: string | null,
    eventDiscord: string | null,
    eventTelegram: string | null,
    eventStartDate: string | null,
    eventEndDate: string | null,
    eventValidation: boolean,
    eventTicketType: string | null,
    eventTicketData: string | null,
    eventTicketStep: Number | null,
    eventPublished: string | null,
    eventOwner: string | null,
}



const INITIAL_STATE = {
    eventId: null,
    eventName: null,
    eventDesc: null,
    eventType: null,
    ticketsupplay: null,
    eventcountry: null,
    eventstate: null,
    eventCity: null,
    eventLocation: null,
    eventExternalLink: null,
    eventProfile: null,
    eventCover: null,
    eventTwitter: "",
    eventFacebook: "",
    eventDiscord: "",
    eventTelegram: "",
    eventStartDate: null,
    eventEndDate: null,
    eventValidation: false,
    eventTicketType: null,
    eventTicketData: null,
    eventTicketStep: 1,
    eventPublished: "Pending",
    eventOwner:null
}
const eventId = (state: state, action: any) => {
    return {
        ...state,
        eventId: action.payload
    }
}

const eventName = (state: state, action: any) => {
    return {
        ...state,
        eventName: action.payload
    }
}

const eventDesc = (state: state, action: any) => {
    return {
        ...state,
        eventDesc: action.payload
    }
}

const eventType = (state: state, action: any) => {
    return {
        ...state,
        eventType: action.payload
    }
}

const ticketSupplay = (state: state, action: any) => {
    return {
        ...state,
        ticketsupplay: action.payload
    }
}

const eventcountry = (state: state, action: any) => {
    return {
        ...state,
        eventcountry: action.payload
    }
}
const eventstate = (state: state, action: any) => {
    return {
        ...state,
        eventstate: action.payload
    }
}

const eventCity = (state: state, action: any) => {
    return {
        ...state,
        eventCity: action.payload
    }
}

const eventExternalLink = (state: state, action: any) => {
    return {
        ...state,
        eventExternalLink: action.payload
    }
}

const eventLocation = (state: state, action: any) => {
    return {
        ...state,
        eventLocation: action.payload
    }
}

const eventProfile = (state: state, action: any) => {
    return {
        ...state,
        eventProfile: action.payload
    }
}

const eventCover = (state: state, action: any) => {
    return {
        ...state,
        eventCover: action.payload
    }
}

const eventTwitter = (state: state, action: any) => {
    return {
        ...state,
        eventTwitter: action.payload
    }
}

const eventFacebook = (state: state, action: any) => {
    return {
        ...state,
        eventFacebook: action.payload
    }
}

const eventDiscord = (state: state, action: any) => {
    return {
        ...state,
        eventDiscord: action.payload
    }
}

const eventTelegram = (state: state, action: any) => {
    return {
        ...state,
        eventTelegram: action.payload
    }
}

const eventStartDate = (state: state, action: any) => {
    return {
        ...state,
        eventStartDate: action.payload
    }
}

const eventEndDate = (state: state, action: any) => {
    return {
        ...state,
        eventEndDate: action.payload
    }
}

const eventValidation = (state: state, action: any) => {
    return {
        ...state,
        eventValidation: action.payload
    }
}

const eventTicketType = (state: state, action: any) => {
    return {
        ...state,
        eventTicketType: action.payload
    }
}

const eventTicketData = (state: state, action: any) => {
    return {
        ...state,
        eventTicketData: action.payload
    }
}

const eventTicketStep = (state: state, action: any) => {
    return {
        ...state,
        eventTicketStep: action.payload
    }
}

const eventPublished = (state: state, action: any) => {
    return {
        ...state,
        eventPublished: action.payload
    }
}

const eventOwner = (state: state, action: any) => {
    return {
        ...state,
        eventOwner: action.payload
    }
}

const createEventDataList = (state: state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        default:
            return state;
        case EVENT_ID:
            return eventId(state, action);
        case EVENT_NAME:
            return eventName(state, action);
        case EVENT_DESC:
            return eventDesc(state, action);
        case EVENT_TYPE:
            return eventType(state, action);
        case TICKET_SUPPLAY:
            return ticketSupplay(state, action);
        case EVENT_COUNTRY:
            return eventcountry(state, action);
        case EVENT_STATE:
            return eventstate(state, action);
        case EVENT_CITY:
            return eventCity(state, action);
        case EVENT_EXTERNAL_LINK:
            return eventExternalLink(state, action);
        case EVENT_LOCATION:
            return eventLocation(state, action);
        case EVENT_PROFILE:
            return eventProfile(state, action);
        case EVENT_COVER:
            return eventCover(state, action);
        case EVENT_TWITTER:
            return eventTwitter(state, action);
        case EVENT_FACEBOOK:
            return eventFacebook(state, action);
        case EVENT_DISCORD:
            return eventDiscord(state, action);
        case EVENT_TELEGRAM:
            return eventTelegram(state, action);
        case EVENT_START_DATE:
            return eventStartDate(state, action);
        case EVENT_END_DATE:
            return eventEndDate(state, action);
        case EVENT_VALIDATION:
            return eventValidation(state, action);
        case EVENT_TICKET_TYPE:
            return eventTicketType(state, action);
        case EVENT_TICKET_DATA:
            return eventTicketData(state, action);
        case EVENT_TICKET_STEP:
            return eventTicketStep(state, action);
        case EVENT_PUBLISH:
            return eventPublished(state, action);
            case EVENT_OWNER:
                return eventOwner(state, action);
    }
}

export default createEventDataList;