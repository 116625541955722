import React from "react";
import { useLottie } from 'lottie-react';
import animationData from '../../assets/lottie/loader.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

function Loader() {
    const { View } = useLottie(defaultOptions, { height: 180, width: 180 })

    return (
        <>
            <div className="pre-loader">
                {View}
            </div>
        </>

    );
}

export default Loader;