import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import EventCard from "../../../components/cards/EventCard";
import Line from "../../../components/Line";
import EventCardSkeleton from "../../../components/skeleton/EventCardSkeleton";
import allActions from "../../../Redux/actions";

function Upcomming() {
  const [isSkeleton] = useState(3);

  const fetchUpcomingEvent = useSelector(
    (state: any) => state?.upComingEventReducerData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allActions.fetchUpcomingEvent("0", "10") as any);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const upcomming = {
    dots: true,
    slidesToShow: 3,
    infinite: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          infinite: true,
          speed: 300,
        },
      },
    ],
  };

  return (
    <>
      {fetchUpcomingEvent?.nftEventData?.nftEvents ? (
        <div className="section upcomming">
          <div className="container">
            <h1 data-aos="fade-right">Upcoming Events</h1>
            <Line></Line>
            <p className="max-667" data-aos="fade-left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <div className="mt-36 slider-container" data-aos="fade-up">
              <Slider {...upcomming}>
                {fetchUpcomingEvent?.nftEventData?.nftEvents ? (
                  fetchUpcomingEvent?.nftEventData?.nftEvents?.slice(0, 6).map(
                    (nftEvent: any, index: any) => {
                      return <EventCard nftEvent={nftEvent} key={index} />;
                    }
                  )
                ) : (
                  <>
                    {[...Array(isSkeleton)].map((val, index) => {
                      return <EventCardSkeleton key={index} />;
                    })}
                  </>
                )}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Upcomming;
