import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Walletbutton from "../components/button/WalletButton";
import Connectwallet from "../components/ConnectWallet";
import { menuList } from "../context/menulist";
import Modal from "react-modal";
import ProfileDropdown from "../components/ProfileDropdown";

function MobileSideMenu() {
    const [active, setActive] = useState(false);
    const CloseSidebar = () => {
        setActive(false);
      };
      const userData = useSelector((state: any) => state.userData);
    const [modalIsOpen, setIsOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false);
    };
    const openModal = () => {
        setIsOpen(true);
    };

    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          padding: "0",
          bottom: "auto",
          marginRight: "-50%",
          width: "450px",
          borderRadius: "25px",
          transform: "translate(-50%, -50%)",
        },
      };
    return (
      <div className={`hamburicon ${active ? "active" : ""}`}>
        <div
          className={`hamburger ${active ? "is-active" : ""}`}
          id="hamburger-9"
          onClick={() => {
            setActive(!active);
          }}
        >
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
        <nav className={`hambur-menu ${active ? "active" : ""}`}>
          <ul>
            {menuList.map((list) => {
              return (
                <li key={list.name} onClick={CloseSidebar}>
                  <NavLink to={list.path}>{list.name}</NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
        {userData?.isLoggedIn ? (
            <div className="">

                <ProfileDropdown setActive={setActive}/>
            </div>
        ) : (
          <div className={`profile ${active ? "active" : ""}`} onClick={() => {openModal(); CloseSidebar()}}>
            <Walletbutton />
          </div>
        )}
        {/* <ProfileDropdown /> */}
        {/* <div className={`profile ${active ? "active" : ""}`}>
          <Walletbutton />
        </div> */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="ticket-list">
            <Connectwallet setIsOpen={setIsOpen} />
          </div>
        </Modal>
      </div>
    );
}

export default MobileSideMenu;