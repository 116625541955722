const Ticketdetailcont={
    share:"Share",
    price:"Price",
    available:"Available Tickets",
    soldticket:"Sold Tickets",
    Aboutlabel:"About",
    eventlabel:"Event Details",
    location:"Event Location & Date",
    activites:"Activites",
    moreevent:"More from this Event",
    notetxt:'Note',
    plsadd: "Please add",
    youremail:"your email address to receive ticket receipts.",
    name:"Name",
    transactionid:"Transaction ID",
    transactiontype:"Transaction Type",
    time:"Time",
    totalamt:"Total Amount",
    buyer:"Buyer"


}
export default Ticketdetailcont;