import React, { useEffect, useState } from "react";
import Walletbutton from "../components/button/WalletButton";
import Search from "../components/search/Search";
import useWindowDimensions from "../const/config";
import { menuList } from "../context/menulist";
import { NavLink } from "react-router-dom";
import MobileSideMenu from "./MobileSideMenu";
import Connectwallet from "../components/ConnectWallet";
import Modal from "react-modal";
import { useWeb3React } from "@web3-react/core";
import ProfileDropdown from "../components/ProfileDropdown";
import { injectedMetamask } from "../const/metamaskWalletConnection";
import { injectedcelo } from "../const/celloWalletConnection";
import { useDispatch } from "react-redux";
import allActions from "../Redux/actions";
import LogoSVG from '../assets/images/svg-icons/logo.svg'
import { useWallet } from "../services/providers/MintbaseWalletContext";
function Header() {
  const { width } = useWindowDimensions();
  const [modalIsOpen, setIsOpen] = useState(false);
  const { active, activate, account } = useWeb3React();
  const { wallet, isConnected, details } = useWallet()
  const dispatch = useDispatch();
 
  console.log(wallet)
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      padding: "0",
      bottom: "auto",
      marginRight: "-50%",
      width: "450px",
      borderRadius: "25px",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(()=>{ 
    localStorage.setItem("isconnect",isConnected?.toString()); 
  },[isConnected])

  useEffect(() => {
    const login = localStorage.getItem('provider');
    if (login === "injectedmetamask") {
      activate(injectedMetamask);
    }
    if (login === "injectedcelo") {
      activate(injectedcelo);
    }    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {     
    if(isConnected){
      const account = details.accountId;
      dispatch(allActions.setAuthOptions(account) as any);
    }
    if(account) {
        dispatch(allActions.setAuthOptions(account) as any);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account,isConnected]);

  return (
    <div className="header">
      <div className="logo">
        <NavLink to={"/"}>
          {" "}
          <img src={LogoSVG} alt="Logo" />
        </NavLink>
      </div>
      <div className="nav-list">
        {width > 1023 ? (
          <>
            <Search />
            <nav>
              <ul>
                {menuList.map((list) => {
                  return (
                    <li key={list.name}>
                      <NavLink to={list.path}>{list.name}</NavLink>
                    </li>
                  );
                })}
                {active === true || isConnected === true ?
                  <li>
                    <ProfileDropdown setActive={''} />
                  </li> :
                  <li onClick={() => openModal()}>
                    <Walletbutton />
                  </li>
                }
              </ul>
            </nav>
          </>
        ) : (
          <MobileSideMenu />
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal">
        <div className="ticket-list">
          <Connectwallet setIsOpen={setIsOpen} />
        </div>
      </Modal>
    </div>
  );
}

export default Header;
