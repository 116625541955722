import React from "react";

interface propse {
    setLayout: any,
    layout: boolean,
    imageone: string,
    imagetwo: string
}

function GridLayout({ setLayout, layout, imageone, imagetwo }: propse) {
    return (
        <div className="gridlayout">
            <button className={`${layout === false ? 'active' : ''}`} onClick={()=>{
                setLayout(false);
            }}>
                <img src={imageone} alt="layout one" />
            </button>
            <button className={`${layout === true ? 'active' : ''}`} onClick={()=>{
                setLayout(true);
            }}>
                <img src={imagetwo} alt="layout two" />
            </button>
        </div>
    );
}

export default GridLayout;