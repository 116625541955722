import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Line from "../../../components/Line";
import bannerkv from '../../../assets/images/ticketkv.png'
import Slider from "react-slick";
import Button from "../../../components/button/Button";
import { useSelector } from "react-redux";
import Connectwallet from "../../../components/ConnectWallet";
import Modal from "react-modal";
import { resetCreateForm } from "../../../const/resetStore";
import { useWallet } from "../../../services/providers/MintbaseWalletContext";


function Banner() {

  const userData = useSelector((state: any) => state.userData);
  const [modalIsOpen, setIsOpen] = useState(false);
  const { isConnected } = useWallet()
  const closeModal = () => {
    setIsOpen(false);
  };

  const bannerimg = {
    dots: true,
    infinite: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          infinite: true,
          speed: 300,
        },
      },
    ],
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      padding: "0",
      bottom: "auto",
      marginRight: "-50%",
      width: "450px",
      borderRadius: "25px",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="content" data-aos="fade-right">
            <h1>
Revolutionizing Ticketing 
</h1>
            <Line />
            <p>NFtickt is here to transform the ticketing experience for both ticket buyers and event organizers. We deliver memorable experiences by managing the event lifecycle, issuing NFT tickets, and seamlessly validating them at venues.</p>
              {
                isConnected ? 
                <NavLink to={'/create-event'}><Button name="Create an Event" click={()=>{ resetCreateForm();}} icon={true} classname={'primarybtn'} /></NavLink>
                :
                <div><Button name="Create an Event" click={()=>{setIsOpen(true);}} icon={true} classname={'primarybtn'} /></div>
              }
          </div>
        </div>
        <div className="col-lg-6">
          <div className="bannerkv" data-aos="fade-in">
            <Slider {...bannerimg}>
              <img src={bannerkv} alt="Banner KV" />
              <img src={bannerkv} alt="Banner KV" />
              <img src={bannerkv} alt="Banner KV" />
            </Slider>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal">
        <div className="ticket-list">
          <Connectwallet setIsOpen={setIsOpen} />
        </div>
      </Modal>
    </>
  );
}

export default Banner;