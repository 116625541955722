import imageone from '../assets/images/eventone.png';
import imagetwo from '../assets/images/eventwo.png';
 

export const eventcard = [
   {
    name:"We use a carbon-neutral blockchain!",
    content:'Minted, Store and Transact tickets on a carbon negative blockchain! We use a mobile-first, carbon-neutral blockchain platform called Celo that makes our services accessible to anyone with a mobile phone—bringing the powerful benefits of NFTickt to everyone!',
    img:imageone,
   },
   {
    name:"Breaking Barriers",
    content:'Traditional ticketing has ruined experiences by solely focusing on profit and bots. Breaking barriers and bringing in an ecosystem that shall serve brands, events, artists, and fans experiences that will last beyond generations, we at NFTickt are here to slay the game.',
    img:imagetwo,
   },  
    
]

