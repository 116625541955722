import { MetadataField } from "mintbase";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { useForm, useFormContext } from "react-hook-form";
import Dropdown from "../../../components/DropDown";
import closeicon from '../../../assets/images/svg/icons/close.svg';
import Button from "../../../components/button/Button";
import Loader from "../../../components/Loader/Loader";
import useUserStores from "../../../hooks/useUserStores";
import { useWallet } from "../../../services/providers/MintbaseWalletContext";
import { EInputType } from "../../../types/types";
import CreateStore from './CreateStore';

function CreateTicket() {
  const { wallet, isConnected, details } = useWallet();
  const [store, setStore] = useState(null);
  const storeList = useUserStores();
  const [model, setModel] = useState(false);
  const [validate, setValidate] = useState(false);
  const [startvalue, onstartChange] = useState(new Date());
  const [endvalue, onEndChange] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [ticketimage, setTicketImage] = useState(null);
  const [storeName, setStoreName] = useState(null);

  const userStore = storeList?.stores.map((list: any) => ({
    name: list?.nft_contracts.name,
    value: list?.nft_contracts.id,
  }
  ));

  const methods = useForm({
    defaultValues: {
      [EInputType.TITLE]: '',
      [EInputType.DESCRIPTION]: '',
      [EInputType.MINT_AMOUNT]: 1,
      [EInputType.CATEGORY]: null,
      [EInputType.LOCATION]: null,
      [EInputType.MAIN_IMAGE]: null,
      [EInputType.FOREVER_MEDIA]: null,
      [EInputType.FOREVER_DOCUMENT]: null,
      [EInputType.TAGS]: null,
      [EInputType.WEBSITE]: null,
      [EInputType.CALENDAR]: null,
      [EInputType.FOREVER_ROYALTIES]: null,
      [EInputType.SPLIT_REVENUE]: null,
      [EInputType.CUSTOM_KEY_VALUE]: null,
      [EInputType.STORE]: null,
    },
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    formState: { errors },
  } = methods;


  const file = getValues(EInputType.MAIN_IMAGE);
  const onSubmit = async (data: { [x: string]: any }) => {
    setLoading(true)
    try {
      if (file) {
        const res = await wallet?.minter?.uploadField(MetadataField.Media, file[0]);
        if (res?.error) {
          throw new Error(res?.error);
        }
      }
    } catch (error) {
      console.error(error);
    }

    const extra: any[] = [
      {
        "trait_type": "website",
        "display_type": "website",
        "value": getValues(EInputType.WEBSITE),
      },
      {
        "trait_type": "Start Date",
        "value": moment(startvalue).format("X"),
        "display_type": "date"
      },
      {
        "trait_type": "End Date",
        "value": moment(endvalue).format("X"),
        "display_type": "date"
      },
      {
        "trait_type": "ADDRESS",
        "display_type": "access",
        "value": data[EInputType.LOCATION]
      }
    ];

    const mintAmount = data[EInputType.MINT_AMOUNT];
    const category = data[EInputType.CATEGORY];

    const metadata = {
      title: data[EInputType.TITLE],
      description: data[EInputType.DESCRIPTION],
      extra,
      store,
      type: 'NEP171',
      category,
    };

    wallet?.minter?.setMetadata(metadata, true);

    const royalties = data[EInputType.FOREVER_ROYALTIES];
    const splits = data[EInputType.SPLIT_REVENUE];

    const metadate = await wallet?.minter?.getMetadataId();


    if (metadate?.error) {
      // TODO: throw error
      return;
    }

    const metadataId = metadate?.data;
    if (storeName)
      await wallet?.mint(
        Number(mintAmount),
        storeName,
        !royalties ? undefined : royalties.royaltyArgs,
        !splits ? undefined : splits,
        category,
        {
          callbackUrl: `${window.location.origin}/user-event/${storeName}`,
          royaltyPercentage: royalties?.percentage || 0,
          metadataId,
        
        },
      );
    setLoading(false)
  };

  useEffect(() => {
    let files = getValues(EInputType.MAIN_IMAGE);
    let reader = new FileReader();
    if (files)
      reader.readAsDataURL(files[0]);
    reader.onload = (e: any) => {
      setTicketImage(e.target.result);
    };
  }, [watch(EInputType.MAIN_IMAGE)])


  return (
    <>
      {loading ? <Loader /> : ""}
      <h6><span>{window.location.pathname === "/edit-event" ? "Update" : "Create"} Ticket</span></h6>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      <div className="mt-4">
        <Button name="Add New Event" click={() => { setModel(true) }} icon={false} classname={'primarybtn'} />
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="filed-section">
          <label >Event Name <span>*</span></label>
          <Dropdown list={userStore} placeholder={'Select Event Name'} setvalue={storeName} setDropValue={setStoreName} />
          {storeName == null && validate ? <p className="required_msg mt-2">This is required</p> : ""}
        </div>
        <div className="filed-section">
          <label  >Ticket Name  <span>*</span></label>
          <input type="text" className={`? "error":""}`} placeholder="Enter title name" required
            {...register(EInputType.TITLE, {
              required: true,
            })} />
          {watch(EInputType.TITLE) == "" && validate ? <p className="required_msg mt-2">This is required</p> : ""}
        </div>
        <div className="filed-section">
          <label  >Ticket Description  <span>*</span></label>
          <input type="text" placeholder="Enter ticket description" required
            {...register(EInputType.DESCRIPTION, {
              required: true,
            })} />
          {watch(EInputType.DESCRIPTION) == "" && validate ? <p className="required_msg mt-2">This is required</p> : ""}
        </div>
        <div className="filed-section">
          <label  >Ticket Image  <span>*</span></label>
          <div className="col-lg-6">
            <div className="file-upload">
              {ticketimage ?
                <div className="priview">
                  <img height={'175px'} src={ticketimage} alt="images" />
                  {ticketimage ?
                    <div className="close-icon" onClick={() => {
                      setTicketImage(null)
                    }}>

                      <img src={closeicon} alt="Close Icon" />
                    </div> : ""}
                </div> : <>
                  <label htmlFor="cheese" className="filed">
                    <br />
                    <p>Drop your image here, or <span className="secondary">Browser</span> <br /><span className="run-text">File Types Supported : "JPEG, PNG"</span></p>
                    <input type="file" id="cheese" accept="image/jpg,image/jpeg,image/png" required  {...register(EInputType.MAIN_IMAGE, { required: true, })} /></label></>}

            </div>
          </div>
          {watch(EInputType.MAIN_IMAGE) == null && validate ? <p className="required_msg mt-2">This is required</p> : ""}
        </div>
        <div className="filed-section">
          <label  >Ticket Supply  <span>*</span></label>
          <input type="number" placeholder="Enter Ticket supply" required
            {...register(EInputType.MINT_AMOUNT, {
              required: true,
            })} />
          {watch(EInputType.MINT_AMOUNT) == null && validate ? <p className="required_msg mt-2">This is required</p> : ""}
        </div>
        <div className="filed-section">
          <label  >Event External Link  <span>*</span></label>
          <input type="text" placeholder="Enter event external link (Website or other link)"
            {...register(EInputType.WEBSITE)} />
          {watch(EInputType.WEBSITE) == null && validate ? <p className="required_msg mt-2">This is required</p> : ""}
        </div>
        <div className="filed-section">
          <label >Address  <span>*</span></label>
          <input type="text" placeholder="Enter event address"
            {...register(EInputType.LOCATION)} />
          {watch(EInputType.LOCATION) == null && validate ? <p className="required_msg mt-2">This is required</p> : ""}
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="filed-section">
              <label  >Start Date  <span>*</span></label>
              <DateTimePicker onChange={onstartChange} minDate={new Date()} value={new Date(startvalue)} dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" />
              {startvalue == null && validate ? <p className="required_msg mt-2">This is required</p> : ""}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="filed-section">
              <label  >End Date  <span>*</span></label>
              <DateTimePicker onChange={onEndChange} minDate={startvalue} value={new Date(endvalue)} dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" />
              {endvalue == null && validate ? <p className="required_msg mt-2">This is required</p> : ""}
            </div>
          </div>
        </div>
        <div className="text-center mt-5">
          <button type="submit" className="primarybtn" onClick={() => { setValidate(true); console.log(getValues(EInputType.MAIN_IMAGE)) }}>Submit</button>
        </div>
      </form>
      <div className={`model ${model ? "active" : ''}`} onClick={() => { setModel(false) }}>
        <div className="medium" onClick={(e) => { e.stopPropagation() }}>
          <CreateStore />
        </div>
      </div>
    </>
  )

}

export default CreateTicket;