import React from 'react';
import Gridthree from '../assets/images/svg-icons/grid-3col.svg';
import GridTwo from '../assets/images/svg-icons/grid-2col.svg';
import GridLayout from './GridLayout';
interface props{
     setGrid: (active: boolean) => void;
     grid:boolean;
}

const FiltersHeader = ({setGrid, grid}:props) => {

  return (
    <div className="filters-header">
      <div className="">
        <h6 className="h-title"> Event Collection</h6>
      </div>
    <div className='d-flex'>
      {/* <div className='me-3'><Dropdown list={sorttype} placeholder={'Sort by'} setvalue={''} setDropValue={''}/></div> */}
      <GridLayout setLayout={setGrid} layout={grid} imageone={Gridthree} imagetwo={GridTwo} />
    </div>
    </div>
  );
};

export default FiltersHeader;