import { SET_EDIT_USER_INFORMATION, SET_EDIT_USER_SETTING, SET_USER_INFORMATION } from '../actions/actionTypes';

interface state {
    userInformation: any,
    userDetails: any,
    editUserSetting: any,
    isLoggedIn: boolean
}

const INITIAL_STATE = {
    userInformation: null,
    userDetails: null,
    editUserSetting: {
        subscribe: true,
        receipt: true,
    },
    isLoggedIn: false
}

const setUserInfo = (state: state, action: any) => {
    return {
        ...state,
        userInformation: action.payload,
        isLoggedIn: true
    }
}

const editUserData = (state: state, action: any) => {
    return {
        ...state,
        userDetails: action.payload
    }
}

const editUserSetting = (state: state, action: any) => {
    return {
        ...state,
        editUserSetting: action.payload
    }
}

const userReducer = (state: state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        default:
            return state;
        case SET_EDIT_USER_INFORMATION:
            return editUserData(state, action);
        case SET_EDIT_USER_SETTING:
            return editUserSetting(state, action);
        case SET_USER_INFORMATION:
            return setUserInfo(state, action);

    }
}

export default userReducer;