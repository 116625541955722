import nftTicketBaseApi from "../baseApi/nftTicketBaseApi"
import { FETCH_NFT_TICKET_ID, FETCH_NFT_TICKET_ERROR, FETCH_TICKET_START, FETCH_TICKET_SUCCESS, FETCH_TICKET_FAIL, TICKET_ACTIVITY_FROM_CHAIN_START, TICKET_ACTIVITY_FROM_CHAIN_SUCCESS, TICKET_ACTIVITY_FROM_CHAIN_FAIL, TICKET_ACTIVITY_FROM_CHAIN_ERROR, GET_TICKET_ACTIVITY_FROM_CHAIN } from "../actions/actionTypes";
import { TicketManagerCelo, TicketManagerMetamask } from "../../contractInstance/contractInstance";

export const fetchNftTicketById = (id: any) => async(dispatch: any) => {

    try {
        dispatch(fetchNftTicketStart())
        const response = await  nftTicketBaseApi.get(`nft-ticket/getById/${id}`);
    
        dispatch({
            type: FETCH_NFT_TICKET_ID,
            payload: response.data
        }) 
        dispatch(fetchNftTicketSuccess())

    } catch (err) {
        dispatch(fetchNftTicketStart())

        dispatch(fetchNftTicketNetworkError(err))

        dispatch(fetchNftTicketDetailFail())

    }

}

//// TICKET OWNERS DETAILS ///////
export const getTicketActivity = (currentEventId: string | any) => async (dispatch: any) => {
    try {

        ticketActivityFromChainStart()
        const currentInjectedProvider = localStorage.getItem('provider');
        let blockChainResponse:any;
        if (currentInjectedProvider === "injectedmetamask") {
            blockChainResponse = await TicketManagerMetamask.methods.getEventsById(currentEventId).call()
        } else {
            blockChainResponse = await TicketManagerCelo.methods.getEventsById(currentEventId).call()
        }

        dispatch({
            type: GET_TICKET_ACTIVITY_FROM_CHAIN,
            payload: blockChainResponse
        })
        ticketActivityFromChainSuccess()

    } catch (err) {
        ticketActivityFromChainStart()
        ticketActivityFromChainError(err)
        ticketActivityFromChainFail()
    }
}

export const fetchNftTicketNetworkError = (error: any) => async (dispatch: any) => {

    dispatch({
        type: FETCH_NFT_TICKET_ERROR,
        fetchNftTicketsNetworkError: error
    })
}

const fetchNftTicketStart = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_TICKET_START,
        nftEventLoader: true
    })
}

const fetchNftTicketSuccess = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_TICKET_SUCCESS,
        nftEventLoader: false
    })
}

const fetchNftTicketDetailFail = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_TICKET_FAIL,
        nftEventLoader: false
    })
}

const ticketActivityFromChainStart = () => async (dispatch: any) => {
    dispatch({
        type: TICKET_ACTIVITY_FROM_CHAIN_START,
        ticketActivityLoader: true
    })
}

const ticketActivityFromChainSuccess = () => async (dispatch: any) => {
    dispatch({
        type: TICKET_ACTIVITY_FROM_CHAIN_SUCCESS,
        ticketActivityLoader: false
    })
}

const ticketActivityFromChainFail = () => async (dispatch: any) => {
    dispatch({
        type: TICKET_ACTIVITY_FROM_CHAIN_FAIL,
        ticketActivityLoader: false
    })
}

const ticketActivityFromChainError = (error: any) => async (dispatch: any) => {
    dispatch({
        type: TICKET_ACTIVITY_FROM_CHAIN_ERROR,
        ticketActivityLoader: false,
        chainError: error
    })
}