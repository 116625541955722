import React, { useState } from "react";
import { NavLink } from "react-router-dom"; 
import Button from "../../../components/button/Button";
import Line from "../../../components/Line";
import promise from './../../../assets/images/promise.png'
import { useSelector } from "react-redux";
import Connectwallet from "../../../components/ConnectWallet";
import Modal from "react-modal";
import { resetCreateForm } from "../../../const/resetStore";
import { useWallet } from "../../../services/providers/MintbaseWalletContext";

function Promise() {

    const userData = useSelector((state: any) => state.userData);
    const [modalIsOpen, setIsOpen] = useState(false);
    const { isConnected } = useWallet()
    const closeModal = () => {
        setIsOpen(false);
    };

    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          padding: "0",
          bottom: "auto",
          marginRight: "-50%",
          width: "450px",
          borderRadius: "25px",
          transform: "translate(-50%, -50%)",
        },
      };

    return (
        <div className="section promise">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="img-section" data-aos="fade-right">
                            <img src={promise} className="img-fluid" alt="promise" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="content" data-aos="fade-left">
                            <h1>Claim your ticket </h1>
                            <Line />
                            <p>At NFTickt, you can buy tickets and gain access to immense perks and incentives! Sell rare tickets to collectors and earn big bucks, or store tickets to keep memories alive. Choose your ticketing journey with NFTickt. 
</p>
                            {/* <h6>Solana Foundation</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore
                                magna aliqua. Ut enim ad minim veniam, </p>
                            <p> quis nostrud exercitation ullamco laboris nisi ut aliquip
                                ex ea commodo consequat.</p> */}
                                  {
                isConnected ? 
                <NavLink to={'/create-event'}><Button name="Create an Event" click={()=>{ resetCreateForm();}} icon={true} classname={'primarybtn'} /></NavLink>
                :
                <div><Button name="Create an Event" click={()=>{setIsOpen(true);}} icon={true} classname={'primarybtn'} /></div>
              }
                        </div>
                    </div>
                </div>
            </div>
             <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Example Modal">
                <div className="ticket-list">
                <Connectwallet setIsOpen={setIsOpen} />
                </div>
            </Modal>
        </div>
    );
}

export default Promise;