import {FETCH_UPCOMING_EVENT_ID,FETCH_UPCOMING_EVENT_ERROR,FETCH_UPCOMING_EVENT_START,FETCH_UPCOMING_EVENT_SUCCESS,FETCH_UPCOMING_EVENT_FAIL} from '../actions/actionTypes';

interface state {
    myEventData:any,
    netWorkErrorFromServer:any,
    nftMyEventLoader: boolean,
}

const INITIAL_STATE = {
    myEventData: null,
    netWorkErrorFromServer:null,
    nftMyEventLoader: false,
}

const fetchUpCommingHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventData: action.payload
    }
}
const fetchUpCommingNetworkError = (state: state, action: any) => {
    return {
        ...state,
        fetchNftTicketsNetworkErrorFromServer: action.fetchNftTicketsNetworkError
    }
}
const fetchUpCommingFail = (state: state, action: any) => {
    return {
        ...state,
        nftEventLoader: false
    }
}


const fetchUpCommingStart = (state: state, action: any) => {
    return {
        ...state,
        nftEventLoader: true
    }
}

const fetchUpCommingSuccess = (state: state, action: any) => {
    return {
        ...state,
        nftEventLoader: false
    }
}
const upComingEventReducer =(state:state = INITIAL_STATE, action:any) =>{
    switch(action.type){
        default:
            return state;
            case FETCH_UPCOMING_EVENT_ID:
                return fetchUpCommingHandler(state, action);
            case FETCH_UPCOMING_EVENT_ERROR:
                return fetchUpCommingNetworkError(state, action)
            case FETCH_UPCOMING_EVENT_START:
                return fetchUpCommingStart(state, action)
            case FETCH_UPCOMING_EVENT_SUCCESS:
                return fetchUpCommingSuccess(state, action)
            case FETCH_UPCOMING_EVENT_FAIL:
                return fetchUpCommingFail(state, action)

    }
}

export default upComingEventReducer;