import axios from "axios";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";

export const store = createStore(reducers, applyMiddleware(thunk));

const defaultOptions = {
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
    }
}

// Create instance
let instance = axios.create(defaultOptions);




export default instance;