import {
    FETCH_NFT_EVENT_BY_ID,
    SET_SELECTED_NFT_EVENT_ID,
    FETCH_NFT_EVENT_NETWORK_ERROR,
    FETCH_NFT_EVENT_BY_ID_START,
    FETCH_NFT_EVENT_BY_ID_SUCCESS,
    FETCH_NFT_EVENT_BY_ID_FAIL,
    FETCH_NFT_EVENT_LISTS_WITH_FILTERING,
    FETCH_NFT_EVENT_LISTS_WITHOUT_FILTERING,
    FETCH_NFT_TICKETS_START,
    FETCH_NFT_EVENT_LISTS_SUCCESS,
    FETCH_NFT_EVENT_LISTS_FAIL,
    FETCH_NFT_EVENT_LISTS_NETWORK_ERROR,
    FETCH_NFT_EVENT_LISTS_START
} from "../actions/actionTypes";

/////// TYPESCRIPT INTERFACE ////////
interface state {
    //// NFT EVENT 
    nftEventData: any,
    selectedNftEventId: string | null,
    fetchNftNetworkErrorFromServer: any | string,
    nftEventLoader: boolean,

    //// NFT EVENT LISTS
    nftEventsLists: any,
    // nftEventListWithFilteringData: any,
    // nftEventListWithoutFilteringData: any,
    nftEventListsWithFiltering: boolean,
    nftEventListsWithoutFiltering: boolean,
    nftEventListsNetworkError: any
}

const INITIAL_STATE = {
    /// NFT EVENT
    nftEventData: null,
    selectedNftEventId: null,
    fetchNftNetworkErrorFromServer: null,
    nftEventLoader: false,

    /// NFT EVENT LISTS
    nftEventsLists: null,
    // nftEventListWithFilteringData: null,
    // nftEventListWithoutFilteringData: null,
    nftEventListsWithFiltering: false,
    nftEventListsWithoutFiltering: false,
    nftEventListsNetworkError: null
}

const fetchNftEventByIdHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventData: action.payload
    }
}

const setSelectedNftEventIdHandler = (state: state, action: any) => {
    return {
        ...state,
        selectedNftEventId: action.setSelectedNftEventId
    }
}

const fetchNftNetworkErrorFromServerHandler = (state: state, action: any) => {
    return {
        ...state,
        fetchNftNetworkErrorFromServer: action.fetchNftNetworkErrorFromServer
    }
}

const nftEventListsStartHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventLoader: true
    }
}

const nftEventListsSuccessHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventLoader: false
    }
}

const nftEventListsFailHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventLoader: false
    }
}


const fetchNftEventByIdStartHandler = (state: state, action: any) => { 
    return {
        ...state,
        nftEventLoader: true
    }
}

const fetchNftEventByIdSuccessHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventLoader: false
    }
}

const fetchNftEventByIdFailHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventLoader: false,
        nftEventsLists: null,
    }
}

//// NFT EVENT LISTS HANDLERS /////
const fetchNftEventListsWithFilteringHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventsLists: action.payload,
        // nftEventListWithFilteringData: action.payload,
        // nftEventListsWithFiltering: action?.nftEventListsWithFiltering,
        nftEventListsWithoutFiltering: action?.nftEventListsWithoutFiltering,
        nftEventListsNetworkError: action.nftEventListsNetworkError
    }
}

const fetchNftEventListsWithoutFilteringHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventsLists: action.payload,
        // nftEventListWithoutFilteringData: action.payload,
        // nftEventListsWithFiltering: action?.nftEventListsWithFiltering,
        nftEventListsWithoutFiltering: action?.nftEventListsWithoutFiltering,
        nftEventListsNetworkError: action.nftEventListsNetworkError
    }
}

const nftEventListsNetworkErrorHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventListsNetworkError: action.nftEventListsNetworkError,
        nftEventsLists: null,
    }
}

const nftEventsReducer = (state: state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        default:
            return state;

        case FETCH_NFT_EVENT_BY_ID:
            return fetchNftEventByIdHandler(state, action);

        case SET_SELECTED_NFT_EVENT_ID:
            return setSelectedNftEventIdHandler(state, action);

        case FETCH_NFT_EVENT_NETWORK_ERROR:
            return fetchNftNetworkErrorFromServerHandler(state, action);

        case FETCH_NFT_EVENT_LISTS_START:
            return fetchNftEventByIdStartHandler(state, action);

        case FETCH_NFT_EVENT_BY_ID_START:
            return fetchNftEventByIdStartHandler(state, action);

        case FETCH_NFT_EVENT_BY_ID_SUCCESS:
            return fetchNftEventByIdSuccessHandler(state, action);

        case FETCH_NFT_EVENT_BY_ID_FAIL:
            return fetchNftEventByIdFailHandler(state, action);

        ///// NFT EVENT LISTS 
        case FETCH_NFT_EVENT_LISTS_WITH_FILTERING:
            return fetchNftEventListsWithFilteringHandler(state, action);

        case FETCH_NFT_EVENT_LISTS_WITHOUT_FILTERING:
            return fetchNftEventListsWithoutFilteringHandler(state, action);

        case FETCH_NFT_TICKETS_START:
            return nftEventListsStartHandler(state, action);

        case FETCH_NFT_EVENT_LISTS_SUCCESS:
            return nftEventListsSuccessHandler(state, action);

        case FETCH_NFT_EVENT_LISTS_FAIL:
            return nftEventListsFailHandler(state, action);

        case FETCH_NFT_EVENT_LISTS_NETWORK_ERROR:
            return nftEventListsNetworkErrorHandler(state, action);

    }
}

export default nftEventsReducer;