import { useState } from "react";
import Button from "../../components/button/Button";
import useNftTokenDetails from "../../hooks/useNftTokenDetails";
import { useWallet } from "../../services/providers/MintbaseWalletContext";
const Burn = (ticketId: any, setPopup: any) => {
  const { nftList, loading } = useNftTokenDetails(ticketId.ticketId)
  const { wallet, isConnected, details } = useWallet();

  return (
    <>
      <p>Are you sure you want to delete?</p>
      <div className="d-flex action-btn">
        <Button
          name="Delete"
          icon={false}
          classname="primarybtn"
          click={() => {
            if (nftList.length == 1) {
              wallet?.burn([nftList[0].token_id], nftList[0].nft_contract_id)
            } else {
              const ticketId = nftList.map((element: any) => element.token_id);
              wallet?.burn(ticketId, nftList[0].nft_contract_id)
            }
          }}
        />
      </div>
    </>
  )

}

export default Burn;