import React, { useEffect, useRef } from "react";
import useDetectOutsideClick from "../useDetectOutsideClick";
import { Link, NavLink } from "react-router-dom";

import UserIcon from "../assets/images/svg-icons/usericon.svg";
import Profileicon from "../assets/images/svg-icons/profileicon.svg";
import Myeventicon from "../assets/images/svg-icons/myevent.svg";
import Logouticon from "../assets/images/svg-icons/logout.svg";
import Myticketicon from '../assets/images/svg-icons/myticket.svg';
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import { useWallet } from "../services/providers/MintbaseWalletContext";

const ProfileDropdown = ({setActive}:any) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(false);
  const { wallet, isConnected, details } = useWallet()
  const onClick = () => setIsActive(!isActive);
  const { account, deactivate } = useWeb3React();
  const Closedrop = () => {
    setIsActive(false);
  };
 
  const logout = async () =>{
    if (isConnected === true) {
      await localStorage.clear();
      await wallet?.disconnect(); 
    } else{
      await localStorage.clear();
      Closedrop();
      deactivate();
      setActive(false);
      toast.success("Logout Your Account");
    }
    await window.location.reload();
  }

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (!isActive?.current?.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
 
  return (
    <div>
      <div className="menu-container">
        <button onClick={onClick} className="menu-trigger">
          <img src={UserIcon} alt="User avatar" />
          {isConnected === true ? 
          <p>{details.accountId}</p> :           
          <p>{account?.toString().slice(0, 3)}...{account?.toString().slice(-3)}</p>
          }
        </button>
        <div
          ref={dropdownRef}
          className={`menu ${isActive ? "active" : "inactive"}`}
        >
          <ul>
            {/* <li onClick={()=>{Closedrop();setActive(false);}}>
              <NavLink to="/my-profile">
                <img src={Profileicon} alt="menu-icon" />
                Profile
              </NavLink>
            </li> */}
            <li onClick={()=>{Closedrop();setActive(false);}}>
              <NavLink to="/my-events">
                <img src={Myeventicon} alt="menu-icon" />
                My Events
              </NavLink>
            </li>
            <li onClick={()=>{Closedrop();setActive(false);}}>
              <NavLink to="/my-tickets">
                <img src={Myticketicon} alt="menu-icon" />
                My Tickets
              </NavLink>
            </li>
            <li onClick={logout}>
              <Link to="/">
                <img src={Logouticon} alt="menu-icon" />
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
