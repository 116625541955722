import React from "react"; 
import { Link } from "react-router-dom";
import PencilIcon from '../../assets/images/svg-icons/pencil-icon.svg'; 
import Scan from '../../assets/images/svg/icons/scan.svg'; 
import { userWalletAddress } from "../../const/config";

const EventCard = (props: any) => { 
 
  return (
    <div className="even-card" key={props?._id}> 
      <Link to={`${window.location.pathname === "/my-events"? "/user-event/": "/event-details/"}${props?.nftEvent?.nft_contracts.id}`}>
        <div className="collection-item">
          <div className="event-thumb">
            <div className="event-edit">

              {props?.nftEvent?.isEventPublished !== "PUBLISHED" &&
                props?.nftEvent?.eventOwner === userWalletAddress?.toString()
                ?
                <span>
                  <Link to={`/edit-event/${props?.nftEvent?.nft_contracts.id}`}>
                    <img src={PencilIcon} alt="Edit-icon" className="ms-3" />
                  </Link>
                </span>
                : ""}

              {/* {props?.nftEvent?.isEventPublished !== "PUBLISHED" &&
                props?.nftEvent?.eventOwner === userWalletAddress?.toString()
                ?
                <span>
                  <Link to={`/scanner/${props?.nftEvent?.nft_contracts.id}`}>
                    <img src={Scan} alt="Edit-icon" className="ms-3" />
                  </Link>
                </span>
                : ""} */}
            </div>

            <img
              src={props?.nftEvent?.coverImage}
              alt={props?.nftEvent?.title}
            />
          </div>
          <div className="event-footer">
            <div className="ent-logo">
              <img src={props?.nftEvent?.profileImage} alt="Event-Thumbnail" />
            </div>
            <h6>{props?.nftEvent?.nft_contracts.name}</h6>            
          </div>
        </div>
      </Link>
    </div>
  );
};

export default EventCard;
