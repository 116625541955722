import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

// ------- window screen size -----------
export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

// ------- Check URL validation -----------
export const isValidUrl = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

// ------- Check Email ID validation -----------
export const emailValidation = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;

// ------- Check Image URL validation -----------
export const checkImgURL = (url: any) => {
  return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

// ------- Set Paginate count per page -----------
export const pageSize = 12

// ------- Chain Network Data -----------
export const chaindata = {
  method: "wallet_addEthereumChain",
  params: [
    {
      chainId: `0x${parseInt(process.env.REACT_APP_CELO_CHAIN_ID as any).toString(16)}`,
      chainName: process.env.REACT_APP_CELO_CHAIN_NAME,
      nativeCurrency: {
        name: process.env.REACT_APP_CELO_SHORT_NAME,
        symbol: process.env.REACT_APP_CELO_SYMBOL,
        decimals: 18
      },
      rpcUrls: [process.env.REACT_APP_CELO_RPC_URL],
      blockExplorerUrls: [process.env.REACT_APP_CELO_EXPLORER_URL],
    }
  ]
}

// ----------- User Wallet Address -----------
export const userWalletAddress = localStorage.getItem("walletAddress");
export const MED_GAS = '300000000000000';
export const networkChain = process.env.REACT_APP_NETWOEK_DEV;

