import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import TicketCard from "../../../components/cards/TicketCard";
import Line from "../../../components/Line";
import allActions from "../../../Redux/actions";

function Topsale() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.fetchNftTicketsByEventId("634142c7a001b12b0bdaaa0d") as any
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchedNftTicketsByEventId = useSelector(
    (state: any) => state.nftTicketReducerData?.nftTicketsByEventIdData
  );
  const topsale = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          infinite: true,
          speed: 300,
        },
      },
    ],
  };

  return (
    <>
      {fetchedNftTicketsByEventId && fetchedNftTicketsByEventId?.NftTickets ? (
        <div className="section topsale pb-0">
          <div className="container">
            <h1 data-aos="fade-right">Top Selling Tickets</h1>
            <Line></Line>
            <p className="max-667" data-aos="fade-left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <div className="mt-36">
              <Slider {...topsale}>
                {fetchedNftTicketsByEventId &&
                  fetchedNftTicketsByEventId?.NftTickets?.slice(0, 6).map(
                    (nftTicketData: any, index: any) => {
                      return (
                        <TicketCard nftTicket={nftTicketData} key={index} />
                      );
                    }
                  )}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Topsale;
