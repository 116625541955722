import { bindActionCreators } from "redux";
import { store } from "../baseApi/nftTicketBaseApi";
import { EVENT_CITY, EVENT_COUNTRY, EVENT_COVER, EVENT_DESC, EVENT_DISCORD, EVENT_END_DATE, EVENT_EXTERNAL_LINK, EVENT_FACEBOOK, EVENT_ID, EVENT_LOCATION, EVENT_NAME, EVENT_OWNER, EVENT_PROFILE, EVENT_PUBLISH, EVENT_START_DATE, EVENT_STATE, EVENT_TELEGRAM, EVENT_TICKET_DATA, EVENT_TICKET_STEP, EVENT_TICKET_TYPE, EVENT_TWITTER, EVENT_TYPE, EVENT_VALIDATION, SET_EDIT_USER_INFORMATION, TICKET_SUPPLAY } from "./actionTypes";
import { createTicketType } from "./createTicketTypeActions";

const evntId = (value: string | null) => ({ type: EVENT_ID, payload: value });

const evntName = (value: string | null) => ({ type: EVENT_NAME, payload: value });

const eventDesc = (value: string | null) => ({ type: EVENT_DESC, payload: value });

const eventType = (value: string | null) => ({ type: EVENT_TYPE, payload: value });

const ticketsupply = (value: string | null) => ({ type: TICKET_SUPPLAY, payload: value });

const eventCountry = (value: string | null) => ({ type: EVENT_COUNTRY, payload: value });

const eventState = (value: string | null) => ({ type: EVENT_STATE, payload: value });

const eventCity = (value: string | null) => ({ type: EVENT_CITY, payload: value });

const eventLocation = (value: string | null) => ({ type: EVENT_LOCATION, payload: value });

const eventExternalLink = (value: string | null) => ({ type: EVENT_EXTERNAL_LINK, payload: value });

const eventProfile = (value: string | null) => ({ type: EVENT_PROFILE, payload: value });

const eventCover = (value: string | null) => ({ type: EVENT_COVER, payload: value });

const eventTwitter = (value: string | null) => ({ type: EVENT_TWITTER, payload: value });

const eventFacebook = (value: string | null) => ({ type: EVENT_FACEBOOK, payload: value });

const eventDiscord = (value: string | null) => ({ type: EVENT_DISCORD, payload: value });

const eventTelegram = (value: string | null) => ({ type: EVENT_TELEGRAM, payload: value });

const eventStartDate = (value: string | null) => ({ type: EVENT_START_DATE, payload: value });

const eventEndDate = (value: string | null) => ({ type: EVENT_END_DATE, payload: value });

const eventValidation = (value: boolean) => ({ type: EVENT_VALIDATION, payload: value });

const eventTicketType = (value: any | null) => ({ type: EVENT_TICKET_TYPE, payload: value });

const eventTicketData = (value: any | null) => ({ type: EVENT_TICKET_DATA, payload: value });

const eventTicketStep = (value: Number) => ({ type: EVENT_TICKET_STEP, payload: value });

const eventPublished = (value: string | null) => ({ type: EVENT_PUBLISH, payload: value });

const editUserData = (value: any | null) => ({ type: SET_EDIT_USER_INFORMATION, payload: value });

const editUserSetting = (value: any | null) => ({ type: SET_EDIT_USER_INFORMATION, payload: value });

const eventOwner = (value: any | null) => ({ type: EVENT_OWNER, payload: value });

export const actionCreators = bindActionCreators(
  {
    evntId,
    evntName,
    eventDesc,
    eventType,
    ticketsupply,
    eventCountry,
    eventState,
    eventCity,
    eventLocation,
    eventExternalLink,
    eventProfile,
    eventCover,
    eventTwitter,
    eventFacebook,
    eventDiscord,
    eventTelegram,
    eventStartDate,
    eventEndDate,
    eventValidation,
    eventTicketType,
    eventTicketData,
    eventTicketStep,
    createTicketType,
    eventPublished,
    editUserData,
    editUserSetting,
    eventOwner,
  },
  store.dispatch
);