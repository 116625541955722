import { CREATE_TICKET_TYPE, CREATE_TICKET_TYPE_ERROR, CREATE_TICKET_TYPE_FAIL, CREATE_TICKET_TYPE_START, CREATE_TICKET_TYPE_SUCCESS } from "../actions/actionTypes";

 

/////// TYPESCRIPT INTERFACE ////////
interface state {
    status: number | null;
    nftTickeTypeData: any,
    nftTickeTypeError: any | string,
    nftTickeTypeLoader: boolean
}

const INITIAL_STATE = {
    status: null,
    nftTickeTypeData: null,
    nftTickeTypeError: null,
    nftTickeTypeLoader: false
}

const createTickeType = (state: state, action: any) => {

    return {
        ...state,
        status: 200,
        nftTickeTypeData: action.payload
    }
}



const createTickeTypeError = (state: state, action: any) => {
    return {
        ...state,
        status: 500,
        nftTickeTypeError: action.nftTickeTypeError
    }
}

const createTickeTypeStart = (state: state, action: any) => { 
    return {
        ...state,
        status: null,        
        nftTickeTypeLoader: true
    }
}

const createTickeTypeSuccess = (state: state, action: any) => {
    return {
        ...state,
        status: 200,
        nftTickeTypeLoader: false
    }
}

const createTickeTypeFail = (state: state, action: any) => {
    return {
        ...state,
        status: 404,
        nftTickeTypeLoader: false
    }
}

const nftTickeTypeReducer = (state: state = INITIAL_STATE, action: any) => { 
    switch (action.type) {
        default:
            return state;
       
        case CREATE_TICKET_TYPE_START:
            return createTickeTypeStart(state, action);

        case CREATE_TICKET_TYPE_SUCCESS:
            return createTickeTypeSuccess(state, action);

        case CREATE_TICKET_TYPE:
            return createTickeType(state, action);

        case CREATE_TICKET_TYPE_ERROR:
            return createTickeTypeError(state, action);

        case CREATE_TICKET_TYPE_FAIL:
            return createTickeTypeFail(state, action);
    }
}

export default nftTickeTypeReducer;