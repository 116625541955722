import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TicketCard from "../../components/cards/TicketCard";
import TicketCardSkeleton from "../../components/skeleton/TicketCardSkeleton";
import useEventAllNft from "../../hooks/useEventAllNft";
import Burn from "./burnNft";

const NftTickets = (props: any) => {
  const nftId = useParams();
  const id = nftId.id?.toString();
  const { nftList, loading } = useEventAllNft(id);
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [ticketId, setNftId] = useState();

  return (
    <>
      <div className={`mt-5 card-layout ${props?.gridview ? 'layout' : ''}`}>
        {loading === true ? <TicketCardSkeleton /> : <>
          {loading == false && nftList?.map((item: any, index: any) => (<div className="nft-card">
            <TicketCard nftTicket={item} key={index} />
            <div className="action">
              <button onClick={() => {
                setNftId(item.metadataId)
                setPopup(true)
              }}>Burn</button>
              {/* <button>multiply</button> */}
              <button onClick={() => {
                navigate(`/listing/${item.metadataId}`)
              }}>list</button>
              {/* <button>send</button> */}
            </div>
          </div>
          ))} </>}
      </div>
      <div className={`model burn ${popup ? "active" : ""}`} onClick={() => { setPopup(false) }}>
        <div className="small" onClick={(e) => { e.stopPropagation() }}>
          <Burn ticketId={ticketId} />
        </div>
      </div>
    </>
  )

}

export default NftTickets;