import Slider from "react-slick";
import faster from "../../../assets/images/faster_transaction.png";
import goodbye from "../../../assets/images/goodbye_to_forgery.png";
import minimal from "../../../assets/images/minimal_damage.png";
import revenue from "../../../assets/images/revenue_opportunities.png";
import tickets from "../../../assets/images/tickets_that_burn.png";
import Line from "../../../components/Line";

function Banefit() {
  const Banefit = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <div className="section pb-0 banefits">
        <h1 data-aos="fade-right">Tearing down walls and building empires! </h1>
        <Line />
        <p className="max-667" data-aos="fade-left">
          NFTickt has everything a fan, event organizer or artist needs! By
          eliminating fraud and lessening the effects of scalping, we assist
          artists in fostering stronger relationships with their fans.{" "}
        </p>
        <div className="slider-section mt-36">
          <Slider {...Banefit}>
            <div className="banefit-card">
              <div className="iconlist">
                <div className="icon-img">
                  <img src={tickets} alt="ticketicon" />
                </div>
                <div>
                  <h6>Tickets that burn</h6>
                </div>
              </div>
              <p className="mb-0">
                Once an NFTickt is used, it burns into a NFT pass that will help
                you identify the actual journey of the ticket and attendance
                rather than biased and unclear analytics.
              </p>
            </div>
            <div className="banefit-card">
              <div className="iconlist">
                <div className="icon-img">
                  <img src={faster} alt="ticketicon" />
                </div>
                <div>
                  <h6>Less cost and faster transaction</h6>
                </div>
              </div>
              <p className="mb-0">
                At NFTickt you will need less money and time to mint and sell
                tickets, unlike the unreasonable timeline and hefty fees you pay
                to distribute traditional tickets.
              </p>
            </div>
            <div className="banefit-card">
              <div className="iconlist">
                <div className="icon-img">
                  <img src={goodbye} alt="ticketicon" />
                </div>
                <div>
                  <h6>Goodbye to forgery</h6>
                </div>
              </div>
              <p className="mb-0">
                As unique as it can get, there is no scope for forgery to seep
                into NFT ticketing.{" "}
              </p>
            </div>
            <div className="banefit-card">
              <div className="iconlist">
                <div className="icon-img">
                  <img src={revenue} alt="ticketicon" />
                </div>
                <div>
                  <h6>Explore multiple revenue opportunities</h6>
                </div>
              </div>
              <p className="mb-0">
                We provide ongoing royalties to event holders, artists, and
                stakeholders using smart contract technology.{" "}
              </p>
            </div>
            <div className="banefit-card">
              <div className="iconlist">
                <div className="icon-img">
                  <img src={minimal} alt="ticketicon" />
                </div>
                <div>
                  <h6>Minimal damage</h6>
                </div>
              </div>
              <p className="mb-0">
                No fear of losing or damaging your tickets! If traditional
                paper-based tickets are used for the event they can easily be
                damaged or lost. No such risk is included when using an NFT
                ticket.
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Banefit;
