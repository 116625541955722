import axios from "axios";
import { resetCreateForm } from "../../const/resetStore"; 
import { EDIT_EVENT_ERROR, EDIT_EVENT_FAIL, EDIT_EVENT_START, EDIT_EVENT_SUCCESS } from "./actionTypes";
import { actionCreators } from "./eventDataActions";

export const fetchNftEditEventById = (eventId: any) => async (dispatch: any) => {
        resetCreateForm();
        dispatch(fetchNftEditEventIdStart())

        let responsEvent = `${process.env.REACT_APP_BACKEND_URL}/nft-event/getById/${eventId}` 
        let responsTicket = `${process.env.REACT_APP_BACKEND_URL}/nft-ticket/getById/0/10/${eventId}`

        const requestOne = axios.get(responsEvent); 
        const requestTwo = axios.post(responsTicket);

        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            if(responses[0]?.data?.nftEvent){ 
            actionCreators.evntId(responses[0]?.data?.nftEvent?._id);
            actionCreators.evntName(responses[0]?.data?.nftEvent?.title);
            actionCreators.eventDesc(responses[0]?.data?.nftEvent?.description);
            actionCreators.eventType(responses[0]?.data?.nftEvent?.type);
            actionCreators.ticketsupply(responses[0]?.data?.nftEvent?.totalTicketSupply);
            actionCreators.eventDesc(responses[0]?.data?.nftEvent?.description);
            actionCreators.eventLocation(responses[0]?.data?.nftEvent?.location);
            actionCreators.eventCountry(responses[0]?.data?.nftEvent?.country);
            actionCreators.eventState(responses[0]?.data?.nftEvent?.state);
            actionCreators.eventCity(responses[0]?.data?.nftEvent?.city);
            actionCreators.eventExternalLink(responses[0]?.data?.nftEvent?.externalLink);
            actionCreators.eventProfile(responses[0]?.data?.nftEvent?.profileImage);
            actionCreators.eventCover(responses[0]?.data?.nftEvent?.coverImage);
            actionCreators.eventTwitter(responses[0]?.data?.nftEvent?.twitterLink);
            actionCreators.eventFacebook(responses[0]?.data?.nftEvent?.facebookLink);
            actionCreators.eventDiscord(responses[0]?.data?.nftEvent?.discordLink);
            actionCreators.eventTelegram(responses[0]?.data?.nftEvent?.telegramLink);
            actionCreators.eventStartDate(responses[0]?.data?.nftEvent?.startDate);
            actionCreators.eventEndDate(responses[0]?.data?.nftEvent?.endDate);
            actionCreators.eventOwner(responses[0].data?.nftEvent?.eventOwner);
            }
            if(responses[1]?.data?.NftTickets){
                actionCreators.eventTicketType(responses[1]?.data?.NftTickets);
                actionCreators.eventTicketData(responses[1]?.data?.NftTickets);                  
            }
            actionCreators.eventTicketStep(1);   
            dispatch(fetchNftEditEventByIdSuccess()); 
        })).catch(error => { 
            dispatch(fetchNftEditEventIdStart())

            const message = 
                error?.response && error?.response?.data?.message
                    ? error?.response?.data?.message
                    : error?.message
                
            dispatch(fetchNftEditEventNetworkError(message))
    
            dispatch(fetchNftEditEventByIdFail())
        })
        dispatch(fetchNftEditEventByIdSuccess())   
}


 

const fetchNftEditEventNetworkError = (error: any) => async (dispatch: any) => {
    dispatch({
        type: EDIT_EVENT_ERROR,
        fetchNftNetworkErrorFromServer: error
    })
}

const fetchNftEditEventIdStart = () => async (dispatch: any) => {
    dispatch({
        type: EDIT_EVENT_START,
        nftEventLoader: true
    })
}

const fetchNftEditEventByIdSuccess = () => async (dispatch: any) => {
    dispatch({
        type: EDIT_EVENT_SUCCESS,
        nftEventLoader: false
    })
}

const fetchNftEditEventByIdFail = () => async (dispatch: any) => {
    dispatch({
        type: EDIT_EVENT_FAIL,
        nftEventLoader: false
    })
}