import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import LiveEventCard from "../../../components/cards/LiveEvent";
import Line from "../../../components/Line";
import allActions from "../../../Redux/actions";

function LiveEvent() {
  const livevent = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          infinite: true,
          speed: 300,
        },
      },
    ],
  };
  const dispatch = useDispatch();
  const getLiveEvent = useSelector((state: any) => state?.liveEventReducerData);
  useEffect(() => {
    dispatch(allActions.getLiveEvent() as any);
  }, [dispatch]);

  return (
    <>
      {getLiveEvent?.liveEvent?.nftEvents ? (
        <div className="section livevent pb-0">
          <div className="container">
            <h1 data-aos="fade-right">Live Events</h1>
            <Line />
            <p className="max-667" data-aos="fade-left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <div className="slider-section mt-36" data-aos="fade-up">
              <Slider {...livevent}>
                {getLiveEvent?.liveEvent?.nftEvents ? (
                  getLiveEvent?.liveEvent?.nftEvents?.slice(0, 6).map(
                    (liveEvent: any, index: any) => {
                      return (
                        <LiveEventCard liveEvents={liveEvent} key={index} />
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        null
      )}
    </>
  );
}

export default LiveEvent;
