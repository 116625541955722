import { InjectedConnector } from "web3-react-injected-connector-celo"; 

export const injectedcelo = new InjectedConnector({
  supportedChainIds: [42220, 44787]
});

// ========== celo wallet Actions ========== 
export type SendReturnResult = { result: any }
export type SendReturn = any

export type Send = (method: string, params?: any[]) => Promise<SendReturnResult | SendReturn>
export type SendOld = ({ method }: { method: string }) => Promise<SendReturnResult | SendReturn>
export declare const __DEV__: boolean


