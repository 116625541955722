import React from "react"; 
import wallet from '../../assets/images/svg/icons/wallet.svg'; 

function WalletButton() {
    return (     
      <button className="wallet-btn">
        <img src={wallet} alt="wallet" />
        <p>Connect Wallet</p>
      </button>     
    );
  }

export default WalletButton;