import { 
    FETCH_NFT_EVENT_BY_ID, FETCH_NFT_EVENT_BY_ID_FAIL, FETCH_NFT_EVENT_BY_ID_START, 
    FETCH_NFT_EVENT_BY_ID_SUCCESS, FETCH_NFT_EVENT_LISTS_FAIL, FETCH_NFT_EVENT_LISTS_NETWORK_ERROR, 
    FETCH_NFT_EVENT_LISTS_START, FETCH_NFT_EVENT_LISTS_SUCCESS, FETCH_NFT_EVENT_LISTS_WITHOUT_FILTERING, 
    FETCH_NFT_EVENT_LISTS_WITH_FILTERING, FETCH_NFT_EVENT_NETWORK_ERROR, 
    SET_SELECTED_NFT_EVENT_ID 
} from "./actionTypes";

import nftTicketBaseApi from "../baseApi/nftTicketBaseApi";
import { pageSize } from "../../const/config";

export const fetcHNftEventById = (eventId: any) => async (dispatch: any) => {

    try {

        dispatch(fetchNftEventByIdStart())

        const response = await nftTicketBaseApi.get(`/nft-event/getById/${eventId}`);

        dispatch({
            type: FETCH_NFT_EVENT_BY_ID,
            payload: response.data
        })

        if (response.data?.nftEvent?._id) {
            dispatch(setSelectedNftEventId(response.data?.nftEvent?._id));
        }

        dispatch(fetchNftEventByIdSuccess())


    } catch (error: any) {
        dispatch(fetchNftEventByIdStart())

        const message = 
            error?.response && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
            
        dispatch(fetchNftEventNetworkError(message))

        dispatch(fetchNftEventByIdFail())

    }

}

/// STORE THE CURRENT OR SELECTED NFT EVENT ID HANDLER /////
const setSelectedNftEventId = (selectedNftEventId: string) => async (dispatch: any) => {

    dispatch({
        type: SET_SELECTED_NFT_EVENT_ID,
        setSelectedNftEventId: selectedNftEventId
    })

}

const fetchNftEventNetworkError = (error: any) => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_EVENT_NETWORK_ERROR,
        fetchNftNetworkErrorFromServer: error
    })
}

const fetchNftEventByIdStart = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_EVENT_BY_ID_START,
        nftEventLoader: true
    })
}

const fetchNftEventByIdSuccess = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_EVENT_BY_ID_SUCCESS,
        nftEventLoader: false
    })
}

const fetchNftEventByIdFail = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_EVENT_BY_ID_FAIL,
        nftEventLoader: false
    })
}


/////////////////////////////////////////////////////////////////////////////////////////////////
//// FETCH NFT EVENT LIST ACTION ////
export const fetchNftEventListsWithFiltering = (data: any,activepage:any) => async (dispatch: any) => {

    try {

        let body ={};
        body = {...data};
        dispatch(fetchNftEventListsStart())

        const response = await nftTicketBaseApi.post(`/nft-event/get/${activepage}/${pageSize}`, body  );
        dispatch({
            type: FETCH_NFT_EVENT_LISTS_WITH_FILTERING,
            payload: response.data,
            nftEventListsWithFiltering: true,
            nftEventListsWithoutFiltering: false,
            nftEventListsNetworkError: null
        })

        dispatch(fetchNftEventListsSuccess())
    } catch (error: any) {

        dispatch(fetchNftEventListsStart())

        const message = error?.response && error?.response?.data?.message ? 
            error?.response?.data?.message
            : error?.message;

        dispatch(fetchNftEventListsNetworkError(message))
        dispatch(fetchNftEventListsFail())
    }
}

export const fetchNftEventListsWithoutFiltering = (activepage:any) => async (dispatch: any) => {

    try {
        dispatch(fetchNftEventListsStart())

        const response = await nftTicketBaseApi.post(`nft-event/get/${activepage}/${pageSize}`);

        dispatch({
            type: FETCH_NFT_EVENT_LISTS_WITHOUT_FILTERING,
            payload: response.data,
            nftEventListsWithoutFiltering: true,
            nftEventListsWithFiltering: false,
            nftEventListsNetworkError: null
        })

        dispatch(fetchNftEventListsSuccess())

    } catch (error: any) {

        dispatch(fetchNftEventListsStart())

        const message = 
            error?.response && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message;

        dispatch(fetchNftEventListsNetworkError(message))
        dispatch(fetchNftEventListsFail())
    }

}

const fetchNftEventListsStart = () => async (dispatch: any) => {
    
    dispatch({
        type: FETCH_NFT_EVENT_LISTS_START,
        nftEventListsLoader: true
    })
}

const fetchNftEventListsSuccess = () => async (dispatch: any) => {

    dispatch({
        type: FETCH_NFT_EVENT_LISTS_SUCCESS,
        nftEventListsLoader: false
    })
}

const fetchNftEventListsFail = () => async (dispatch: any) => {

    dispatch({
        type: FETCH_NFT_EVENT_LISTS_FAIL,
        nfEventListLoader: false
    })
}

const fetchNftEventListsNetworkError = (error: any) => async (dispatch: any) => {

    dispatch({
        type: FETCH_NFT_EVENT_LISTS_NETWORK_ERROR,
        nftEventListsNetworkError: error
    })
}