import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import editicon from '../../assets/images/svg/icons/edit-icon.svg';
import remove from '../../assets/images/svg/icons/trash.svg';
import toast from "react-hot-toast";
import Switch from "../../components/switch/Switch";
import Line from "../../components/Line";
import { store } from "../../Redux/baseApi/nftTicketBaseApi";
import { getUserSetting, removeProfile, setProfile, setUserProfile } from "../../services/userProfile";
import { useWallet } from "../../services/providers/MintbaseWalletContext";

function Myprofile() {
    const userDetails = store.getState().userData.userDetails;
    const [subscribe, setSubscribe] = useState(userDetails.subscription);
    const [receipt, setReceipt] = useState(userDetails.receipt);
    const [name, setName] = useState(userDetails != null ? userDetails.name : '');
    const [emailid, setEmailId] = useState(userDetails != null ? userDetails.email : "");
    const { wallet, isConnected, details } = useWallet();

    useEffect(() => {
        if (subscribe == true && !emailid || receipt == true && !emailid) {
            toast.error("Email address is required")
            setSubscribe(false);
            setReceipt(false);
        }
        getUserSetting(subscribe, receipt);
    }, [subscribe, receipt])

    return (
        <div className="myprofile">
            <div className="container">
                <div className="details">
                    <div className="avater">
                        {!userDetails.profile_image ? "" : <img src={userDetails.profile_image} alt="Profile" className="img-flued" />}
                        <div className="edit-icon">
                            <label htmlFor="upload"><img src={editicon} alt="Edit" /></label>
                            <input type="file" name="" id="upload" className="d-none" onChange={async (e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/gif') {
                                        const file = e.target.files[0];
                                        setProfile(file);
                                    } else {
                                        toast.error("Not supported this type of file");
                                    }
                                }
                            }} />
                            <img src={remove} alt="Profile" className="img-flued remove-img" onClick={removeProfile}
                            />
                        </div>
                    </div>
                    <div>
                        {isConnected ? <h2 data-toggle="tooltip" data-placement="top" title="Tooltip on top"> {userDetails.wallet_address?.toString()}</h2> :
                            <h2 data-toggle="tooltip" data-placement="top" title="Tooltip on top"> {userDetails.wallet_address?.toString().slice(0, 8)}...{userDetails.wallet_address?.toString().slice(-3)}</h2>
                        }
                        <p>{userDetails.email}</p>
                    </div>
                </div>
                <div className="input-filed">
                    <div className="form">
                        <h4 >User Information</h4>
                        <Line />
                        <div className="mb-4">
                            <label >Name</label>
                            <input type="text" placeholder="Enter your Name" value={name} onChange={(e: any) => { setName(e.target.value) }} />
                        </div>
                        <div className="">
                            <label >Email Address</label>
                            <input type="email" placeholder="Enter your Email Address" value={emailid} onChange={(e: any) => { setEmailId(e.target.value) }} />
                        </div>
                        <Button classname="primarybtn" icon={false} name={'save'} click={() => { setUserProfile(emailid, name) }} />
                        <div className="settings">
                            <h4 >Settings</h4>
                            <Line />
                            <div className="filed-section d-flex">
                                <div>
                                    <p>Subscribe Email</p>
                                    <span>I would like to be receive subscribe notification</span>
                                </div>
                                <div>
                                    <Switch id="Subscribe" value={subscribe} setvalue={setSubscribe} />
                                </div>
                            </div>
                            <div className="filed-section d-flex">
                                <div>
                                    <p>Checkout Email</p>
                                    <span>I would like to be receive transaction receipt</span>
                                </div>
                                <div>
                                    <Switch id="Checkout" value={receipt} setvalue={setReceipt} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Myprofile;