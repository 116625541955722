import {FETCH_NFT_MY_EVENT_ID,FETCH_NFT_MY_EVENT_ERROR,FETCH_NFT_MY_EVENT_START,FETCH_NFT_MY_EVENT_SUCCESS, FETCH_NFT_MY_EVENT_FAIL} from './actionTypes';

import nftTicketBaseApi from "../baseApi/nftTicketBaseApi";


export const fetchMyEvent =(page:any, pagesize:any) => async(dispatch:any) =>{
    try{
        dispatch(fetchMyEventStart())
        const response = await nftTicketBaseApi.get(`nft-event/getMyEvents/${page}/${pagesize}`);
        dispatch({
            type: FETCH_NFT_MY_EVENT_ID,
            payload: response.data
        })

        if (response.data?.nftEvent?._id) {
            dispatch(setSelectedNftEventId(response.data?.nftEvent?._id));
        }

        dispatch(fetchMyEventSuccess())
    }catch (error: any) {
        const message = 
        error?.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        
            dispatch(fetchMyEventError(message))

            dispatch(fetchMyEventFail())

    }
}

const setSelectedNftEventId = (selectedNftEventId: string) => async (dispatch: any) => {

    dispatch({
        type: FETCH_NFT_MY_EVENT_ID,
        setSelectedNftEventId: selectedNftEventId
    })

}

const fetchMyEventError = (error: any) => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_MY_EVENT_ERROR,
        fetchNftNetworkErrorFromServer: error
    })
}

const fetchMyEventStart = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_MY_EVENT_START,
        nftEventLoader: true
    })
}

const fetchMyEventSuccess = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_MY_EVENT_SUCCESS,
        nftEventLoader: false
    })
}

const fetchMyEventFail = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_MY_EVENT_FAIL,
        nftEventLoader: false
    })
}