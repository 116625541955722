import Eventheader from "../../pages/eventdetails/components/EventHeader"
import { useSelector, useDispatch } from "react-redux";
import { useEffect} from "react";
import allActions from "../../Redux/actions";
import { useParams } from "react-router-dom";
import useStoresDetails from "../../hooks/useStoresDetails";


  /// SINGLE NFT EVENT ////
  const NftEvent = () => {
    let { id } = useParams(); 
    const { storesDetails, loading } = useStoresDetails(id)
    return (

      <>     
         <Eventheader data={storesDetails}/>
      </>
    )

  }
export default NftEvent;