import React from "react";
import { Link } from "react-router-dom";
type propsLive = {
  liveEvents?: {
    _id:string;
    title: string;
    coverImage: string;
    description: string;
  };
};
const LiveEvent = (props: propsLive) => {
  return (
    <Link to={`/event-details/${props?.liveEvents?._id}`}>
    <div className="live-event-card">
      <div className="overlay"></div>
      <img src={props?.liveEvents?.coverImage} alt="Event card" />
      <div className="live">
        <span className="live-icon"></span>
        <p>Live</p>
      </div>
      <div className="content">
        <h6>{props?.liveEvents?.title}</h6>
        <p>{props.liveEvents?.description} </p>
      </div>
    </div>
    </Link>
  );
};

export default LiveEvent;
