import { CREATE_EVENT, CREATE_EVENT_ERROR, CREATE_EVENT_START, CREATE_EVENT_SUCCESS, CREATE_EVENT_FAIL } from "../actions/actionTypes";

/////// TYPESCRIPT INTERFACE ////////
interface state {
    status: number | null;
    nftEventData: any,
    fetchNftNetworkErrorFromServer: any | string,
    nftEventLoader: boolean
}

const INITIAL_STATE = {
    status: null,
    nftEventData: null,
    fetchNftNetworkErrorFromServer: null,
    nftEventLoader: false
}

const createEvent = (state: state, action: any) => {

    return {
        ...state,
        status: 200,
        nftEventData: action.payload
    }
}



const createEventError = (state: state, action: any) => {
    return {
        ...state,
        status: 500,
        fetchNftNetworkErrorFromServer: action.fetchNftNetworkErrorFromServer
    }
}

const createEventStart = (state: state, action: any) => { 
    return {
        ...state,        
        nftEventLoader: true
    }
}

const createEventSuccess = (state: state, action: any) => {
    return {
        ...state,
        status: 200,
        nftEventLoader: false
    }
}

const createEventFail = (state: state, action: any) => {
    return {
        ...state,
        status: 404,
        nftEventLoader: false
    }
}

const nftEventsReducer = (state: state = INITIAL_STATE, action: any) => { 
    switch (action.type) {
        default:
            return state;
       
        case CREATE_EVENT_START:
            return createEventStart(state, action);

        case CREATE_EVENT_SUCCESS:
            return createEventSuccess(state, action);

        case CREATE_EVENT:
            return createEvent(state, action);

        case CREATE_EVENT_ERROR:
            return createEventError(state, action);


        case CREATE_EVENT_FAIL:
            return createEventFail(state, action);
    }
}

export default nftEventsReducer;