import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useWallet } from "../../../services/providers/MintbaseWalletContext";
import NearCreateTicket from "./CreateTicket";

function CreateStore() {
  const { wallet, isConnected, details } = useWallet();
  const symbol = process.env.REACT_APP_NEAR_STORE_SYMBOL;
  const { register, handleSubmit, watch, formState: { errors } } = useForm(
    {
      mode: "all",
      defaultValues: {
        name: "",
        symbol: symbol?.toString()
      },
    }
  );
  const onSubmit = async (data: any) => {
    await wallet?.deployStore(data.name, data.symbol);
  };

  return (
    <>
      <h6><span>Create Event</span></h6>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="filed-section">
          <label  >Event Name <span>*</span></label>
          <input type="text" placeholder="Enter title name" required
            {...register("name", {
              required: true,
            })} />
        </div>
        <button type="submit" className="primarybtn">Submit</button>
      </form>
    </>
  )

}

export default CreateStore;