import React from "react";
import arrow from './../../assets/images/svg/icons/btn-arrow.svg';
interface propse {
    name: string; 
    classname: string; 
    click: any;
    icon:boolean;
}

function Button({ click,classname, name,icon }: propse) {
    return (
        <button className={classname} onClick={click}>
            <span>{name}</span>
            {icon === true ? <img src={arrow} alt="arrow" /> :''}
        </button>
    );
}

export default  Button;