import Slider from "react-slick";
import sytemwork from './../../../assets/images/systemwork.png'
import Banefit from "./Banefits";

function Sytemwork() {
    const systemwork = {
        dots: false,
        infinite: false,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,

    };


    return (
        <div className="section systemwork pb-0">
            <div className="container ">
                <div className="system-slider">
                    <Slider {...systemwork}>
                        <div className="slider-card">
                            <img className="slider-img" src={sytemwork} alt="system work" data-aos="fade-right"/>
                            <div className="slider-content" data-aos="fade-left">
                                <h1>Rebalancing the Live Event Market </h1>
                                <p className="max-667">Thanks to Web3 technology, we have a fantastic opportunity to generate real value for everyone involved in the ticketing industry. It's simple and hassle-free to buy NFT tickets and other items on NFTickt. Even the most crypto-averse event attendees find switching from traditional ticketing platforms to this one simple thanks to our amazing user interface.
</p>
                            </div>
                            <div className="slider-content-list" data-aos="fade-up">
                                <div className="">
                                    <h6>Create a NFTickt account in minutes</h6>
                                    <p >You'll need to register on NFTickt to buy tickets and other NFT assets. Create a username, verify your phone number, connect your wallet, and you'll be ready to make your first purchase in no time.
</p>
                                </div>
                                <div className="">
                                    <h6>Sell tickets and track them seamlessly </h6>
                                    <p>If you're an artist or an event organizer, you can create an event and sell tickets effortlessly. Every option is customizable, thereby making the process as simple as it can get. Start selling tickets and track each one of them in your analytics dashboard with the click of a button. 
</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="slider-card">
                            <img className="slider-img" src={sytemwork} alt="system work" />
                            <div className="slider-content">
                                <h1>How Ticketing System Working</h1>
                                <p className="max-667">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo G</p>
                            </div>
                            <div className="slider-content-list">
                                <div className="">
                                    <h6>Metapass Dashboard to view event statistics</h6>
                                    <p  >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo G</p>
                                </div>
                                <div className="">
                                    <h6>One time QR code for IRL Events</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo G</p>
                                </div>
                            </div>
                        </div>
                        <div className="slider-card">
                            <img className="slider-img" src={sytemwork} alt="system work" />
                            <div className="slider-content">
                                <h1>How Ticketing System Working</h1>
                                <p className="max-667">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo G</p>
                            </div>
                            <div className="slider-content-list">
                                <div className="">
                                    <h6>Metapass Dashboard to view event statistics</h6>
                                    <p  >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo G</p>
                                </div>
                                <div className="">
                                    <h6>One time QR code for IRL Events</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo G</p>
                                </div>
                            </div>
                        </div>
                        <div className="slider-card">
                            <img className="slider-img" src={sytemwork} alt="system work" />
                            <div className="slider-content">
                                <h1>How Ticketing System Working</h1>
                                <p className="max-667">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo G</p>
                            </div>
                            <div className="slider-content-list">
                                <div className="">
                                    <h6>Metapass Dashboard to view event statistics</h6>
                                    <p  >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo G</p>
                                </div>
                                <div className="">
                                    <h6>One time QR code for IRL Events</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo G</p>
                                </div>
                            </div>
                        </div> */}
                    </Slider>
                </div>
                <Banefit />
            </div>
        </div>
    );
}

export default Sytemwork;