import toast from "react-hot-toast";
import { emailValidation } from "../const/config";
import nftTicketBaseApi from "../Redux/baseApi/nftTicketBaseApi";

export const newsLatter = async (mail:string,setEmail:any) => { 

    
    if(emailValidation.test(mail) === true){
      const body = {
        email:mail
    }
    const response = await nftTicketBaseApi.post(`/subscription/newsletter-signup`,body);
    if(response.status === 200){
      toast.success(response.data.message);
      setEmail("");
    }else{
      toast.error("Email subscription failed. Please try again")
    }
 
    }else{
     toast.error("Please enter the valid email id");
    }
    

     
  };