import { FETCH_NFT_TICKET_ID, FETCH_NFT_TICKET_ERROR, FETCH_TICKET_START, FETCH_TICKET_SUCCESS, FETCH_TICKET_FAIL, GET_TICKET_ACTIVITY_FROM_CHAIN, TICKET_ACTIVITY_FROM_CHAIN_START, TICKET_ACTIVITY_FROM_CHAIN_SUCCESS, TICKET_ACTIVITY_FROM_CHAIN_FAIL, TICKET_ACTIVITY_FROM_CHAIN_ERROR } from "../actions/actionTypes";

/////// INTERFACES ////////
interface state { 
    nftTicketByIdData: any,
    fetchNftTicketNetworkErrorServer: any,
    nftTicketLoader: boolean,
    ticketActivityLoader: boolean,
    ticketActivityChainError: any,
    ticketActivity: any
}

const INITIAL_STATES = { 
    nftTicketByIdData: null,
    fetchNftTicketNetworkErrorServer: null,
    nftTicketLoader: true,
    ticketActivityLoader: false,
    ticketActivityChainError: null,
    ticketActivity: null
}

const fetchNftTicketByIdHandler = (state: state, action: any) => { 
    return {
        ...state,
        nftTicketByIdData: action.payload,
        nftTicketLoader: false
    }
}

const fetchNftTicketNetworkErrorHandler = (state: state, action: any) => {
    return {
        ...state,
        fetchNftTicketNetworkErrorServer: action.fetchNftTicketsNetworkError,
        nftTicketLoader: false
    }
}

const fetchNftTicketStartHandler = (state: state, action: any) => {
    return {
        ...state,
        nftTicketData: true,
        nftTicketLoader: false
    }
}

const fetchNftTicketSuccessHandler = (state: state, action: any) => {
    return {
        ...state,
        nftTicketData: false,
        nftTicketLoader: false
    }
}

const fetchNftTicketFailHandler = (state: state, action: any) => {
    return {
        ...state,
        nftTicketData: false,
        nftTicketLoader: false
    }
}

//// TICKET ACTIVITY /////
const ticketActivityFromChainHandler = (state: state, action: any) => { 
    return {
        ...state,
        ticketActivity: action.payload,
        ticketActivityLoader: action.ticketActivityLoader
    }
}

const ticketActivityStartHandler = (state: state, action: any) => {
    return {
        ...state,
        ticketActivityLoader: action.ticketActivityLoader
    }
}

const ticketActivitySuccessHandler = (state: state, action: any) => {
    return {
        ...state,
        ticketActivityLoader: action.ticketActivityLoader
    }
}

const ticketActivityFailHandler = (state: state, action: any) => {
    return {
        ...state,
        ticketActivityLoader: action.ticketActivityLoader
    }
}

const ticketActivityErrorHandler = (state: state, action: any) => {
    return {
        ...state,
        ticketActivityLoader: action.ticketActivityLoader,
        ticketActivityChainError: action.chainError,
        ticketActivity: null
    }
}

const nftTicketsReducer = (state: state = INITIAL_STATES, action: any) => {
    switch(action.type) {
        default:
            return state;
        
        case FETCH_NFT_TICKET_ID:
            return fetchNftTicketByIdHandler(state, action);

        case FETCH_NFT_TICKET_ERROR:
            return fetchNftTicketNetworkErrorHandler(state, action);

        case FETCH_TICKET_START:
            return fetchNftTicketStartHandler(state, action);

        case FETCH_TICKET_SUCCESS:
            return fetchNftTicketSuccessHandler(state, action);

        case FETCH_TICKET_FAIL:
            return fetchNftTicketFailHandler(state, action);
         
        case GET_TICKET_ACTIVITY_FROM_CHAIN:
            return ticketActivityFromChainHandler(state, action);

        case TICKET_ACTIVITY_FROM_CHAIN_START:
            return ticketActivityStartHandler(state, action);

        case TICKET_ACTIVITY_FROM_CHAIN_SUCCESS:
            return ticketActivitySuccessHandler(state, action);

        case TICKET_ACTIVITY_FROM_CHAIN_FAIL:
            return ticketActivityFailHandler(state, action);

        case TICKET_ACTIVITY_FROM_CHAIN_ERROR:
            return ticketActivityErrorHandler(state, action);
    }
}

export default nftTicketsReducer;