import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button"; 
import { useNavigate, useParams } from "react-router-dom";
import useNftTokenDetails from "../../hooks/useNftTokenDetails"; 
import { useWallet } from "../../services/providers/MintbaseWalletContext";
import { nearToYocto } from "../../lib/numbers";
import Skeleton from "react-loading-skeleton";
import { execute, list, NearContractCall, transfer } from '@mintbase-js/sdk';
import { providers } from "near-api-js";
const ListingEvent = () => {
  const nftId = useParams();
  const id = nftId.id?.toString();
  const { nftList, loading } = useNftTokenDetails(id);
  const [num, setNum] = useState(1);
  const [price , setPrice] = useState(null);
  const [validation, setValidation] = useState(false);
  const { wallet, isConnected, details } = useWallet();
  const [name, setName] = useState(null)
  const [store, setStore] = useState(null)
  const [image, setImage] = useState(null)
  const navigate = useNavigate(); 
  const increment = () => {
    if (nftList) {
      if (num < nftList?.length) {
        setNum(Number(num) + 1);
      }
    }
  };
  const decrement = () => {
    if (num > 1) {
      setNum(num - 1);
    }
  };

  const handleChange = (e: any) => {
    setNum(e.target.value);
  };

  
  
  const listing = async ()=>{ 
    if(!price){ 
      setValidation(true)
    }else{
      setValidation(false);
      const value = await nearToYocto(price)
      if(value){       
        if(num === 1) {           
          await wallet?.list(nftList[0].token_id,nftList[0].nft_contract_id, value ,{
            callbackUrl: `${window.location.origin}/event-details/${nftList[0].token_id}`, 
            marketAddress:"market-v2-beta.mintspace2.testnet"
          }); 
          
        }else{       
          const ticketId = nftList.map((element:any)=> element.token_id);   
          await wallet?.batchList(ticketId, nftList[0].nft_contract_id, value ,{
            callbackUrl: `${window.location.origin}/event-details/${nftList[0].nft_contract_id}`, 
            marketAddress:"market-v2-beta.mintspace2.testnet"
          },); 
        }
      }
    }
  } 

  useEffect(()=>{
    if(nftList){
      setName(nftList[0].title);
      setStore(nftList[0].nft_contract_id) 
      setImage(nftList[0].media)
      setNum(nftList.length);
    }
  },[nftList])

  return (
    <>
      <Helmet>
        <title>NFT Listing</title>
      </Helmet>
      <div className="container listing">
        <h1>List NFT For Sale</h1>
        <div className="row">
          <div className="col-lg-4 listing-profile">
            <div className="avather">
              {image ? 
              <div>

              <img src={image} alt="" />
              </div>
              :  <Skeleton width={"320px"} height={"320px"} />}
            </div>
            <p>{store || <Skeleton className="mt-2 mb-2" width={"100%"} />}</p>
            <h2>{name || <Skeleton className="mt-2 mb-2" width={"100%"} />}</h2>
          </div>
          <div className="col-lg-8">
            <div className="content">
              <div className="mb-4">
                <label >Avaliable Tikcet : <span  >{nftList?.length || 0}</span></label><br /> 
              </div>
              <div className="price-card p-0 m-0">
              {/* <label >Number of ticket listing</label> <br /> */}
                {/* <div className="quantity-group mt-0">
                  <div className="input-group">
                    <div className="input-group-dec">
                      <button className="counter-btn" type="button" onClick={decrement}>-</button>
                    </div>
                    <input type="text" className="form-control p-0 m-0" value={num} disabled={true} onChange={handleChange} />
                    <div className="input-group-inc">
                      <button className="counter-btn" type="button" onClick={increment}>+</button>
                    </div>
                  </div>
                </div> */}
                <div className="filed-section">
                  <label className="required" >Price </label> <br />
                  <input type="text" className={`p-3 ${validation && !price ?"error" :""}`} onChange={(e:any)=>{setPrice(e.target.value)}} placeholder="Enter price in near" />
                </div> 
                <div className="text-center mt-4">
                <Button name={'View NFT'} click={()=>{
                   navigate(`/nft-details/${id}`)
                }} icon={false} classname={"secondarybtn"}/>
                <Button name={'Make Listing'} click={listing}  icon={false} classname={"primarybtn"}/>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingEvent;
