import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NftEvent from "../../../containerComponents/nftEvent/NftEvent";
import EventNftTickets from "../../../containerComponents/nftTickets/EventNftTickets";
import NftTickets from "../../../containerComponents/nftTickets/NftTickets";


function MyEventDetails() { 
  
  const [gridview, setGridView] = useState(false);
  const fetchedNftEvent = useSelector((state: any) => state.nftEventReducerData); 

  return (
    <div className="eventdetails">
      <Helmet>
        <title>My Event Details | Nftickt</title>
      </Helmet>
      <div className="banner">
        {!fetchedNftEvent?.nftEventData?.nftEvent?.coverImage ? <>
          <Skeleton width={"100%"} height={"500px"} />
        </> :
          <div className="detail-banner" style={{
            backgroundImage: `url(${fetchedNftEvent?.nftEventData?.nftEvent?.coverImage})`,
          }}>
          </div>}
      </div>
      <div className="container">
        <NftEvent />
        <EventNftTickets gridview={gridview} setGridView={setGridView} />
      </div>
    </div>
  );
}

export default MyEventDetails;