import { CREATE_TICKET_PUBLISH, CREATE_TICKET_PUBLISH_ERROR, CREATE_TICKET_PUBLISH_FAIL, CREATE_TICKET_PUBLISH_START, CREATE_TICKET_PUBLISH_SUCCESS } from "../actions/actionTypes";

/////// TYPESCRIPT INTERFACE ////////
interface state {
    status: number | null;
     createTicketPublishData: any,
     createTicketPublishError: any | string,
     createTicketPublishLoader: boolean
}

const INITIAL_STATE = {
    status: null,
     createTicketPublishData: null,
     createTicketPublishError: null,
     createTicketPublishLoader: false
}

const  createTicketPublish = (state: state, action: any) => {

    return {
        ...state,
        status: 200,
         createTicketPublishData: action.payload
    }
}



const  createTicketPublishError = (state: state, action: any) => {
    return {
        ...state,
        status: 500,
         createTicketPublishError: action.createTicketPublishError
    }
}

const  createTicketPublishStart = (state: state, action: any) => { 
    return {
        ...state,        
         createTicketPublishLoader: true
    }
}

const  createTicketPublishSuccess = (state: state, action: any) => {
    return {
        ...state,
        status: 200,
         createTicketPublishLoader: false
    }
}

const  createTicketPublishFail = (state: state, action: any) => {
    return {
        ...state,
        status: 404,
         createTicketPublishLoader: false
    }
}

const nftTickeEventPublishReducer = (state: state = INITIAL_STATE, action: any) => { 
    switch (action.type) {
        default:
            return state;
       
        case CREATE_TICKET_PUBLISH_START:
            return  createTicketPublishStart(state, action);

        case CREATE_TICKET_PUBLISH_SUCCESS:
            return  createTicketPublishSuccess(state, action);

        case CREATE_TICKET_PUBLISH:
            return  createTicketPublish(state, action);

        case CREATE_TICKET_PUBLISH_ERROR:
            return  createTicketPublishError(state, action);

        case CREATE_TICKET_PUBLISH_FAIL:
            return  createTicketPublishFail(state, action);
    }
}

export default nftTickeEventPublishReducer;