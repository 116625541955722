import { actionCreators } from "../Redux/actions/eventDataActions";

export const resetCreateForm = () => {
    // const date = new Date();
   
    actionCreators.evntName(null);
    actionCreators.evntId(null);
    actionCreators.evntName('');
    actionCreators.eventDesc('');
    actionCreators.eventType('');
    actionCreators.ticketsupply('');
    actionCreators.eventCountry('');
    actionCreators.eventState('');
    actionCreators.eventCity('');
    actionCreators.eventLocation('');
    actionCreators.eventExternalLink('');
    actionCreators.eventProfile('');
    actionCreators.eventCover('');
    actionCreators.eventTwitter('');
    actionCreators.eventFacebook('');
    actionCreators.eventDiscord('');
    actionCreators.eventTelegram('');
    actionCreators.eventStartDate(null);
    actionCreators.eventEndDate(null);
    actionCreators.eventValidation(false);
    actionCreators.eventTicketType(null);
    actionCreators.eventTicketData(null);
    actionCreators.eventTicketStep(1);
    actionCreators.eventPublished("Pending");
}
