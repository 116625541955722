import React, { useEffect } from "react";
import celologo from "../assets/images/celo-celo.png";
import Walletlogo from "../assets/images/svg-icons/metawallet.svg";
import Button from "./button/Button";
import CloseIcon from '../assets/images/svg-icons/close.svg';
import toast from "react-hot-toast";
import { useWeb3React } from "@web3-react/core"
import { injectedMetamask, setProvider } from "../const/metamaskWalletConnection"; 
import Web3 from "web3";
import { chaindata } from "../const/config";
import { injectedcelo, Send, SendReturn, SendReturnResult } from "../const/celloWalletConnection";
import near from "../assets/images/near.png";
import { useWallet } from "../services/providers/MintbaseWalletContext";
import { Console } from "console";

interface props {
  setIsOpen: (active: boolean) => void;
}

const ConnectWallet = ({ setIsOpen }: props) => {
  const web3 = new Web3(Web3.givenProvider || process.env.REACT_APP_CELO_RPC_URL);

  const { active, activate } = useWeb3React();

  const { wallet, isConnected, details } = useWallet()

  const connectMedamask = async () => {
    await activate(injectedMetamask);
    try {
      const chainId = await web3.eth.getChainId();

      if (chainId !== parseInt(process.env.REACT_APP_CELO_CHAIN_ID as any)) {
        await window.ethereum.request(chaindata);
        await activate(injectedMetamask);
        setIsOpen(false);
      }

      if (chainId === parseInt(process.env.REACT_APP_CELO_CHAIN_ID as any)) {
        await activate(injectedMetamask);
        setIsOpen(false);
      }

    } catch (err: any) {
      if (err.code === 4902) {
        await window.ethereum.request(chaindata);
      } 
       
    }
  }
  

  const connectCelo = async () => {

    function parseSendReturn(sendReturn: SendReturnResult | SendReturn): any {
      return sendReturn.hasOwnProperty('result') ? sendReturn.result : sendReturn
    }    

    try {
      var chainId = await (window.celo.send as Send)('net_version').then(parseSendReturn);        

      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CELO_CHAIN_ID as any)) {   
        toast.error("Please switch to CELO network. Otherwise, you can't login into site"); 
      }

      if (parseInt(chainId) === parseInt(process.env.REACT_APP_CELO_CHAIN_ID as any)) {
        await activate(injectedcelo);
        setProvider("injectedcelo");
        setIsOpen(false);
      }

    } catch (err: any) { 
    }  
   
  }

  useEffect(() => {
    if (active) { 
      toast.success('Login Successfully.');
    }
  }, [active])   

  return (
    <div className="wallet-group">
      <div>
        <div className="close-modal">
          <img src={CloseIcon} alt="CloseIcon" onClick={() => { setIsOpen(false) }} />
        </div>
        <h5>
          You’ll need a wallet on
          <br />
          CELO Coins to Continue{" "}
        </h5>
        <div className="text-center pb-4 mb-2">
          <img src={celologo} alt="celologo" />

        </div>
        <div className="text-center pb-4 mb-2">
          <Button name={'Get Started'} classname="primarybtn" icon={false} click={()=>{
            if (!window.celo) {
              toast.error('Please install Celo extension');
            }
            connectCelo();
            }} />
        </div>
        <div className="wallet-option">
          <p>Already have a wallet? <a href="/">View Options</a> </p>
        </div>
      </div>
      <div className="connect-wallet">
        <button onClick={() => {
          if (!window.ethereum) {
            toast.error('Please install MetaMask extension');
          } else {
            connectMedamask();
            setProvider("injectedmetamask");
          }
        }}>
          <div className="d-flex align-items-center justify-content-center">
            <img src={Walletlogo} alt="walletlogo" width={45} />
            <h6>Metamask</h6>
          </div>
        </button>
        <button onClick={() => {
           wallet?.connect({ requestSignIn: true });
          
        }}>
          <div className="d-flex align-items-center justify-content-center"> 
            <img src={near} alt="walletlogo" width={45} />
            <h6>Near</h6>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ConnectWallet;
