import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/Loader/Spinner";
import SocialShare from "../../components/share/SocialShare";
import { useTokenListData } from "../../hooks/useTokenListData";
import { v2MarketPlaceGetMetadata } from "../../queries/marketplace.queries";
import AboutEvent from "./components/aboutEvent";
import Closeicon from "../../assets/images/svg-icons/close.svg";
import Ticketdetailcont from "../../context/ticketDetailcont";
import Button from "../../components/button/Button";
import { useWallet } from "../../services/providers/MintbaseWalletContext";
import { nearToYocto } from "../../lib/numbers";
import { TransactionEnum } from "../../types/types";
import { MED_GAS } from "../../const/config";
import EventActivites from "./components/eventActivites";
import QRCode from "react-qr-code";

const TicketDetailsNear = () => {
  const nftId = useParams();
  const id = nftId.id?.toString(); 
  const { metadataId }: any = { metadataId: id };
  const [ticketData, setTicketData] = useState(null);
  const [ticketInfo, setTicketInfo] = useState(null);
  const [des, setDes] = useState(null);
  const [eventName, setEventName] = useState(null)
  const [ticketImg, setTicketImag] = useState(null);
  let navigate = useNavigate();
  const [num, setNum] = useState(1);
  const { wallet, isConnected, details } = useWallet();
  const {
    price,
    prices,
    amountAvailable,
    tokensTotal,
    tokenId,
    tokenList,
    tokenData,
    tokenKey,
    marketId,
    isTokenListLoading,
    nftContractId,
  } = useTokenListData({ metadataId });
  const [tokencount, setTokenCount] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(price); 
  

  useQuery(v2MarketPlaceGetMetadata, {
    variables: { metadataId },
    onCompleted: (data) => {
      console.log(data)
      setEventName(data.metadata[0].contract.id)
      setTicketData(data.metadata[0].title);
      setDes(data.metadata[0].description);
      setTicketImag(data.metadata[0].media);
      setTokenCount(data.tokenCount.aggregate.count);
    },
    onError: () => { setTicketData(null) },
  });

  const increment = () => {
    if (amountAvailable) {

      if (num < amountAvailable) {
        setNum(Number(num) + 1);
      }
    }
  };
  const decrement = () => {
    if (num > 1) {
      setNum(num - 1);
    }
  };

  const handleChange = (e: any) => {
    setNum(e.target.value);
  };

  const singleBuy = useCallback(async (price: any) => {

    const txns = [
      {
        receiverId: marketId,
        functionCalls: [
          {
            methodName: 'buy',
            receiverId: marketId,
            gas: MED_GAS,
            args: {
              nft_contract_id: nftContractId,
              token_id: tokenId,
              referrer_id: process.env.REACT_APP_PUBLIC_REFERRAL_ID,
            },
            deposit: nearToYocto(price.toString()),
          },
        ],
      },
    ];

    await wallet?.executeMultipleTransactions({
      transactions: txns as never,
      options: {
        callbackUrl: `${window.location.origin}/events`,
        meta: JSON.stringify({
          type: 'make-offer',
          args: {
            tokenId,
            price: nearToYocto(price.toString()),
          },
        }),

      },


    });
  }, [price, tokenKey, wallet]);

  const multiBuy = useCallback(async (tokenKey: any) => {
    const nftPrice = nearToYocto(price?.toString());
    const finalPrice = new Array(num);

    finalPrice.fill(nftPrice);

    wallet?.batchMakeOffer([tokenKey], finalPrice, {
      gas: MED_GAS,
      callbackUrl: `${window.location.origin}/wallet-callback`,
      meta: JSON.stringify({
        type: TransactionEnum.MAKE_OFFER,
        args: {
          tokenId: tokenKey,
          price: nearToYocto(price?.toString()),
        },

      }),
    });
  }, [num, currentPrice, price, tokenKey, wallet]);

  const BuyNftButton = () => {

    if (isConnected) {
      return (
        <div className="appbutton">
          <Button
            name="Buy Now"
            icon={false}
            classname="primarybtn"
            click={() => {
              if (num <= 1) {
                singleBuy(price);
              } else {
                multiBuy(tokenKey);
              }
            }}
          />
        </div>)
    } else {
      return (
        <div className="appbutton">
          <Button
            name="Connect Wallet"
            icon={false}
            classname="primarybtn"
            click={''}
          />
        </div>)
    }
  }


  const AvailableTicketsSection = () => {

    if (amountAvailable === 0) {
      return (
        <>
          <p className="sold-out">
            Sold Out!
          </p>
        </>
      )
    }

    return (
      <>
        <div className="quantity-group">
          <div>
            <div className="input-group">
              <div className="input-group-dec">
                <button
                  className="counter-btn"
                  type="button"
                  onClick={decrement}
                >
                  -
                </button>
              </div>
              <input
                type="text"
                className="form-control bg-white"
                value={num}
                disabled={true}
                onChange={handleChange}
              />
              <div className="input-group-inc">
                <button
                  className="counter-btn"
                  type="button"
                  onClick={increment}
                >
                  +
                </button>
              </div>
            </div>

          </div>
        </div>
        <>
          <BuyNftButton />
        </>
      </>
    )
  }

 

  return (
    <div className="page-spacing ticket-details near">
      <Helmet>
        <title>Ticket Details | Nftickt</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="ticket-card">
              {ticketImg != null ?
                <img src={ticketImg} alt="Ticket-img" />
                : <Spinner />}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ticket-title">
              {ticketData != null ?
                <h3>{ticketData}</h3>
                : <div className="mb-3"><Skeleton width={"200px"} height={"25px"} /></div>}
              <div className="ticket-shate"> <SocialShare eventTitle={ticketData} /></div>
              <div className="close-icon" onClick={() => {
                navigate(`/event-details/${eventName}`)
              }}>
                <img src={Closeicon} alt="close" />
              </div>
            </div>
            {price == 0 && window.location.pathname == `/user-nft/${id}` ? <div className="price-card text-center">
              
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "250", width: "100%" }}
                value={`Event Name: ${eventName}, weruiogweuweg`}
                viewBox={`0 0 256 256`}
              />

            </div>:
            <div className="price-card">
              <h6>{Ticketdetailcont.price}</h6>
              <h2> {price != null ? price : <Skeleton width={"150px"} height={"35px"} />}Near</h2>
              {amountAvailable ?
                <div className="ticket-detail">
                  <div className="tickets-sold">
                    <span>{Ticketdetailcont.available}</span>
                    <p>{amountAvailable}</p>
                  </div>
                  <div className="tickets-sold">
                    <span>{Ticketdetailcont.soldticket}</span>
                    <p>{tokensTotal ? tokensTotal - amountAvailable > 0 ? tokensTotal - amountAvailable : 0 : ""}</p>
                  </div>
                </div> : ""}
              <div className="ticket-btn">
                <div className="mt-5">
                  {window.location.pathname == `/user-nft/${id}` ? "" :
                    <BuyNftButton />
                  }
                </div>
              </div>
            </div>}
            <AboutEvent content={des} />
          </div>
        </div>
        <div className="row mt-4">
          <EventActivites tokenId={tokenId} nftContractId={nftContractId} />
        </div>
      </div>

    </div>

  );
};

export default TicketDetailsNear;
