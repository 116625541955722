import nftTicketBaseApi from "../baseApi/nftTicketBaseApi"
import { FETCH_NFT_TICKETS_BY_NFT_EVENT_ID, FETCH_NFT_TICKETS_FAIL, FETCH_NFT_TICKETS_NETWORK_ERROR, FETCH_NFT_TICKETS_START, FETCH_NFT_TICKETS_SUCCESS } from "./actionTypes";

export const fetchNftTicketsByEventId = (eventId: any) => async(dispatch: any) => {

    try {
        dispatch(fetchNftTicketsStart())
        const response = await  nftTicketBaseApi.post(`/nft-ticket/getById/0/10/${eventId}`);
    
        dispatch({
            type: FETCH_NFT_TICKETS_BY_NFT_EVENT_ID,
            payload: response.data
        })

        dispatch(fetchNftTicketsSuccess())

    } catch (err) {
        dispatch(fetchNftTicketsStart())

        dispatch(fetchNftTicketsNetworkError(err))

        dispatch(fetchNftTicketsFail())

    }

}


export const fetchNftTicketsNetworkError = (error: any) => async (dispatch: any) => {

    dispatch({
        type: FETCH_NFT_TICKETS_NETWORK_ERROR,
        fetchNftTicketsNetworkError: error
    })
}

const fetchNftTicketsStart = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_TICKETS_START,
        nftEventLoader: true
    })
}

const fetchNftTicketsSuccess = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_TICKETS_SUCCESS,
        nftEventLoader: false
    })
}

const fetchNftTicketsFail = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_NFT_TICKETS_FAIL,
        nftEventLoader: false
    })
}
