import toast from "react-hot-toast";
import nftTicketBaseApi, { store } from "../baseApi/nftTicketBaseApi"
import { CREATE_TICKET_TYPE, CREATE_TICKET_TYPE_ERROR, CREATE_TICKET_TYPE_FAIL, CREATE_TICKET_TYPE_START, CREATE_TICKET_TYPE_SUCCESS } from "./actionTypes";

import { actionCreators } from "./eventDataActions";

export const createTicketType = () => async (dispatch: any) => {
    const event = store.getState().eventData;
    const ticketData = store.getState().eventData.eventTicketData;
    try {
        dispatch(fetchCreateTicketTypeStart());
        let body = {}
        body = {
            nftTickets: event.eventTicketType,
        }

        const response = await nftTicketBaseApi.post(`nft-ticket/create-type`, body)

        if (response.status === 201 || response.status === 200) {
            toast.success(response.data.message);
            const data = response.data.nftTicket;
            actionCreators.eventTicketType(response.data.nftTicket);

            if (ticketData != null) {
                const createData = data.map((ticketType: any) => {
                    const matchTicketData = ticketData.filter((ticketData: any) => {
                        if (ticketType._id === ticketData._id) {
                            return ticketData;
                        }
                        return null;
                    });
                    if (matchTicketData.length === 0) {
                        return {
                            eventId: ticketType.eventId,
                            _id: ticketType._id,
                            name: ticketType.name,
                            nftImage: "",
                            passImage: "",
                            ticketPerHead: "",
                            description: "",
                        }
                    } else {
                        matchTicketData[0].name = ticketType.name;
                        return matchTicketData[0]
                    }
                })
                actionCreators.eventTicketData(createData);
            } else {
                const createData = data.map((ticket: any) => ({
                    eventId: ticket.eventId,
                    _id: ticket._id,
                    name: ticket.name,
                    nftImage: "",
                    passImage: "",
                    ticketPerHead: "",
                    description: "",
                }))
                actionCreators.eventTicketData(createData)
            }

            actionCreators.eventTicketStep(3);
        }

        dispatch({
            type: CREATE_TICKET_TYPE,
            payload: response.data
        })
        dispatch(fetchCreateTicketTypeSuccess())

    } catch (err: any) {
        toast.error(err.response.data.message);
        dispatch(fetchCreateTicketTypeStart())

        dispatch(fetchCreateTicketTypeError(err))

        dispatch(fetchCreateTicketTypeFail())

    }

}

export const fetchCreateTicketTypeError = (error: any) => async (dispatch: any) => {

    dispatch({
        type: CREATE_TICKET_TYPE_ERROR,
        fetchNftTicketsNetworkError: error
    })
}

const fetchCreateTicketTypeStart = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_TYPE_START,
        nftEventLoader: true
    })
}

const fetchCreateTicketTypeSuccess = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_TYPE_SUCCESS,
        nftEventLoader: false
    })
}

const fetchCreateTicketTypeFail = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_TYPE_FAIL,
        nftEventLoader: false
    })
}