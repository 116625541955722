import React, { useState } from "react";
import Button from "../../../components/button/Button"; 
import Line from "../../../components/Line";
import { newsLatter } from "../../../services/newslatter";

function SignUp() {
    const [mail, setEmail]= useState('');
   
 
  return (
        <div className="section signup">
            <div className="container">
                <h1>Signup here</h1>
                <Line></Line>
                <p className="max-667">Do you want to sell NFT tickets at your next event? Do you want to buy tickets and earn big? Choose NFTickt, where we are redefining ticketing!  
</p>
                    <div className="input-filed">
                        <input type="email" placeholder="Enter your email id here" value={mail} onChange={(e:any)=>{setEmail(e.target.value)}}/> 
                        <Button name={'Get Started'} click={()=>{newsLatter(mail,setEmail)}}  icon={true} classname={"primarybtn"}/>                        
                    </div>
            </div>
        </div>
    );
}

export default SignUp;