import { useQuery } from '@apollo/client';
import { useWallet } from "../services/providers/MintbaseWalletContext";
import { useState } from 'react'; 
import { EventTicketList } from '../queries/marketplace.queries';
import { Store } from '../types/types';

export const useEventAllNft = (store:any) => {
  const [nftList, setStores] = useState<Store[]>([]);
  const { wallet } = useWallet();
  
  const { loading } = useQuery(EventTicketList, {
    variables: {
      id: store,
      account: wallet?.activeAccount?.accountId.toString(),
    }, 
    onCompleted: (data) => {  
      const storesData = data?.store;
      setStores(storesData);
    },
  });

  return { nftList, loading };
};

export default useEventAllNft;
