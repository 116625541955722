
import { useQuery } from '@apollo/client';
import { useState } from 'react'; 
import { StoreDetails } from '../queries/marketplace.queries';
import { Store } from '../types/types';

export const useStoresDetails = (id:any) => {
  const [storesDetails, setStores] = useState<Store[]>([]);
 
 
  const { loading } = useQuery(StoreDetails, {
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      const storesData = data?.store[0]; 
      setStores(storesData);
    },
  });

  return { storesDetails, loading };
};

export default useStoresDetails;
