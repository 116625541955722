import React from "react";
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button";
import NotfountError from "../../assets/images/not-found.png";
import { useNavigate} from "react-router-dom";
const NotFound = () => {
    let navigate = useNavigate(); 
    const homeRouter = () =>{ 
        let path = `/`; 
        navigate(path);
      }
  return (
    <>
      <Helmet>
        <title>404 | This page could not be found</title>
      </Helmet>
      <div className="found-styles">
        <div className="myprofile">
          <div className="container">
            <div className="text-center">
              <div>
                <img
                  src={NotfountError}
                  alt="NotfountError"
                  className="error-img"
                />
                <h2>Page Not Found</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit
                  maecenas
                  <br />
                  tristique varius, a nisi vulputate taciti fames erat montes .
                </p>
                <Button
                  name="Back to home"
                  click={() => {homeRouter()}}
                  icon={false}
                  classname={"primarybtn"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
