import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import smticket from '../../assets/images/svg/icons/ticketsmallicon.svg';
import imagePlaceholder from '../../assets/images/ticketplaceholder.png';
import { parseYactoToNear } from "../../lib/numbers";

type propsTypeDefinition = {
  nftTicket?: {
    approval_id: number,
    base_uri: string,
  content_flag: null,
  created_at: string,
  currency: string,
  description: string,
  extra: object,
  kind: string,
  listed_by: string,
  market_id: string,
  media: string,
  metadataId: string,
  minter: string,
  nft_contract_id: string,
  price: number,
  receipt_id: string,
  reference: string,
  title: string,
  token_id: string,
  __typename: string,
  
}
}

const TicketCard = (props: propsTypeDefinition) => {

  const [path, setPath] = useState("nft-details")
  useEffect(()=>{
    if(window.location.pathname == `/user-event/${props?.nftTicket?.nft_contract_id}`){
      setPath("user-nft")
    }else{
      setPath("nft-details")
    }
  },[window.location.pathname])
 
   return (
    <>
     <Link to={`/${path}/${props?.nftTicket?.metadataId}`} >
       <div className="ticketcard"  key={props?.nftTicket?.title}>
         <div className="ticketicon">
           <div className="avalable-ticket">
             <div className="ticket-sm-icon">
               <img src={smticket} alt="" />
             </div> 
           </div>          
           {!props?.nftTicket?.media ?  <img src={imagePlaceholder} alt="Ticket" /> : 
           <img src={props?.nftTicket?.media} alt="Ticket" />
           }
         </div>
         <div className="details">
          
           {props?.nftTicket?.title != null ? (
             <h6>{props?.nftTicket?.title}</h6>
           ) : (
             <div className="mb-3">
               <Skeleton width={"200px"} height={"25px"} />
             </div>
           )}  
         </div>
       </div>
     </Link>

     
     </>
   );
};

export default TicketCard;
