import React, { useState } from "react";
import NftCard from "../../../components/cards/NftCard";
import EventCardSkeleton from "../../../components/skeleton/EventCardSkeleton";
import { pageSize } from "../../../const/config";
import useStores from "../../../hooks/useStores";

const NearList = () => {
    const { stores, loading } = useStores();

    return (
        <>
            <div className="event-spacing">
                <div className="pt-4 mt-2">
                    {loading === true ?
                        <div className="event-group">
                            {[...Array(pageSize)].map(() => { return <EventCardSkeleton />; })}
                        </div> : <div className="event-group">
                            {stores.map((list: any, index: any) => {
                                return (<NftCard nftList={stores[index]} />);
                            })}
                        </div>}
                </div>
            </div>
        </>
    )
}

export default NearList;