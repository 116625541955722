import nftTicketBaseApi from "../baseApi/nftTicketBaseApi"
import {
    FETCH_RECENT_TICKET,
    FETCH_RECENT_ERROR,
    FETCH_RECENT_START,
    FETCH_RECENT_SUCCESS,
    FETCH_RECENT_FAIL
} from "./actionTypes";
 
export const fetchRecentList = (event:any, id: any) => async(dispatch: any) => {

    try {
        dispatch(fetchRecentListStart());
       let body ={}
       body = {
        ticketId: id,
       }
        const response = await nftTicketBaseApi.post(`nft-ticket/getById/0/0/${event}`, body) 
  
        dispatch({
            type: FETCH_RECENT_TICKET,
            payload: response.data
        }) 
        dispatch(fetchRecentListSuccess())

    } catch (err) {
        dispatch(fetchRecentListStart())

        dispatch(fetchRecentListError(err))

        dispatch(fetchRecentListFail())

    }

}

export const fetchRecentListError = (error: any) => async (dispatch: any) => {

    dispatch({
        type: FETCH_RECENT_ERROR,
        fetchNftTicketsNetworkError: error
    })
}

const fetchRecentListStart = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_RECENT_START,
        nftEventLoader: true
    })
}

const fetchRecentListSuccess = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_RECENT_SUCCESS,
        nftEventLoader: false
    })
}

const fetchRecentListFail = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_RECENT_FAIL,
        nftEventLoader: false
    })
}