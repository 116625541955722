import { useQuery } from '@apollo/client';
import { useState } from 'react'; 
import { UserEventList } from '../queries/marketplace.queries';
import { useWallet } from '../services/providers/MintbaseWalletContext';
import { Store } from '../types/types';

export const useUserStores = () => {
  const [stores, setStores] = useState<Store[]>([]);
  const { wallet, isConnected, details } = useWallet() 
  const formatedselectedStores = wallet?.activeAccount?.accountId;
  const symbol = process.env.REACT_APP_NEAR_STORE_SYMBOL;

  const { loading } = useQuery(UserEventList, {
    variables: {
      user: formatedselectedStores,
      symbol: symbol,
    },
    onCompleted: (data) => {
      const storesData = data?.store; 
      setStores(storesData);
    },
  });

  return { stores, loading };
};

export default useUserStores;
