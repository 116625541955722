import {FETCH_UPCOMING_EVENT_ID,FETCH_UPCOMING_EVENT_ERROR,FETCH_UPCOMING_EVENT_START,FETCH_UPCOMING_EVENT_SUCCESS,FETCH_UPCOMING_EVENT_FAIL} from './actionTypes';

import nftTicketBaseApi from "../baseApi/nftTicketBaseApi";


export const fetchUpcomingEvent =(page:string, pagesize:string) => async(dispatch:any) =>{
    try{
    dispatch(fetchUpComingEventStart())
    const response = await nftTicketBaseApi.get(`/nft-event/get/upcoming-events/${page}/${pagesize}`);

    dispatch({
        type: FETCH_UPCOMING_EVENT_ID,
        payload: response.data
    })

    
    dispatch(fetchUpComingEventSuccess())


} catch (error: any) {
    const message = 
        error?.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        
    dispatch(fetchUpComingEventError(message))

    dispatch(fetchUpComingEventFail())

}
}


const fetchUpComingEventError = (error: any) => async (dispatch: any) => {
    dispatch({
        type: FETCH_UPCOMING_EVENT_ERROR,
        fetchNftNetworkErrorFromServer: error
    })
}

const fetchUpComingEventStart = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_UPCOMING_EVENT_START,
        nftEventLoader: true
    })
}

const fetchUpComingEventSuccess = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_UPCOMING_EVENT_SUCCESS,
        nftEventLoader: false
    })
}

const fetchUpComingEventFail = () => async (dispatch: any) => {
    dispatch({
        type: FETCH_UPCOMING_EVENT_FAIL,
        nftEventLoader: false
    })
}