/*

useStores Hook
Description: Hook to get the stores set on the NEXT_PUBLIC_STORES env variable

*/

import { useQuery } from '@apollo/client';
import { useState } from 'react'; 
import { NftToken } from '../queries/marketplace.queries';
import { Store } from '../types/types';

export const useNftTokenDetails = (id:any) => {
  const [nftList, setStores] = useState<any>(null);
  

  const { loading } = useQuery(NftToken, {
    variables: {
      id: id      
    },
    onCompleted: (data) => {  
      const storesData = data?.store.nodes;
      setStores(storesData);
    },
  });

  return { nftList, loading };
};

export default useNftTokenDetails;
