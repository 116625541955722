import {LIVE_EVENT_DATA, LIVE_EVENT_FAIL, LIVE_EVENT_ERROR} from './actionTypes';

import nftTicketBaseApi from "../baseApi/nftTicketBaseApi";

export const getLiveEvent=() => async(dispatch:any) => {
    try{ 
        dispatch(setSelectedLiveNftEvent)
        const response = await nftTicketBaseApi.get(`nft-event/live-events`);
        dispatch({
            type:LIVE_EVENT_DATA,
            payload:response.data
        });
       
    }catch (error: any) {
        const message = 
        error?.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        
            dispatch(getLiveEventError(message))

            dispatch(getLiveEventFail())

    }

}
const setSelectedLiveNftEvent = (selectedliveEventData: string) => async (dispatch: any) => {

    dispatch({
        type: LIVE_EVENT_DATA,
        setSelectedNftEventId: selectedliveEventData
    })

}
const getLiveEventError = (error: any) => async (dispatch: any) => {
    dispatch({
        type: LIVE_EVENT_ERROR,
        fetchNftNetworkErrorFromServer: error
    })
}
const getLiveEventFail = () => async (dispatch: any) => {
    dispatch({
        type: LIVE_EVENT_FAIL,
        nftEventLoader: false
    })
}