import Web3 from "web3";
import { newKitFromWeb3 } from "@celo/contractkit";
import { abi } from "../ABI/TicketManager";

/// DEPLOYED CONTRACT ADDRESS /////
const contractAddress = "0x51b41DF57EC27433638B20F5ab7c891dd1CC8622"
const ABI = abi;


//// CELO INSTANCE ///////
const celoChainId = "44787"
// const chainId = "42220"

/// RPC Provider for celo
const celoRpcProvider = "https://alfajores-forno.celo-testnet.org"
// const rpcProvider = "https://forno.celo.org"

const celoWeb3 = new Web3( window.celo || celoRpcProvider )
const celoKit = newKitFromWeb3(celoWeb3);
const TicketManagerCelo = new celoKit.web3.eth.Contract(
    ABI,  celoChainId && contractAddress
    )    

//// CELO KIT INSTANCE ////////
const kit = new Web3(celoRpcProvider);



//// METAMASK INSTANCE CREATION INITIATION ////////
// let metamaskWeb3;

window.addEventListener('load', async () => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.

    const currentInjectedProvider = localStorage.getItem('provider');

    if (currentInjectedProvider === "injectedmetamask") {
     if (window.ethereum) {
       const web3 = new Web3(window.ethereum);
       try {
         // Request account access if needed
         await window.ethereum.enable();
         // Accounts now exposed
         return web3;
       } catch (error) {}
     }
     // Legacy dapp browsers...
     else if (window.web3) {
       // Use MetaMask/Mist's provider.
       const web3 = window.web3;
    //    console.log('Injected web3 detected.');
       return web3;
     }
     // Fallback to localhost; use dev console port by default...
     else {
       const provider = new Web3.providers.HttpProvider('http://127.0.0.1:9545');
       const web3 = new Web3(provider);
    //    console.log('No web3 instance injected, using Local web3.');
       return web3;
     }
    }
   });

//// METAMASK INSTANCE /////
const metamaskWeb3 = new Web3(window.ethereum)

const TicketManagerMetamask = new metamaskWeb3.eth.Contract(ABI, contractAddress)

export { TicketManagerCelo, TicketManagerMetamask, kit }