import {  BUY_NFT_TICKET_TRANSACTION, NFT_TICKET_TRANSACTION_FAIL, NFT_TICKET_TRANSACTION_START, NFT_TICKET_TRANSACTION_SUCCESS } from "../actions/actionTypes";


const INITIAL_STATE = {
    buyNftTicketTransactions: null,
    buyNftTicketLoader: false
}

interface state {
    buyNftTicketTransactions: any,
    buyNftTicketLoader: any
}


const buyNftTicketTransactionsHandler = (state: state, action: any) => {
    return {
        ...state,
        buyNftTicketTransactions: action.payload?.orderedTransaction
    }
}

const nftTicketTransactionStartHandler = (state: state, action: any) => {
    return {
        ...state,
        buyNftTicketLoader: action.buyNftTicketLoader
    }
}

const nftTicketTransactionSuccessHandler = (state: state, action: any) => {
    return {
        ...state,
        buyNftTicketLoader: action.buyNftTicketLoader
    }
}

const nftTicketTransactionFailHandler = (state: state, action: any) => {
    return {
        ...state,
        buyNftTicketLoader: action.buyNftTicketLoader
    }
}

const nftTicketTransactionsReducer = (state: state = INITIAL_STATE, action: any) => {
    switch(action.type) {
        default:
            return state;

        case BUY_NFT_TICKET_TRANSACTION:
            return buyNftTicketTransactionsHandler(state, action);

        case NFT_TICKET_TRANSACTION_START:
            return nftTicketTransactionStartHandler(state, action);

        case NFT_TICKET_TRANSACTION_SUCCESS:
            return nftTicketTransactionSuccessHandler(state, action);

        case NFT_TICKET_TRANSACTION_FAIL:
            return nftTicketTransactionFailHandler(state, action)
    }
}

export default nftTicketTransactionsReducer;