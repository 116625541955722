import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NftEvent from "../../containerComponents/nftEvent/NftEvent";
import NftTickets from "../../containerComponents/nftTickets/NftTickets";  


function EventDetails() {
  const [gridview, setGridView] = useState(false);
  const [storeData, setStoreData] = useState<any>();
  const dispatch = useDispatch();
  let { id } = useParams();  
  const url = process.env.REACT_APP_PUBLIC_STORE_DATA_API;
  const data = async () =>{ 
     await axios.get(`${url}/${id}`).then((res)=>{
      console.log(res)
       setStoreData(res.data)
    });
  }

  useEffect(()=>{
    data(); 
  },[])
  
  return (
    <div className="eventdetails">
      <Helmet>
        <title>Event Details | Nftickt</title>
      </Helmet>
      <div className="banner">
        {storeData == null ? <>
          <div className="detail-banner" >
          </div>
        </> :
          <div className="detail-banner" style={{
            backgroundImage: `url(${storeData?.headerImage})`,
          }}>
          </div>
        }
      </div>
      <div className="container">
        <NftEvent />
        <NftTickets gridview={gridview} setGridView={setGridView} />
      </div>
    </div>
  );
}

export default EventDetails;