import { FETCH_NFT_TICKETS_BY_NFT_EVENT_ID, FETCH_NFT_TICKETS_FAIL, FETCH_NFT_TICKETS_NETWORK_ERROR, FETCH_NFT_TICKETS_START, FETCH_NFT_TICKETS_SUCCESS } from "../actions/actionTypes";

/////// INTERFACES ////////
interface state {
    nftTicketsData: any,
    nftTicketsByEventIdData: any,
    fetchNftTicketsNetworkErrorFromServer: any,
    nftTicketsLoader: boolean
}

const INITIAL_STATE = {
    nftTicketsData: null,
    nftTicketsByEventIdData: null,
    fetchNftTicketsNetworkErrorFromServer: null,
    nftTicketsLoader: false
}

const fetchNftTicketsByEventIdHandler = (state: state, action: any) => {
    return {
        ...state,
        nftTicketsByEventIdData: action.payload
    }
}

const fetchNftTicketsNetworkErrorHandler = (state: state, action: any) => {
    return {
        ...state,
        fetchNftTicketsNetworkErrorFromServer: action.fetchNftTicketsNetworkError
    }
}

const fetchNftTicketsStartHandler = (state: state, action: any) => {
    return {
        ...state,
        nftTicketsLoader: true
    }
}

const fetchNftTicketsSuccessHandler = (state: state, action: any) => {
    return {
        ...state,
        nftTicketsLoader: false
    }
}

const fetchNftTicketsFailHandler = (state: state, action: any) => {
    return {
        ...state,
        nftTicketsLoader: false
    }
}

const nftTicketsReducer = (state: state = INITIAL_STATE, action: any) => {
    switch(action.type) {
        default:
            return state;
        
        case FETCH_NFT_TICKETS_BY_NFT_EVENT_ID:
            return fetchNftTicketsByEventIdHandler(state, action);

        case FETCH_NFT_TICKETS_NETWORK_ERROR:
            return fetchNftTicketsNetworkErrorHandler(state, action);

        case FETCH_NFT_TICKETS_START:
            return fetchNftTicketsStartHandler(state, action);

        case FETCH_NFT_TICKETS_SUCCESS:
            return fetchNftTicketsSuccessHandler(state, action);

        case FETCH_NFT_TICKETS_FAIL:
            return fetchNftTicketsFailHandler(state, action);
            
    }
}

export default nftTicketsReducer;