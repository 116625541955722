import { useQuery } from '@apollo/client';
import { useState } from 'react'; 
import { NftActivities } from '../queries/marketplace.queries';
import { Store } from '../types/types';

export const useNftActivities = (storeData:any) => {
  const [activit, setActivit] = useState<Store[]>([]);   

  const { loading } = useQuery(NftActivities, {
    variables: { 
      id: storeData.id.tokenId, 
      store: storeData.id.nftContractId
    },
    onCompleted: (data:any) => {  
      const storesData = data?.store; 
      setActivit(storesData);
    },
  });

  

  return { activit, loading };
};

export default useNftActivities;
