import React, { useEffect, useState } from "react";
import ticketicon from "../../../assets/images/svg/icons/ticketicon.svg";
import owner from "../../../assets/images/events/eventlogo.png";
import clockstart from "../../../assets/images/svg/icons/clockstart.svg";
import clockend from "../../../assets/images/svg/icons/clockend.svg";
import locationicon from "../../../assets/images/svg/icons/location.svg";
import SocialIcons from "../../../components/SocialIcons";
import eventdetails from "../../../context/eventdetails";
import IconList from "../../../components/IconList";
import SocialShare from "../../../components/share/SocialShare";
import Skeleton from "react-loading-skeleton";
import twitter from "../../../assets/images/svg-icons/twitter.svg";
import Externallink from "../../../assets/images/svg-icons/external-link.svg";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useStores from "../../../hooks/useStores";
import axios from "axios";
import useStoresDetails from "../../../hooks/useStoresDetails";

  const EventHeader = (props: any) => {
  let { id } = useParams(); 
  const [storeData, setStoreData] = useState<any>();
  const url = process.env.REACT_APP_PUBLIC_STORE_DATA_API;
  const data = async () => {
    await axios.get(`${url}/${id}`).then((res) => {
      setStoreData(res.data)
    });
  }

 
  
  useEffect(() => {
    data();
  }, [])

  console.log(storeData)
  
  return (
    <>
      <div className="header-section">
        <div className="logo">
          {!storeData?.profileImage ? ( 
            <img src={owner} alt="event logo" srcSet="" />
          ) : (
            <img src={storeData?.profileImage} alt="event logo" srcSet="" />
          )}
        </div>
        <div className="event-bio">
          <div className="w-100">
            <div className="content">
              <div>
                <div className="detail-header">
                  <h6 className="verfied">
                    {props.data.name || (
                      <Skeleton width={"200px"} height={"25px"} />
                    )}
                  </h6>
                  <SocialShare
                    eventTitle={id}
                    eventDesc={storeData && storeData.description? storeData.description :''}
                  />
                </div>
                <p>
                  Created by
                  <span className="ms-2">
                    {props.data.owner || (
                      <Skeleton width={"200px"} height={"15px"} />
                    )}{" "}
                  </span>
                </p>
              </div>

              <SocialIcons
                facebookLink={storeData?.facebook}
                twitterLink={storeData?.twitter}
                telegramLink={storeData?.instagram}
                discordLink={storeData?.communityUrl}
              />
            </div> 
          </div>
        </div>
      </div> 
      <div className="event-location"> 
      <p>{storeData && storeData.description? storeData.description :''}</p>
        {storeData && storeData?.websiteUrl ? (
          <div className="d-flex align-items-center">
            <div> 
              <img src={Externallink} alt="location" />
            </div>
            <div className="ps-3">
              <a
                href={storeData?.websiteUrl}
                target="_blank"
                rel="noreferrer"
                className="abt-event"
              >
                Know more about this event
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default EventHeader;
