
import React from "react";

function Switch({id, value, setvalue}:any) {
  return (
    <div className="switch-style">
      <input type="checkbox" id={id} checked={value} onChange={(e)=>{setvalue(e.target.checked)}}/>
      <label htmlFor={id}>Toggle</label>
    </div>
  );
}

export default Switch;
