import {FETCH_NFT_MY_EVENT_ID,FETCH_NFT_MY_EVENT_ERROR,FETCH_NFT_MY_EVENT_START,FETCH_NFT_MY_EVENT_SUCCESS, FETCH_NFT_MY_EVENT_FAIL} from '../actions/actionTypes';

interface state {
    myEventData:any,
    selectMyEventId:string | null,
    netWorkErrorFromServer:any,
    nftMyEventLoader: boolean,
}

const INITIAL_STATE = {
    myEventData: null,
    selectMyEventId:null,
    netWorkErrorFromServer:null,
    nftMyEventLoader: false,
}

const fetchMyEventByIdHandler = (state: state, action: any) => {
    return {
        ...state,
        nftEventData: action.payload
    }
}
const fetchMyEventError = (state: state, action: any) => {
    return {
        ...state,
        nftMyEventLoader: false
    }
}
const fetchMyEventStart = (state: state, action: any) => {
    return {
        ...state,
        nftMyEventLoader: true
    }
}
const fetchMyEventSuccess = (state: state, action: any) => {
    return {
        ...state,
        nftMyEventLoader: false
    }
}
const fetchMyEventFail = (state: state, action: any) => {
    return {
        ...state,
        nftMyEventLoader: false
    }
}

const myEventReducer =(state:state = INITIAL_STATE, action:any) =>{
    switch(action.type){
        default:
            return state;
            case FETCH_NFT_MY_EVENT_ID:
                return fetchMyEventByIdHandler(state, action);
            case FETCH_NFT_MY_EVENT_ERROR:
                return fetchMyEventError(state, action);
            case FETCH_NFT_MY_EVENT_START:
                return fetchMyEventStart(state, action);
            case FETCH_NFT_MY_EVENT_SUCCESS:
                return fetchMyEventSuccess(state, action);
            case FETCH_NFT_MY_EVENT_FAIL:
                return fetchMyEventFail(state, action);

    }
}

export default myEventReducer;