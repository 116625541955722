import React from "react"; 
import SelectSearch, { fuzzySearch } from "react-select-search";

interface propse {
  list:any,
  placeholder:string,  
  setDropValue:any,
  setvalue:any,
}

function DropDown({list,placeholder,setvalue, setDropValue}:propse) {     

    return (     
     <div className="dropdown">
      <SelectSearch
          options={list}          
          search={true} 
          value={setvalue}
          filterOptions={fuzzySearch}
          placeholder={placeholder}          
          onChange={(value) => { 
            setDropValue(value);
          }}
        />
     </div>  
    );
  }

export default DropDown;