import React, { useEffect } from "react";
import { Helmet } from "react-helmet"; 
import Banner from "./components/Banner";
import Eventcard from "./components/EventCard";
import Livevent from "./components/LiveEvent";
import Promise from "./components/Promise";
import SignUp from "./components/SignUp";
import Sytemwork from "./components/SystemWork";
import Topsale from "./components/TopSale";
import Upcomming from "./components/UpComming";

function Home() {

  const scroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  } 

  useEffect(() => {
    scroll();
  }, []);


  return (
    <div className="home-page">
      <Helmet>
        <title>Nftickt</title>
      </Helmet>
      <div className="container-fluid">
        <Banner />
      </div>
      <Promise />
      <Eventcard />
      <Upcomming />
      <Topsale />
      <Livevent />
      <Sytemwork />
      <SignUp />
    </div>
  );
}

export default Home;