import React from "react";
import WhiteColor from "../assets/images/white-logo.svg";
import SocialIcons from "../components/SocialIcons";
import SubscribeForm from "../components/SubscribeForm";
import footerCont  from "../context/footer";
const Footer = () => {
  return (
    <>
      <div className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-text">
                <img src={WhiteColor} alt="Footer-Logo" />
                <p className="pt-2">{footerCont.logodesc}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="social-section">
              <div className="footer-text">
                <h6>{footerCont.follow}</h6>
                <p>{footerCont.subscribetext}</p>
                <SocialIcons facebookLink="facebookLink" twitterLink="twitterLink" telegramLink="telegramLink" discordLink="discordLink"/>
              </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="foot-form">
              <div className="footer-text">
                <h6>{footerCont.subscribe}</h6>
                <p className="w-100">{footerCont.subscribetext}</p>
                <SubscribeForm />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-rights">
        <div className="text-center">
          <p>Copyright &#169; {new Date().getFullYear()} Nftickt</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
