import { InjectedConnector } from '@web3-react/injected-connector';

declare global {
  interface Window {
    ethereum: {
      removeListener<T>(event: string, cb: (params: T) => void): void;

      request<T>(params: { method: string }): Promise<T>;

      on<T>(event: string, cb: (params: T) => void): void;

      selectedAddress: string | undefined;
    },
    celo :  {
      send: unknown
      enable: () => Promise<string[]>
      on?: (method: string, listener: (...args: any[]) => void) => void
      removeListener?: (method: string, listener: (...args: any[]) => void) => void
    }
  }
}



export const injectedMetamask = new InjectedConnector({
  supportedChainIds: [44787],
})

export const setProvider = (type: any) => {
  window.localStorage.setItem("provider", type);
};






