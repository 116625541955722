import { gql } from '@apollo/client';
import {
  METADATA_FRAGMENT, MINTERS_FRAGMENT, ROLLING_AUCTION_COUNT_FRAGMENT, TOKEN_COUNT_FRAGMENT, LISTINGS_FRAGMENT, SIMPLE_SALE_COUNT_FRAGMENT,
} from './fragments';

// Event List filter by Owner id  
export const StoreEventList = gql`
  query StoreEventList($id: String) @cached {
    store: mb_store_minters(where: {nft_contracts: {symbol: {_eq: $id}}}) {
      nft_contracts {
        symbol
        spec
        reference_hash
        reference
        owner_id
        name
        is_mintbase
        id
        icon
        created_receipt_id
        created_at
        content_flag
        base_uri
        
      }
    }
  }`;
// Event List filter by contracts id 
  export const StoreDetails = gql`
  query StoreEventList($id: String) @cached {
    store: nft_contracts(where: {id: {_eq: $id}}) {
      symbol
      spec
      reference_hash
      reference
      owner: owner_id
      name
      is_mintbase
      id
      icon
      created_receipt_id
      created_at
      content_flag
      base_uri
    }
  }`;

// Event List filter by Owner id  
export const UserEventList = gql`
query EventList($user: String
  $symbol: String) @cached {
  store:  mb_store_minters(where: {nft_contracts: {owner_id: {_eq: $user}, symbol: {_eq: $symbol}}}) {
    nft_contracts {
      symbol
      spec
      reference_hash
      reference
      owner_id
      name
      is_mintbase
      id
      icon
      created_receipt_id
      created_at
      content_flag
      base_uri
      
    }
  }
}`;

// Event NFT Ticket list  
export const EventTicketList = gql`
  query EventTicketList($id: String, $account: String) @cached {
    store: mb_views_nft_owned_tokens(
      where: {owner: {_eq: $account}, nft_contract_id: {_eq: $id}}
    ) {
      token_id
      title
      nft_contract_name
      owner
      metadata_id
      nft_contract_id
      media      
      metadataId: metadata_id
    }
  }
`;

// Event Details  
export const EventDetails = gql`
  query EventDetails($id: [String!]) @cached {
    store:mb_views_nft_tokens(where: {}) {
    base_uri
    burned_receipt_id
    last_transfer_timestamp
    last_transfer_receipt_id
    issued_at
    extra
    expires_at
    description
    copies
    media
    media_hash
  }
  }
`;

// User Ticket List
export const UserTicketList = gql`
  query UserTicketList($id: String) @cached {
    store:mb_views_nft_owned_tokens(where: {owner: {_eq: $id}}) {
      token_id
      title
      nft_contract_name
      owner
      metadata_id
      nft_contract_id
      media 
    }    
  }
`;

// Nft Activities list
export const NftActivities = gql`
  query NftActivities($id: String $store:String) @cached {
    store:  nft_activities(
      where: {token_id: {_eq: $id}, nft_contract_id: {_eq: $store}}
    ) {
      tx_sender
      token_id
      timestamp
      receipt_id
      price
      nft_contract_id
      memo
      kind
      action_sender
      action_receiver
    }
  }`;

  // NFT Details without listing 
export const NftToken = gql`
query NftToken($id: String) @cached {
  store: mb_views_nft_tokens_aggregate(
    where: {metadata_id: {_eq: $id}}
  ) {
    nodes {
      updated_at
      token_id
      title
      starts_at
      royalties_percent
      royalties
      reference_hash
      reference
      owner
      nft_contract_symbol
      nft_contract_spec
      nft_contract_reference
      nft_contract_owner_id
      nft_contract_name
      nft_contract_id
      metadata_id
      media
      media_hash
      extra
      description
    }
  }
}`;


  // NFT Details filter with Id 
  export const MetadataById = gql`
  query MetadataById($id: String) @cached {
    store: mb_views_nft_metadata(
      where: {id: {_eq: $id}}
    ) {
      media
      title
      nft_contract_name
      id
      nft_contract_id
    }
  }`;

export const v2MarketPlaceGetToken = gql`
query v2MarketPlaceGetToken($id: String) {
 tokenData:  mb_views_nft_metadata_unburned(where: {metadata_id: {_eq: "ticketstore.mintspace2.testnet:iWEm0kiK0-AiiI7XMIRudKMdpCdL95uysAy7FmPHGbU"}}) {
  title
  price
  nft_contract_id
  minter
  minted_timestamp
  metadata_id
  media
  description
  base_uri
}
} 
`;

export const v2MarketPlaceGetTokenListings = gql`
  query v2MarketPlaceGetTokenListings($ids: [String!]) {
    list: mb_views_active_listings(where: { token_id: { _in: $ids } }) {
      price
      token {
        id: token_id
      }
    }
  }
`;

export const v2MarketPlaceGetMetadata = gql`
  query v2MarketPlaceGetMetadata(
    $metadataId: String!
  ) {
    ${METADATA_FRAGMENT}
    ${TOKEN_COUNT_FRAGMENT}
    ${MINTERS_FRAGMENT}
    ${ROLLING_AUCTION_COUNT_FRAGMENT}
    ${LISTINGS_FRAGMENT}
    ${SIMPLE_SALE_COUNT_FRAGMENT}
    }
`;


export const v2MarketPlaceGetStoreNfts = gql`
  query v2MarketPlaceGetStoreNfts(
    $offset: Int = 0
    $condition: mb_views_nft_metadata_unburned_bool_exp
  ) @cached {
    mb_views_nft_metadata_unburned(
      where: $condition
      offset: $offset
      order_by: { minted_timestamp: desc }
      limit: 10
    ) {
      createdAt: minted_timestamp
      price
      media
      storeId: nft_contract_id
      metadataId: metadata_id
      title
      base_uri
      description
      
    }
    mb_views_nft_metadata_unburned_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`;
