import {LIVE_EVENT_DATA } from '../actions/actionTypes';


interface state {
    liveEventData:any
}
const INITIAL_STATE ={
    liveEventData:null
}
const getLiveEvent = (state: state, action:any)=>{
    return {
        ...state,
        liveEvent: action.payload
    }
}
const liveEventReducer = (state: state= INITIAL_STATE, action:any)=>{
    switch(action.type){
default:
    return state;
    case LIVE_EVENT_DATA:
        return getLiveEvent(state, action);
    }
}

export default liveEventReducer;