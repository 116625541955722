///// NFT EVENT ACTIONS //////
/// SINGLE EVENT ////
export const FETCH_NFT_EVENT_BY_ID = "FETCH_NFT_EVENT_BY_ID";

export const SET_SELECTED_NFT_EVENT_ID = "SET_SELECTED_NFT_EVENT_ID"

export const FETCH_NFT_EVENT_NETWORK_ERROR = "FETCH_NFT_EVENT_NETWORK_ERROR"

export const FETCH_NFT_EVENT_BY_ID_START = "FETCH_NFT_EVENT_BY_ID_START";

export const FETCH_NFT_EVENT_BY_ID_SUCCESS = "FETCH_NFT_EVENT_BY_ID_SUCCESS";

export const FETCH_NFT_EVENT_BY_ID_FAIL = "FETCH_NFT_EVENT_BY_ID_FAIL";

/// EVENT LISTS 
export const FETCH_NFT_EVENT_LISTS_WITHOUT_FILTERING = "FETCH_NFT_EVENT_LISTS_WITHOUT_FILTERING";

export const FETCH_NFT_EVENT_LISTS_WITH_FILTERING = "FETCH_NFT_EVENT_LISTS_WITH_FILTERING";

export const FETCH_NFT_EVENT_LISTS_START = "FETCH_NFT_EVENT_LISTS_START";

export const FETCH_NFT_EVENT_LISTS_SUCCESS = "FETCH_NFT_EVENT_LISTS_SUCCESS";

export const FETCH_NFT_EVENT_LISTS_FAIL = "FETCH_NFT_EVENT_LISTS_FAIL";

export const FETCH_NFT_EVENT_LISTS_NETWORK_ERROR = "FETCH_NFT_EVENT_LISTS_NETWORK_ERROR";



//// NFT TICKETS ACTIONS /////
export const FETCH_NFT_TICKETS_BY_NFT_EVENT_ID = "FETCH_NFT_TICKETS_BY_NFT_EVENT_ID";

export const FETCH_NFT_TICKETS_NETWORK_ERROR = "FETCH_NFT_TICKETS_NETWORK_ERROR";

export const FETCH_NFT_TICKETS_START = "FETCH_NFT_TICKETS_START";

export const FETCH_NFT_TICKETS_SUCCESS = "FETCH_NFT_TICKETS_SUCCESS";

export const FETCH_NFT_TICKETS_FAIL = "FETCH_NFT_TICKETS_FAIL";


/// GET TICKET ACTIVITY FROM BLOCKCHAIN ACTION ////
export const GET_TICKET_ACTIVITY_FROM_CHAIN = "GET_TICKET_ACTIVITY_FROM_CHAIN";

export const TICKET_ACTIVITY_FROM_CHAIN_START = "TICKET_ACTIVITY_FROM_CHAIN_START";

export const TICKET_ACTIVITY_FROM_CHAIN_SUCCESS = "TICKET_ACTIVITY_FROM_CHAIN_SUCCESS";

export const TICKET_ACTIVITY_FROM_CHAIN_FAIL = "TICKET_ACTIVITY_FROM_CHAIN_FAIL";

export const TICKET_ACTIVITY_FROM_CHAIN_ERROR = "TICKET_ACTIVITY_FROM_CHAIN_ERROR";


////  TICKET DETAIL ACTIONS /////
export const FETCH_NFT_TICKET_ID = "FETCH_NFT_TICKET_ID";

export const FETCH_NFT_TICKET_ERROR = "FETCH_NFT_TICKET_ERROR";

export const FETCH_TICKET_START = "FETCH_TICKET_START";

export const FETCH_TICKET_SUCCESS = "FETCH_TICKET_SUCCESS";

export const FETCH_TICKET_FAIL = "FETCH_TICKET_FAIL";


////  RECENT DETAIL ACTIONS /////
export const FETCH_RECENT_TICKET = "FETCH_RECENT_TICKET";

export const FETCH_RECENT_ERROR = "FETCH_RECENT_ERROR";

export const FETCH_RECENT_START = "FETCH_RECENT_START";

export const FETCH_RECENT_SUCCESS = "FETCH_RECENT_SUCCESS";

export const FETCH_RECENT_FAIL = "FETCH_RECENT_FAIL";

////  CREATE EVENT DATA LIST /////
export const EVENT_ID = "EVENT_ID"; 

export const EVENT_NAME = "EVENT_NAME"; 

export const EVENT_DESC = "EVENT_DESC";

export const EVENT_TYPE = "EVENT_TYPE";

export const TICKET_SUPPLAY = "TICKET_SUPPLAY";

export const EVENT_COUNTRY = "EVENT_COUNTRY";

export const EVENT_STATE = "EVENT_STATE";

export const EVENT_CITY = "EVENT_CITY";

export const EVENT_LOCATION = "EVENT_LOCATION";

export const EVENT_EXTERNAL_LINK = "EVENT_EXTERNAL_LINK";

export const EVENT_PROFILE = "EVENT_PROFILE";

export const EVENT_COVER = "EVENT_COVER";

export const EVENT_TWITTER = "EVENT_TWITTER";

export const EVENT_FACEBOOK = "EVENT_FACEBOOK";

export const EVENT_DISCORD = "EVENT_DISCORD";

export const EVENT_TELEGRAM = "EVENT_TELEGRAM";

export const EVENT_START_DATE = "EVENT_START_DATE";

export const EVENT_END_DATE = "EVENT_END_DATE";

export const EVENT_VALIDATION = "EVENT_VALIDATION";

export const EVENT_TICKET_TYPE = "EVENT_TICKET_TYPE";

export const EVENT_TICKET_DATA = "EVENT_TICKET_DATA";

export const EVENT_TICKET_STEP = "EVENT_TICKET_STEP";

export const EVENT_PUBLISH = "EVENT_PUBLISH";

export const EVENT_OWNER = "EVENT_OWNER";

////  CREATE EVENT STEP 01 /////
export const CREATE_EVENT = "CREATE_EVENT";

export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";

export const CREATE_EVENT_START = "CREATE_EVENT_START";

export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";

export const CREATE_EVENT_FAIL = "CREATE_EVENT_FAIL"; 

////  CREATE TICKET TYPE STEP 02 /////
export const CREATE_TICKET_TYPE = "CREATE_TICKET_TYPE";

export const CREATE_TICKET_TYPE_ERROR = "CREATE_TICKET_TYPE_ERROR";

export const CREATE_TICKET_TYPE_START = "CREATE_TICKET_TYPE_START";

export const CREATE_TICKET_TYPE_SUCCESS = "CREATE_TICKET_TYPE_SUCCESS";

export const CREATE_TICKET_TYPE_FAIL = "CREATE_TICKET_TYPE_FAIL"; 

////  CREATE TICKET INFO STEP 03 /////
export const CREATE_TICKET_IFNO = "CREATE_TICKET_IFNO";

export const CREATE_TICKET_IFNO_ERROR = "CREATE_TICKET_IFNO_ERROR";

export const CREATE_TICKET_IFNO_START = "CREATE_TICKET_IFNO_START";

export const CREATE_TICKET_IFNO_SUCCESS = "CREATE_TICKET_IFNO_SUCCESS";

export const CREATE_TICKET_IFNO_FAIL = "CREATE_TICKET_IFNO_FAIL"; 

////  CREATE TICKET INFO STEP 04 /////
export const CREATE_TICKET_PUBLISH = "CREATE_TICKET_IFNO";

export const CREATE_TICKET_PUBLISH_ERROR = "CREATE_TICKET_PUBLISH_ERROR";

export const CREATE_TICKET_PUBLISH_START = "CREATE_TICKET_PUBLISH_START";

export const CREATE_TICKET_PUBLISH_SUCCESS = "CREATE_TICKET_PUBLISH_SUCCESS";

export const CREATE_TICKET_PUBLISH_FAIL = "CREATE_TICKET_PUBLISH_FAIL"; 

// My Event
export const FETCH_NFT_MY_EVENT_ID = "FETCH_NFT_MY_EVENT_ID";

export const FETCH_NFT_MY_EVENT_ERROR = "FETCH_NFT_MY_EVENT_ERROR";

export const FETCH_NFT_MY_EVENT_START = "FETCH_NFT_MY_EVENT_START";

export const FETCH_NFT_MY_EVENT_SUCCESS = "FETCH_NFT_MY_EVENT_SUCCESS";

export const FETCH_NFT_MY_EVENT_FAIL = "FETCH_NFT_MY_EVENT_FAIL";

// UpComing Event

export const FETCH_UPCOMING_EVENT_ID = "FETCH_UPCOMING_EVENT_ID";

export const FETCH_UPCOMING_EVENT_ERROR = "FETCH_UPCOMING_EVENT_ERROR";

export const FETCH_UPCOMING_EVENT_START = "FETCH_UPCOMING_EVENT_START";

export const FETCH_UPCOMING_EVENT_SUCCESS = "FETCH_UPCOMING_EVENT_SUCCESS";

export const FETCH_UPCOMING_EVENT_FAIL = "FETCH_UPCOMING_EVENT_FAIL";

//User

export const SET_USER_INFORMATION  = "SET_USER_INFORMATION";

export const SET_EDIT_USER_INFORMATION = "SET_EDIT_USER_INFORMATION";

export const SET_EDIT_USER_SETTING = "SET_EDIT_USER_SETTING";

// Live Event

export const LIVE_EVENT_DATA = "LIVE_EVENT_ID";

export const LIVE_EVENT_FAIL = "LIVE_EVENT_FAIL";

export const LIVE_EVENT_ERROR = "LIVE_EVENT_ERROR";


///// NFT TICKETSTRANSACTIONS ///////
export const NFT_TICKET_TRANSACTION_START = "NFT_TICKET_TRANSACTION_START";

export const NFT_TICKET_TRANSACTION_SUCCESS = "NFT_TICKET_TRANSACTION_SUCCESS";

export const NFT_TICKET_TRANSACTION_FAIL = "NFT_TICKET_TRANSACTION_FAIL";

export const BUY_NFT_TICKET_TRANSACTION = "BUY_NFT_TICKET_TRANSACTION";

export const UPDATE_NFT_TICKET_TRANSACTION = "UPDATE_NFT_TICKET_TRANSACTION";

export const NFT_TICKET_TRANSACTION_NETWORK_ERROR = "NFT_TICKET_TRANSACTION_NETWORK_ERROR";

///// EDIT EVENT ///////
export const EDIT_EVENT_START = "EDIT_EVENT_START"; 
 
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";

export const EDIT_EVENT_FAIL = "EDIT_EVENT_FAIL"; 

export const EDIT_EVENT_ERROR = "EDIT_EVENT_ERROR";