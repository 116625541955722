import toast from "react-hot-toast";
import nftTicketBaseApi from "../baseApi/nftTicketBaseApi";
import { BUY_NFT_TICKET_TRANSACTION, NFT_TICKET_TRANSACTION_FAIL, NFT_TICKET_TRANSACTION_NETWORK_ERROR, NFT_TICKET_TRANSACTION_START, NFT_TICKET_TRANSACTION_SUCCESS, UPDATE_NFT_TICKET_TRANSACTION } from "./actionTypes";
import NftTicket from "../../ABI/NftTicket";
import allActions from "./index";
import { TicketManagerMetamask, TicketManagerCelo } from "../../contractInstance/contractInstance";

export const buyNftTicketTransaction = (currentTicketId: string, tierIndex: any, currentEventId: string | any, walletAddress: string | null | undefined ) => async (dispatch: any) => {

    try {
        dispatch(nftTicketTransactionStart())
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        let body = {
            ticketId: currentTicketId,
            eventId: currentEventId,
            ticketQty: 1
        }

        const response = await nftTicketBaseApi.post(`nft-ticket/buy-ticket`, body, config)

        dispatch({
            type: BUY_NFT_TICKET_TRANSACTION,
            payload: response.data
        })


      //// SENDING TICKET INFO URL TO BLOCK CHAIN METHOD //// 
      const ticketInfoHash = response.data?.ticketInfoHash?.IpfsHash
      const ticketInfoUrl = `https://gateway.pinata.cloud/ipfs/${ticketInfoHash}`

      const currentInjectedProvider = localStorage.getItem('provider');
      let blockChainResponse: any;

    if (currentInjectedProvider === "injectedmetamask") {
        blockChainResponse = await TicketManagerMetamask.methods.buyTickets(currentEventId, 1, tierIndex, ticketInfoUrl).send({ from: walletAddress, value: "1000000000000000000", gasLimit: 3000000 })
    } else {
        blockChainResponse = await TicketManagerCelo.methods.buyTickets(currentEventId, 1, tierIndex, ticketInfoUrl).send({ from: walletAddress, value: "1000000000000000000", gasLimit: 3000000 })
    }

      //// UPDATING THE TICKET TRANSACTION STATUS ////
      const transactionId = response.data?.orderedTransaction?._id;
      const ticketId = response.data?.orderedTransaction?.ticketId;
      const eventId = response.data?.orderedTransaction?.eventId;
      if (blockChainResponse?.status === true) {
            await dispatch(allActions.updateNftTicketTransaction(transactionId, ticketId, eventId) as any)
      }

        dispatch(nftTicketTransactionSuccess())

    } catch (error: any) {

        dispatch(nftTicketTransactionStart())

        const message = error?.response && error?.response?.data?.message ?
            error?.response?.data?.message
            : error?.message;

        toast.error("Failed to buy ticket");
        dispatch(nftTicketTransactionNetworkError(message))
        dispatch(nftTicketTransactionFail())
    }
}

export const updateNftTicketTransaction = (transactionId: string, ticketId: string, eventId: string) => async (dispatch: any) => {

    try {

        dispatch(nftTicketTransactionStart())
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        let body = {
            ticketQty: 1,
            ticketId: ticketId,
            eventId: eventId,
            transactionId: transactionId
        }

            const response = await nftTicketBaseApi.post(`nft-ticket/buy-ticket`, body, config)

            //// SENDING EMAIL TO USER FOR SUCCESSFUL PURCHASE ////
            const confirmationMail = await nftTicketBaseApi.post(`confirmation-email`, body, config)

            dispatch({
                type: UPDATE_NFT_TICKET_TRANSACTION,
                payload: response.data
            }) 

        dispatch(nftTicketTransactionSuccess())

    } catch (error: any) {
        dispatch(nftTicketTransactionStart())

        const message = error?.response && error?.response?.data?.message ?
            error?.response?.data?.message
            : error?.message;

        dispatch(nftTicketTransactionNetworkError(message))
        dispatch(nftTicketTransactionFail())

    }

}

export const nftTicketTransactionStart = () => (dispatch: any) => {
    dispatch ({
        type: NFT_TICKET_TRANSACTION_START,
        buyNftTicketLoader: true
    })
}

export const nftTicketTransactionSuccess = () => (dispatch: any) => {
    dispatch ({
        type: NFT_TICKET_TRANSACTION_SUCCESS,
        buyNftTicketLoader: false
    })
}

export const nftTicketTransactionFail = () => (dispatch: any) => {
    dispatch({
        type: NFT_TICKET_TRANSACTION_FAIL,
        buyNftTicketLoader: false
    })
}

export const nftTicketTransactionNetworkError = (error: any) => (dispatch: any) => {
    dispatch({
        type: NFT_TICKET_TRANSACTION_NETWORK_ERROR,
        buyNftTicketsNetworkError: error
    })
}