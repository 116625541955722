import React, { useEffect } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Footer from './layouts/Footer';
import Header from './layouts/Header';
import EventCollection from './pages/eventScreen/EventCollection';
import EventDetails from './pages/eventdetails/EventDetails'; 
import CreateEvent from './pages/createevent/CreateEvent';
import { Toaster } from 'react-hot-toast';
import MyEvents from './pages/myevents/myEvents';
import MyTickets from './pages/mytickets/MyTickets';
import Home from './pages/home/Home';
import Myprofile from './pages/myprofile/MyProfile';
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3';
import ScrollToTop from './components/ScrollToTop';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux'; 
import NotFound from './pages/httperrors/NotFound';
import InternalServer from './pages/httperrors/InternalServer';
import { WalletProvider } from './services/providers/MintbaseWalletContext';
import { ApolloProvider } from '@apollo/client';
import { getClient } from './services/providers/apollo';
import { Chain, Network } from 'mintbase';
import TicketDetailsNear from './pages/ticketdetails/ticketDetailsNear';
import ListingEvent from './pages/listingEvent/ListingEvent';
import MyEventDetails from './pages/myevents/components/MyEventDetails';
import { useWallet } from "./services/providers/MintbaseWalletContext";
import Scanner from './pages/scanner/scanner';
function getLibrary(provider: any) {
  return new Web3(provider)
}

function App() {
  const userData = useSelector((state: any) => state.userData);   
  const isConnected = localStorage.getItem("isconnect");

  console.log(isConnected)
  useEffect(() => {
    AOS.init(
      {
        duration: 2000,
        easing: 'ease-out-back',
      }
    );
  }, [])

  
  const isValidNetworkKey = Object.values(Network).includes(
    process.env.REACT_APP_NEAR_NETWORK as Network,
  );
  const networkKey = isValidNetworkKey
    ? (process.env.REACT_APP_NEAR_NETWORK as Network)
    : Network.testnet;
 
  return (
    <div className="App">
      <BrowserRouter>
        <Web3ReactProvider getLibrary={getLibrary}>
        <WalletProvider  network={networkKey} chain={Chain.near as Chain} apiKey={process.env.REACT_APP_NEAR_API_KEY || ''}>
        <ApolloProvider client={getClient({ network: Network.testnet })}>
          <Header />
          <ScrollToTop>
            <SkeletonTheme baseColor="#ececec" highlightColor="#dadada">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/create-event" element={isConnected ? <CreateEvent /> : <Home />} />
                <Route path="/events" element={<EventCollection />} />
                <Route path="/event-details/:id" element={<EventDetails />} /> 
                <Route path="/user-event/:id" element={<MyEventDetails />} /> 
                <Route path="/nft-details/:id" element={<TicketDetailsNear />} />
                <Route path="/user-nft/:id" element={<TicketDetailsNear />} />
                <Route path="/my-events" element={isConnected ? <MyEvents /> : <Home />} />
                <Route path="/my-tickets" element={isConnected ? <MyTickets /> : <Home />} />
                <Route path="/my-profile" element={isConnected ? <Myprofile /> : <Home />} />
                <Route path="/edit-event/:id" element={isConnected ? <CreateEvent /> : <Home />} />
                <Route path="/scanner/:id" element={<Scanner/>} />
                <Route path="/file-internal-server" element={<InternalServer/>} />
                <Route path="/listing/:id" element={<ListingEvent/>} />
                <Route path="/*" element={<NotFound/>} />
              </Routes>
            </SkeletonTheme>
          </ScrollToTop>
          <Footer />
          </ApolloProvider>
          </WalletProvider>
        </Web3ReactProvider>
      </BrowserRouter>
      <Toaster position="bottom-right" reverseOrder={false} toastOptions={{
        success: {
          iconTheme: {
            primary: 'white',
            secondary: 'green',
          },
          style: {
            background: 'green',
            color: 'white',
          },

        },
        error: {
          iconTheme: {
            primary: 'white',
            secondary: 'red',
          },
          style: {
            background: 'red',
            color: 'white',
          },
        },
      }} />
    </div>
  );
}

export default App;
