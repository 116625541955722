import React from "react";
import Skeleton from "react-loading-skeleton";

const EventCardSkeleton = () => {
  return (
    <div key={''}>
      <div className="even-card">
        <div className="collection-item">
          <div className="event-thumb">
            <div className="event-edit">
              {/* <img src={PencilIcon} alt="edit-event"/> */}
            </div>
            <Skeleton width={"100%"} height={"285px"} />
          </div>
          <div className="event-footer">
            <div className="ent-logo">
              <Skeleton width={"36px"} height={"36px"} />
            </div>
            <h6>
              <Skeleton width={"210px"} height={"18px"} />
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCardSkeleton;
