import React from "react";
import Facebook from '../assets/images/svg-icons/facebook.svg';
import Twitter from '../assets/images/svg-icons/twitter.svg';
import Google from '../assets/images/svg-icons/google.svg';
import Twitch from '../assets/images/svg-icons/twitch.svg';
import Instgram from "../assets/images/instagram.png";

const SocialIcons = (props : any) => {

  return (
    <div className="social-links">
    <ul >
        {props?.facebookLink ? (
          <li>
            <a href={props?.facebookLink} target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook" /></a>
          </li>
        ) : null
        }
        {props?.twitterLink ? (
          <li>
            <a href={props?.twitterLink} target="_blank" rel="noreferrer"><img src={Twitter} alt="Twitter" /></a>
          </li>
        ) : null
        }
        {props?.telegramLink ? (
          <li>
            <a href={props?.telegramLink} target="_blank" rel="noreferrer"><img src={Instgram} alt="Google" /></a>
          </li>
        ) : null
        }
        {
          props?.discordLink ? (
            <li>
              <a href={props?.discordLink} target="_blank" rel="noreferrer"><img src={Twitch} alt="Twitch" /></a>
            </li>

          ) : null
        }
    </ul>
  </div>
  );
};

export default SocialIcons;
