import { combineReducers } from "redux";
import myEventReducer from "./myEventReducer";
import nftEventsReducer from "./nftEventsReducer";
import nftTicketsReducer from "./nftTicketsReducer";
import nftTicketDetailsReducer from './nftTicketDetailsReducer';
import recentListDataReducer from './recentListReducer';
import createEventData from './createEventReducer';
import createEventDataList from "./eventDataReducer";
import upComingEventReducer from "./upComingEventReducer";
import nftTickeTypeReducer from "./createTicketTypeReducer";
import nftTickeInfoReducer from "./createTicketInfoReducer";
import nftTickeEventPublishReducer from "./createTicketPublishReducer";
import userReducer from './userReducer';
import liveEventReducer from "./liveEventReducer";
import nftTicketTransactionsReducer from "./ticketTransactionsReducer";

const rootReducer = combineReducers({
    nftTicketReducerData: nftTicketsReducer,
    nftEventReducerData: nftEventsReducer,
    nftTicketReducer: nftTicketDetailsReducer,
    recentListReducer: recentListDataReducer,
    eventData:createEventDataList,
    createEvent : createEventData,
    myEventReducerData: myEventReducer,
    upComingEventReducerData:upComingEventReducer,
    createTicketType : nftTickeTypeReducer,
    createTicketInfo : nftTickeInfoReducer,
    createTicketEventPublish : nftTickeEventPublishReducer,
    userData: userReducer,
    liveEventReducerData:liveEventReducer,
    nftTicketTransactions: nftTicketTransactionsReducer
})

export default rootReducer;