/*

useStores Hook
Description: Hook to get the stores set on the NEXT_PUBLIC_STORES env variable

*/

import { useQuery } from '@apollo/client';
import { useState } from 'react'; 
import { UserTicketList } from '../queries/marketplace.queries';
import { useWallet } from '../services/providers/MintbaseWalletContext';
import { Store } from '../types/types';

export const useUserNftList = () => {
  const [stores, setStores] = useState<Store[]>([]);
  const { wallet } = useWallet();

  const { loading } = useQuery(UserTicketList, {
    variables: {
      id:  wallet?.activeAccount?.accountId.toString(),
    },
    onCompleted: (data) => {
      console.log(data)
      const storesData = data?.store; 
      setStores(storesData);
    },
  });

  return { stores, loading };
};

export default useUserNftList;
