import React, { useEffect, useState } from "react";
import DateFilter from "../../components/filters/DateFilter";
import EventMode from "../../components/filters/EventMode";
import FiltersHeader from "../../components/FiltersHeader";
import useWindowDimensions, { networkChain } from "../../const/config";
import CloseIcon from '../../assets/images/svg-icons/close.svg';
import FilterIcon from '../../assets/images/svg-icons/filter-icon.svg';
import { useDispatch } from "react-redux";
import allActions from "../../Redux/actions";
import NftEventsLists from "./components/NftEventsLists";
import { Helmet } from "react-helmet";
import NearList from "./components/NearList";

const EventCollection = () => {
  const [grid, setGrid] = useState(false);
  const [activepage, setActivePage] = useState<Number>(0);
  const [showFilters, setShowFilters] = useState(false);
  const [hideFilters, setHideFilters] = useState(false);
  const { width } = useWindowDimensions();

  const dispatch = useDispatch()

  const [datePickerToggle, setDatePickerToggle] = useState({
    startDate: false,
    endDate: false
  })

  const [eventCheckbox] = useState([
    { id: 1, name: "eventType", value: "online", isChecked: false },
    { id: 2, name: "eventType", value: "offline", isChecked: false },
    { id: 3, name: "eventType", value: "yes", isChecked: false },
    { id: 4, name: "eventType", value: "no", isChecked: false } ])

  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
    eventType: [],
    isEntryFree: []}) as any

  useEffect(() => {
    if (width <= 991) {
      setHideFilters(true);
    } else {
      setHideFilters(false);
      // setShowFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllData = (activepage: any) => {
    window.scrollTo(200, 200);
    dispatch(allActions.fetchNftEventListsWithoutFiltering(activepage) as any)
  }

  const postFilteredData = () => {
    if ((filterData?.eventType?.length > 0) || (filterData?.isEntryFree?.length > 0) || filterData.startDate || (filterData.startDate && filterData.endDate) || filterData.endDate) {
      let data: any = {};

      if (filterData?.startDate) {
        data.startDate = filterData?.startDate;
        data.filterData = true;
      }

      if (filterData?.endDate) {
        data.endDate = filterData?.endDate;
        data.filterData = true;
      }

      if ((filterData?.eventType?.length === 1)) {
        data.type = filterData?.eventType[0];
        data.filterData = true;
      }

      if (filterData?.isEntryFree?.length === 1) {
        data.isEntryFree = filterData?.isEntryFree[0];
        data.filterData = true;
      }
      window.scrollTo(200, 200);
      dispatch(allActions.fetchNftEventListsWithFiltering(data, activepage) as any)

    } else {
      getAllData(activepage)
      // dispatch(allActions.fetchNftEventListsWithoutFiltering(activepage) as any)
    }
  }

  const checkBoxHandler = async (e: any) => {

    eventCheckbox.map((checkbox: any, index: any) => {

      if (checkbox.value === e.target.value) {
        checkbox.isChecked = !(checkbox.isChecked)
      }
      return null;
    })

    if ((e.target.value) && (e.target.checked) && (filterData[e.target.name]?.length > 0)) {
      filterData[e?.target?.name].map((data: any) => {
        if (data !== e?.target?.value) {
          setFilterData((prevState: any) => ({
            ...prevState,
            [e?.target?.name]: [...prevState[e?.target?.name], e?.target?.value]
          }))
        }
        return null;
      })
    }

    if ((e.target.value) && !(e.target.checked) && (filterData[e.target.name]?.length > 0)) {
      filterData[e?.target?.name].map((data: any, index: any) => {
        if (data === e?.target?.value) {
          const filteredData = filterData[e?.target?.name].filter((value: any) => value !== e?.target?.value)
          setFilterData((prevState: any) => ({
            ...prevState,
            [e?.target?.name]: filteredData
          }))
          // return filterData[e?.target?.name].splice(index, 1)
        }
        return null;
      })
    }

    if ((e?.target?.value) && (e?.target?.checked) && (filterData[e.target.name]?.length === 0)) {
      setFilterData((prevState: any) => ({
        ...prevState,
        [e?.target?.name]: [...prevState[e?.target?.name], e?.target?.value]
      }))
    }
  }

  const dateChangeHandler = (dateFlag: string, date: any) => {

    if (dateFlag === "startDate") {
      setFilterData((prevState: any) => ({
        ...prevState,
        startDate: date
      }))

      toggleStartDate()
    }

    if (dateFlag === "endDate") {
      setFilterData((prevState: any) => ({
        ...prevState,
        endDate: date
      }))

      toggleEndDate()
    }
  }

  ////// TOGGLING HANDLERS ///////
  const toggleStartDate = () => {

    setDatePickerToggle((prevState) => ({
      ...prevState,
      startDate: !(prevState.startDate)
    }))

  }

  const toggleEndDate = () => {
    setDatePickerToggle((prevState) => ({
      ...prevState,
      endDate: !(prevState.endDate)
    }))
  }


  useEffect(() => {
    getAllData(activepage)
    // dispatch(allActions.fetchNftEventListsWithoutFiltering(activepage) as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activepage])

  useEffect(() => {
    postFilteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  return (
    <div className="page-spacing">
      <Helmet>
        <title>Events | Nftickt</title>
      </Helmet>
      <div className={`fe ${grid ? "gridfour" : ""}`}>
        <div className="container">
          <FiltersHeader setGrid={setGrid} grid={grid} />
          {hideFilters === true &&
            showFilters === false ? (
            <div
              className="filter-icon"
              onClick={(e) => {
                e.stopPropagation();
                setShowFilters(true);
              }} >
              <img src={FilterIcon} alt="closeicon" />
            </div>
          ) : ("")}
          <div className="row">
            <div className="col-lg-12">
              <NearList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCollection;
