import { FacebookShareButton } from "next-share";
import React, { useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import TelegramShareButton from "react-share/lib/TelegramShareButton";
import TwitterShareButton from "react-share/lib/TwitterShareButton";
import RedditShareButton from "react-share/lib/RedditShareButton";
import ShareIcon from "../../assets/images/svg-icons/share-icon.svg";
import { useLocation } from 'react-router-dom'
import toast from "react-hot-toast";
import useDetectOutsideClick from "../../useDetectOutsideClick";

const SocialShare = (props: any) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(false);
  const onClick = () => setIsActive(!isActive);
  const location = useLocation();
  const url = `${process.env.REACT_APP_FRONTEND_URL}${location.pathname}`
  const Closedrop = () => {
    setIsActive(false);
  };
  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (!isActive?.current?.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [isActive, setIsActive]);
  return (
    <div className="share-popup">
      <div className="menu-container">
        <span onClick={onClick} className="menu-trigger">
          <img src={ShareIcon} alt="share" />
          Share
        </span>
        <div
          ref={dropdownRef}
          className={`menu ${isActive ? "active" : "inactive"}`}
        >
          <ul>
            <li>
              <button className="border-0 bg-transparent p-0" onClick={() => {
                navigator.clipboard.writeText(url)
                toast.success("Copied")
                }}><Link to="">Copy Link</Link></button>
            </li>
            <li onClick={Closedrop}>
              <FacebookShareButton
                title={props?.eventTitle}
                url={url}
                quote={props?.eventDesc}
                hashtag={"#nftticket"}
              >
                <Link to="">Facebook</Link>
              </FacebookShareButton>
            </li>
            <li onClick={Closedrop}>
              {" "}
              <TwitterShareButton
                url={url}
                title={props?.eventTitle}
                via={props?.eventDesc}
              >
                <Link to="">Twitter</Link>
              </TwitterShareButton>
            </li>
            <li onClick={Closedrop}>
              <RedditShareButton url={url} title={props?.eventTitle}>
                <Link to="">Reddit</Link>
              </RedditShareButton>
            </li>
            <li onClick={Closedrop}>
              <TelegramShareButton url={url} title={props?.eventTitle}>
                <Link to="">Telegram</Link>
              </TelegramShareButton>
            </li>
            
              {/* <li onClick={Closedrop}>
              <LinkedinShareButton
                url={url}
                title={props?.eventTitle}
                summary={props?.eventDesc}
              >
                <Link to="">Linkedin</Link>
              </LinkedinShareButton>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SocialShare;
