import React, { useState } from "react";
import Ticketdetailcont from "../../../context/ticketDetailcont";
import InfoIcon from "../../../assets/images/svg-icons/info.svg";
import Skeleton from "react-loading-skeleton";
const AboutEvent = ({ content }: any) => {
  const [isShown, setIsShown] = useState(true);
  const handleClick = () => {
    setIsShown((current) => !current);
  };

  return (
    <div className="acc-group">
      <div className="acc-header" onClick={handleClick}>
        <div className="title-icon">
          <img src={InfoIcon} alt="InfoIcon" />
        </div>
        <div className="title-head">
          <h5>{Ticketdetailcont.Aboutlabel}</h5>
        </div>
      </div>
      {isShown && (
        <div className="body-content card-height">
          <p>{content || <Skeleton height={"145px"} />}</p>
        </div>
      )}
    </div>
  );
};

export default AboutEvent;
