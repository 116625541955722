import { CREATE_TICKET_IFNO, CREATE_TICKET_IFNO_ERROR, CREATE_TICKET_IFNO_FAIL, CREATE_TICKET_IFNO_START, CREATE_TICKET_IFNO_SUCCESS } from "../actions/actionTypes";

  

/////// TYPESCRIPT INTERFACE ////////
interface state {
    status: number | null;
     createTicketInfoData: any,
     createTicketInfoError: any | string,
     createTicketInfoLoader: boolean
}

const INITIAL_STATE = {
    status: null,
     createTicketInfoData: null,
     createTicketInfoError: null,
     createTicketInfoLoader: false
}

const  createTicketInfo = (state: state, action: any) => {

    return {
        ...state,
        status: 200,
         createTicketInfoData: action.payload
    }
}



const  createTicketInfoError = (state: state, action: any) => {
    return {
        ...state,
        status: 500,
         createTicketInfoError: action.createTicketInfoError
    }
}

const  createTicketInfoStart = (state: state, action: any) => { 
    return {
        ...state,        
         createTicketInfoLoader: true
    }
}

const  createTicketInfoSuccess = (state: state, action: any) => {
    return {
        ...state,
        status: 200,
         createTicketInfoLoader: false
    }
}

const  createTicketInfoFail = (state: state, action: any) => {
    return {
        ...state,
        status: 404,
         createTicketInfoLoader: false
    }
}

const nftTickeInfoReducer = (state: state = INITIAL_STATE, action: any) => { 
    switch (action.type) {
        default:
            return state;
       
        case CREATE_TICKET_IFNO_START:
            return  createTicketInfoStart(state, action);

        case CREATE_TICKET_IFNO_SUCCESS:
            return  createTicketInfoSuccess(state, action);

        case CREATE_TICKET_IFNO:
            return  createTicketInfo(state, action);

        case CREATE_TICKET_IFNO_ERROR:
            return  createTicketInfoError(state, action);

        case CREATE_TICKET_IFNO_FAIL:
            return  createTicketInfoFail(state, action);
    }
}

export default nftTickeInfoReducer;