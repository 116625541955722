import toast from "react-hot-toast";  
import nftTicketBaseApi, { store } from "../baseApi/nftTicketBaseApi"; 
import { CREATE_TICKET_PUBLISH, CREATE_TICKET_PUBLISH_ERROR, CREATE_TICKET_PUBLISH_FAIL, CREATE_TICKET_PUBLISH_START, CREATE_TICKET_PUBLISH_SUCCESS } from "./actionTypes";
 
export const creatEventPublishLater = () => async(dispatch: any) => {
    const event = store.getState().eventData;  
    try {
        dispatch(fetchcreateTicketPublishStart());
        let body = { }
        body = {
            eventId: event.eventId, 
        }
         
        const response = await nftTicketBaseApi.post(`nft-event/publish-later`, body)  
        if (response.status === 201) { 
            // actionCreators.eventTicketStep(4); 
            toast.success(response.data.message);   
           
        } else if (response.status === 200) {
            toast.success(response.data.message);           
            // actionCreators.eventTicketStep(4); 
        }  else { 
            toast.error(response.data.message) 
        }
  
        dispatch({
            type: CREATE_TICKET_PUBLISH,
            payload: response.data
        }) 
        dispatch(fetchcreateTicketPublishSuccess())

    } catch (err) {
        // toast.error(err) 
        toast.error("Ticket Type Creat unsucessfully. Pls try again");
        dispatch(fetchcreateTicketPublishStart())

        dispatch(fetchcreateTicketPublishError(err))

        dispatch(fetchcreateTicketPublishFail())

    }

}

export const fetchcreateTicketPublishError = (error: any) => async (dispatch: any) => {

    dispatch({
        type: CREATE_TICKET_PUBLISH_ERROR,
        fetchNftTicketsNetworkError: error
    })
}

const fetchcreateTicketPublishStart = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_PUBLISH_START,
        nftEventLoader: true
    })
}

const fetchcreateTicketPublishSuccess = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_PUBLISH_SUCCESS,
        nftEventLoader: false
    })
}

const fetchcreateTicketPublishFail = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_PUBLISH_FAIL,
        nftEventLoader: false
    })
}