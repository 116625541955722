import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

const TicketCardSkeleton = () => {
  const [isSkeleton] = useState(12);

  return (
    <>
      {[...Array(isSkeleton)].map(() => {
        return (
          <div className="ticketcard">
            <div className="ticketicon p-0">
              <div className="avalable-ticket">
                <Skeleton width={"100%"} height={"285px"} />
              </div>
              <Skeleton width={"100%"} height={"285px"} />
            </div>
            <div className="details">
              <Skeleton width={"200px"} height={"25px"} />               
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TicketCardSkeleton;
