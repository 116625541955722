import { useParams } from "react-router-dom";
import TicketCard from "../../components/cards/TicketCard";
import TicketCardSkeleton from "../../components/skeleton/TicketCardSkeleton";
import useStoreNfts from "../../hooks/useStoreNfts";

const NftTickets = (props: any) => {
  const nftId = useParams();
  const id = nftId.id?.toString();
  const { nfts, loading } = useStoreNfts(id);

  return (
    <> <div className={`card-layout ${nfts.length > 0 ? "mt-5" : ""} ${props?.gridview ? 'layout' : ''}`}>
      {loading === true ? <TicketCardSkeleton /> : <>
        {loading == false && nfts?.map((item: any, index: any) => (
          <TicketCard nftTicket={item} key={index} />
        ))} </>}
    </div>
      {nfts.length <= 0 ? <div className="text-center p-5 mb-5"> <p>No more Ticket</p></div> : ""}
    </>
  )
}

export default NftTickets;