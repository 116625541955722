import React, { useState } from 'react';
import SendIcon from '../assets/images/svg-icons/send.svg'
import { newsLatter } from '../services/newslatter';
const SubscribeForm = () => {
  const [mail, setEmail] = useState('');

    return (
      <div className="d-flex">
        <div className="subscribe-from d-flex"> 
          <input type="email" placeholder="Info@nftickt.com" value={mail} onChange={(e:any)=>{setEmail(e.target.value)}} />
          <span onClick={()=>{newsLatter(mail,setEmail)}}>
            <img src={SendIcon} alt="send-icon" />
          </span> 
        </div>
      </div>
    );
};

export default SubscribeForm;