import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { QrReader } from 'react-qr-reader';
import Button from "../../components/button/Button";

const Scanner = () => {
    const [data, setData] = useState('No result');
    const [popup, setPopup] =  useState(false)
    return (
        <>
            <Helmet>
                <title>Scan | Nftickt</title>
            </Helmet>
            <div className="container scanner">
                <div className="row ">
                    <div className="col-lg-6">
                        <QrReader
                            videoStyle={{ "width": "100%", "height": "100%" }}
                            onResult={(result: any, error: any) => {
                                if (!!result) { 
                                    setPopup(true)
                                    setData(result?.text);
                                }
                                if (!!error) {
                                    console.info(error);
                                }
                            }}
                            constraints={{
                                "facingMode": "user"
                            }} />

                    </div>
                    <div className="col-lg-6 user-info">
                        <p>{data}</p>
                    </div>
                </div>
                {popup == true ?
                    <div className="action-btn mb-5">
                        <div className="content">
                            <Button name="Cancel" click={''} icon={false} classname={'secondarybtn'} />
                            <Button name="Approed" click={''} icon={false} classname={'primarybtn'} />
                        </div>
                    </div>
                    : ""}

            </div>
        </>
    );
}

export default Scanner;
