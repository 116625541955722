import React from "react"; 
import { Link } from "react-router-dom";
import PencilIcon from '../../assets/images/svg-icons/pencil-icon.svg'; 
import { userWalletAddress } from "../../const/config";

const NftCard = (props:any) => {   
  return (
    <div className="even-card" key={props?._id}>
    <Link to={`/event-details/${props?.nftList?.storedata.id}`}>
      <div className="collection-item">
        <div className="event-thumb">
          <div className="event-edit">
            {props?.nftEvent?.isEventPublished !== "PUBLISHED" && 
             props?.nftEvent?.eventOwner === userWalletAddress?.toString()
             ?  
              <span>
                <Link to={`/edit-event/${props?.nftEvent?._id}`}>
                  <img src={PencilIcon} alt="Edit-icon" className="ms-3" />
                </Link>
              </span>
           :""}
          </div>

          <img
            src={props.nftList.storedata.icon}
            alt={props.nftList.storedata.name}
          />
        </div>
        <div className="event-footer align-items-center">          
          <h6 className="p-0 ps-3">{props.nftList.storedata.name}</h6>            
        </div>
      </div>
    </Link>
  </div>
  );
};

export default NftCard;
