import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import EventCard from "../../components/cards/EventCard";
import MyEventCont from "../../context/myEventCont";
import allActions from "../../Redux/actions";
import { Helmet } from "react-helmet";
import { Pagination } from "../../components/Pagination";
import EventCardSkeleton from "../../components/skeleton/EventCardSkeleton";
import NoData from "../../components/notfound/NoData";
import { pageSize } from "../../const/config";
import useUserStores from "../../hooks/useUserStores";

const MyEvents = () => { 
  const { stores, loading } = useUserStores();
  let navigate = useNavigate();
  const createEventRouter = () => {
    let path = `/create-event`;
    navigate(path);
  }

  return (
    <>
      <Helmet>
        <title>My Event | Nftickt</title>
      </Helmet>
      <div className="page-spacing">
        <div className="container">
          <div className="my-events">
            <h2>
              {MyEventCont.title || (
                <Skeleton width={"100px"} height={"20px"} />
              )}
            </h2>
            <p>{MyEventCont.desc || <Skeleton width={"100%"} height={"15px"} />}</p>
            <Button
              name={MyEventCont.btntext}
              icon={false}
              classname="primarybtn"
              click={() => {
                createEventRouter();
              }}
            ></Button>
          </div>
          <div className="content-spacing">
            <div className="event-group" id="my-events">
              {
                loading === true
                  ? <>
                    {[...Array(pageSize)].map((index) => {
                      return <EventCardSkeleton key={index} />;
                    })}
                  </> : <>
                    {stores &&
                      stores.length > 0
                      && stores?.map(
                        (nftEvent: any, index: any) => {
                          return (
                            <>
                              <EventCard nftEvent={nftEvent} key={index} />
                            </>
                          );
                        }
                      )}
                  </>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyEvents;
