import React, { useState } from "react";
import { Helmet } from "react-helmet";
import TicketCardSkeleton from "../../components/skeleton/TicketCardSkeleton";
import useUserNftList from "../../hooks/useUserNftList";
import MyTicketCard from "./component/MyTicketCard";

const MyTickets = () => {
  const { stores, loading } = useUserNftList();

  return (
    <>
      <Helmet>
        <title>My Tickets | Nftickt</title>
      </Helmet>
      <div className="page-spacing">
        <div className="container">
          <div className="my-events">
            <h2>My Tickets</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              et dolore magna aliqua. Ut enim ad minim veniam.
            </p>
          </div> 
          <div className="content-spacing">
            <div className={`ticket-group`}>
              {loading === true ? <TicketCardSkeleton /> : <>
                {stores?.map((item: any, index: any) => (
                  <MyTicketCard nftTicket={item} key={index} />
                ))} </>}
                </div>
          </div>
        </div>
      </div>
    </>
  );
};






export default MyTickets;
