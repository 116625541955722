import { Helmet } from "react-helmet";
import CreateStore from "./components/CreateStore";
import CreateTicket from "./components/CreateTicket";

function CreateEvent() {

  return (
    <>
      <Helmet>
        <title>Create Event | Nftickt</title>
      </Helmet>
      <div className="container">
        <div className="create-evn">
          <CreateTicket />
        </div>
      </div>
    </>
  );
}

export default CreateEvent;