import {
    FETCH_RECENT_TICKET,
    FETCH_RECENT_ERROR,
    FETCH_RECENT_START,
    FETCH_RECENT_SUCCESS,
    FETCH_RECENT_FAIL
} from "../actions/actionTypes";




/////// INTERFACES ////////
interface state { 
    recentTicketList: any,
    recentTicketNetworkErrorServer: any,
    recentTicketLoader: boolean
}

const INITIAL_STATE = { 
    recentTicketList: null,
    recentTicketNetworkErrorServer: null,
    recentTicketLoader: true
}

const fetchRecentByIdHandler = (state: state, action: any) => { 
    return {
        ...state,
        recentTicketList: action.payload,
        recentTicketLoader: false
    }
}

const fetchRecentNetworkErrorHandler = (state: state, action: any) => {
    return {
        ...state,
        recentTicketNetworkErrorServer: action.recentTicketsNetworkError,
        recentTicketLoader: false
    }
}

const fetchRecentStartHandler = (state: state, action: any) => {
    return {
        ...state, 
        recentTicketLoader: false
    }
}

const fetchRecentSuccessHandler = (state: state, action: any) => {

    return {
        ...state, 
        recentTicketLoader: false
    }
}

const fetchRecentFailHandler = (state: state, action: any) => {
    return {
        ...state,
        recentTicketList: false,
        recentTicketLoader: false
    }
}

const createEventData = (state: state = INITIAL_STATE, action: any) => {
     switch(action.type) {
        default:
            return state;
        
        case FETCH_RECENT_TICKET:
            return fetchRecentByIdHandler(state, action);

        case FETCH_RECENT_ERROR:
            return fetchRecentNetworkErrorHandler(state, action);

        case FETCH_RECENT_START:
            return fetchRecentStartHandler(state, action);

        case FETCH_RECENT_SUCCESS:
            return fetchRecentSuccessHandler(state, action);

        case FETCH_RECENT_FAIL:
            return fetchRecentFailHandler
            (state, action);
            
    }
}

export default createEventData;