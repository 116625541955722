import { SET_USER_INFORMATION } from './actionTypes';
import nftTicketBaseApi from "../baseApi/nftTicketBaseApi";
import moment from 'moment';
import { getUserData } from '../../services/userProfile';


export const setAuthOptions = (account: any) => async (dispatch: any) => {

    const response = await nftTicketBaseApi.post(`/user/wallet_verify`, { address: account, signature: "need to create" });
    localStorage.setItem("walletAddress", response.data.data.wallet_address);
    localStorage.setItem("token", response.data.data.token);
    localStorage.setItem("validUntil", moment.utc(response.data.valid_until).toISOString());

    // Set the AUTH token for any request
    nftTicketBaseApi.defaults.headers.common['Authorization'] = '';
    delete nftTicketBaseApi.defaults.headers.common['Authorization'];
    const token = localStorage.getItem('token');
    if (token) {
    nftTicketBaseApi.defaults.headers.common['Authorization'] = `${token}`;
    getUserData();
    }
    
    dispatch({
        type: SET_USER_INFORMATION,
        payload: response.data
    })
} 
