import moment from "moment";
import React, { useState } from "react";
import Ticketdetailcont from "../../../context/ticketDetailcont";
import ActivitesICon from "../../../assets/images/svg-icons/activites.svg";
import { ticketActivites } from "../../../const/nfttickettemp";
import { useSelector } from "react-redux";
import useNftActivities from "../../../hooks/useNftActivities";
import { parseYactoToNear, yoctoToNear } from "../../../lib/numbers";

const EventActivites = (tokenId: any, nftContractId: any) => {
  const [isShown, setIsShown] = useState(true);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  const storeData = {
    store: nftContractId,
    id: tokenId
  }
  const { activit, loading } = useNftActivities(storeData);

  return (
    <>  {activit.length == 0 ? "" : <>
      <div className="acc-group">
        <div className="acc-header" onClick={handleClick}>
          <div className="title-icon">
            <img src={ActivitesICon} alt="ActivitesICon" />
          </div>
          <div className="title-head">
            <h5>{Ticketdetailcont.activites}</h5>
          </div>
        </div>
        {isShown && (
          <div className="body-content p-0">
            <div className="active-table">
            
                {activit && activit.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Event</th>
                          <th>Price</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Date</th>
                          <th>Transaction ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activit &&
                          activit.map((item: any, index: any) => (
                            <tr className={`${item.kind == "approve" ? "d-none" : ""}`} key={index}>
                              <td>{item.kind}</td>
                              <td >{item.price == null ? "-" : parseYactoToNear(item.price) + "N"}</td>
                              <td>{item.action_sender ? item.action_sender : "-"}</td>
                              <td>{item.action_receiver ? item.action_receiver : "-"}</td>
                              <td>{moment(item.timestamp).fromNow()}</td>
                              <td><a target="_blank" href={`https://testnet.nearblocks.io/txns/${item.receipt_id}`}>{item.receipt_id}</a></td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>No Data </p>
                )}
            </div>
          </div>
        )}
      </div></>}
    </>
  );
};

export default EventActivites;
