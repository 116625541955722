import toast from "react-hot-toast";
import nftTicketBaseApi, { store } from "../baseApi/nftTicketBaseApi";
import { CREATE_TICKET_IFNO, CREATE_TICKET_IFNO_ERROR, CREATE_TICKET_IFNO_FAIL, CREATE_TICKET_IFNO_START, CREATE_TICKET_IFNO_SUCCESS } from "./actionTypes";
import { actionCreators } from "./eventDataActions";

export const createTicketInfo = () => async (dispatch: any) => {
    const event = store.getState().eventData.eventTicketData; 

    try {
        dispatch(fetchcreateTicketInfoStart());
        let body = {

        }
        body = {
            nftTickets: event,
        }

        const response = await nftTicketBaseApi.post(`nft-ticket/create-ticket`, body)

        if (response.status === 201) {

            toast.success(response.data.message);
            actionCreators.eventTicketStep(4);
        } else if (response.status === 200) {
            toast.error(response.data.message)
            actionCreators.eventTicketStep(4);
        } else {
            toast.error(response.data.message)
        }

        dispatch({
            type: CREATE_TICKET_IFNO,
            payload: response.data
        })
        dispatch(fetchcreateTicketInfoSuccess())

    } catch (err) {
        // toast.error(err) 
        toast.error("Ticket Type Creat unsucessfully. Pls try again");
        dispatch(fetchcreateTicketInfoStart())

        dispatch(fetchcreateTicketInfoError(err))

        dispatch(fetchcreateTicketInfoFail())

    }

}

export const fetchcreateTicketInfoError = (error: any) => async (dispatch: any) => {

    dispatch({
        type: CREATE_TICKET_IFNO_ERROR,
        fetchNftTicketsNetworkError: error
    })
}

const fetchcreateTicketInfoStart = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_IFNO_START,
        nftEventLoader: true
    })
}

const fetchcreateTicketInfoSuccess = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_IFNO_SUCCESS,
        nftEventLoader: false
    })
}

const fetchcreateTicketInfoFail = () => async (dispatch: any) => {
    dispatch({
        type: CREATE_TICKET_IFNO_FAIL,
        nftEventLoader: false
    })
}